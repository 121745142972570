import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { toast } from 'react-toastify';

import { loginUserService } from '../../services/authServices/loginUser.service';
import { updateUserService } from '../../services/authServices/updateUser.service';
import { updatePasswordService } from '../../services/authServices/updatePassword.service';
import { verifyPasswordService } from '../../services/authServices/verifyPassword.service';
import { updateEmailService } from '../../services/authServices/updateEmail.service';
import { updateContactService } from '../../services/authServices/updateContact.service';
import { forgotPasswordService } from '../../services/authServices/forgotPassword.service';
import { createPasswordService } from '../../services/authServices/createPassword.service';
import { addAdminService } from '../../services/adminUsers/addAdminUser.service';

const initialState = {
	user: JSON.parse(localStorage.getItem('user')) || null,
	token: localStorage.getItem('token') || null
};

export const addAdminUser = createAsyncThunk(
	'auth/addAdminUser',
	async (adminUserData, { rejectWithValue }) => {
		try {
			const response = await addAdminService(adminUserData);

			return {
				status: response.status
			};
		} catch (error) {
			console.log(error);
			return rejectWithValue({
				data: error.response?.data?.data,
				status: error.response.status
			});
		}
	}
);

export const loginUser = createAsyncThunk(
	'auth/loginUser',
	async (user, { rejectWithValue }) => {
		try {
			const response = await loginUserService(user);

			const { token, ...userData } = response?.data?.data?.Data;

			return {
				token,
				user: userData,
				status: response.status
			};
		} catch (error) {
			return rejectWithValue({
				data: error.response?.data?.data?.Message
			});
		}
	}
);

export const updateUser = createAsyncThunk(
	'user/update/',
	async (userDetails, { rejectWithValue, getState }) => {
		try {
			let {
				auth: { token }
			} = getState();
			const response = await updateUserService(token, userDetails);
			return {
				status: response?.status,
				user: response?.data?.data
			};
		} catch (error) {
			return rejectWithValue({ data: error });
		}
	}
);

export const updatePassword = createAsyncThunk(
	'user/update/',
	async (userDetails, { rejectWithValue, getState }) => {
		try {
			let {
				auth: { token }
			} = getState();
			const response = await updatePasswordService(token, userDetails);
			return {
				status: response?.status,
				user: response?.data?.data
			};
		} catch (error) {
			return rejectWithValue({ data: error });
		}
	}
);

export const forgotPassword = createAsyncThunk(
	'user/forgotPassword/',
	async (userDetails, { rejectWithValue, getState }) => {
		try {
			let {
				auth: { token }
			} = getState();
			const response = await forgotPasswordService(token, userDetails);
			return {
				status: response?.status,
				data: response?.data?.data
			};
		} catch (error) {
			return rejectWithValue({ data: error });
		}
	}
);

export const createPassword = createAsyncThunk(
	'user/createPassword/',
	async (userDetails, { rejectWithValue, getState }) => {
		try {
			let {
				auth: { token }
			} = getState();
			const response = await createPasswordService(token, userDetails);
			return {
				status: response?.status,
				data: response?.data?.data
			};
		} catch (error) {
			return rejectWithValue({ data: error });
		}
	}
);

export const verifyPassword = createAsyncThunk(
	'user/verifyPassword/',
	async (userDetails, { rejectWithValue, getState }) => {
		try {
			let {
				auth: { token }
			} = getState();
			const response = await verifyPasswordService(token, userDetails);
			return {
				status: response?.status,
				user: response?.data?.data
			};
		} catch (error) {
			return rejectWithValue({ data: error });
		}
	}
);

export const updateEmail = createAsyncThunk(
	'user/updateEmail/',
	async (userDetails, { rejectWithValue, getState }) => {
		try {
			let {
				auth: { token }
			} = getState();
			const response = await updateEmailService(token, userDetails);
			return {
				status: response?.status,
				user: response?.data?.data
			};
		} catch (error) {
			return rejectWithValue({ data: error });
		}
	}
);

export const updateContact = createAsyncThunk(
	'user/updateContact/',
	async (userDetails, { rejectWithValue, getState }) => {
		try {
			let {
				auth: { token }
			} = getState();
			const response = await updateContactService(token, userDetails);
			return {
				status: response?.status,
				user: response?.data?.data
			};
		} catch (error) {
			return rejectWithValue({ data: error });
		}
	}
);

const authSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		logoutUser: (state) => {
			state.user = null;
			state.token = null;
			localStorage.removeItem('token');
			localStorage.removeItem('user');
		}
	},
	extraReducers: (builder) => {
		builder
			.addCase(loginUser.fulfilled, (state, action) => {
				state.user = action.payload.user;
				state.token = action.payload.token;
			})
			.addCase(loginUser.rejected, (state, action) => {
				toast.error(action.payload.data);
			})
			.addCase(addAdminUser.rejected, (state, action) => {
				if (action.payload.data?.email) {
					toast.error(action.payload.data?.email[0]);
				}
				if (action.payload.data?.password) {
					toast.error(action.payload.data?.password[0]);
				}
			})
			.addCase(updateUser.rejected, (state, action) => {
				toast.error(action.payload.data);
			})
			.addCase(updateUser.fulfilled, (state, action) => {
				if (action.payload.user) {
					state.user = action.payload.user;
				}
			})
			.addCase(updateEmail.rejected, (state, action) => {
				toast.error(action.payload.data);
			})
			.addCase(updateEmail.fulfilled, (state, action) => {
				if (action.payload.user) {
					state.user = action.payload.user;
				}
			})
			.addCase(updateContact.rejected, (state, action) => {
				toast.error(action.payload.data);
			})
			.addCase(updateContact.fulfilled, (state, action) => {
				if (action.payload.user) {
					state.user = action.payload.user;
				}
			});
	}
});

export const { logoutUser } = authSlice.actions;

export default authSlice.reducer;
