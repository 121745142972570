import React, { useState, useEffect } from 'react';
import { Form, Button, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { FaPlus } from 'react-icons/fa';
import { BsX } from 'react-icons/bs';
import CreatableSelect from 'react-select/creatable';
import '../property/property.scss';
import './setting.scss';
import { getAmenitiesService } from '../../services/settingServices/amenityServices/getAmenities.service';
import { deleteAmenityService } from '../../services/settingServices/amenityServices/deleteAmenity.service';
import { addAmenitiesService } from '../../services/settingServices/amenityServices/addAmenities.service';
import { saveAmenitiesService } from '../../services/settingServices/amenityServices/saveAmenities.service';
import Layout from '../../common/layout';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Loader from '../../common/Loader/loader';

const components = {
	DropdownIndicator: null
};

const createOption = (label) => ({
	label,
	value: label
});

const AmenitiesList = () => {
	const [showDelete, setShowDelete] = useState(false);
	const [ShowAmenities, setShowAmenities] = useState(false);
	const [inputValue, setInputValue] = React.useState('');
	const [value, setValue] = useState([]);
	const [amenities, setAmenities] = useState([]);
	const [saveAmenityIds, setSaveAmenityIds] = useState([]);
	const [deleteAmenityId, setDeleteAmenityId] = useState(null);
	const { token } = useSelector((state) => state.auth);

	const handleKeyDown = (event) => {
		if (!inputValue) return;
		switch (event.key) {
			case 'Enter':
			case 'Tab':
				setValue((prev) => [...prev, createOption(inputValue)]);
				setInputValue('');
				event.preventDefault();
				break;
			default:
				break;
		}
	};

	const getAmenities = async () => {
		const response = await getAmenitiesService();
		setAmenities(response?.data?.data?.results);
	};

	const deleteAmenity = async () => {
		const response = await deleteAmenityService(deleteAmenityId, token);
		if (response.status === 204) {
			toast.success('Amenity deleted successfully');
			setDeleteAmenityId(null);
			setShowDelete(false);
			getAmenities();
		}
	};

	const toggleAmenities = (amenityId) => {
		if (!saveAmenityIds.includes(amenityId)) {
			setSaveAmenityIds((amenities) => [...amenities, amenityId]);
		} else {
			const filterAmenities = saveAmenityIds.filter(
				(amenity) => amenity !== amenityId
			);

			setSaveAmenityIds([...filterAmenities]);
		}
	};

	const saveAmenities = async () => {
		const response = await saveAmenitiesService(token, {
			ids: saveAmenityIds,
			is_active: true
		});
		console.log(response?.data);
		if (response.status === 200) {
			toast.success('Amenities Successfully Saved to STR');
			setSaveAmenityIds([]);
		}
	};

	const newAmenities = value.map((val) => ({ name: val.label }));

	const addAmenities = async () => {
		const response = await addAmenitiesService(token, newAmenities);
		if (response.status === 201) {
			toast.success('New Amenities Created Successfully');
			setShowAmenities(false);
			getAmenities();
		}
	};

	useEffect(() => {
		getAmenities();
	}, []);

	return (
		<>
			<Layout>
				<div className="content-body">
					<div className="content-body-inner">
						<div className="page-header">
							<h1>Amenities Setting</h1>
							<Breadcrumb>
								<span>
									<Link to={`/setting`} className="link-btn">
										Setting
									</Link>
								</span>

								<span className="mx-1"> / </span>

								<span>Amenities Setting</span>
							</Breadcrumb>
						</div>
						<div className="page-content setting-page-content">
							<div className="filter-area">
								<div className="tab-bar"></div>
								<div className="table-filter d-flex">
									<Button
										variant="primary"
										onClick={() => {
											setShowAmenities(true);
										}}
									>
										<FaPlus /> New Amenities
									</Button>
								</div>
							</div>
							<div className="page-content">
								<div className="amenity-wrap">
									<p>
										Check the boxes for each amenity that is available at your
										property and your selected amenities will be added to
										“Create STR’s” amenities step once you click "Save".
									</p>
									<Form className="amenity-list">
										<Form.Group
											as={Row}
											className="mb-3"
											controlId="formSignUpFirstName"
										>
											{amenities.length > 0 ? (
												amenities.map((amenity) => (
													<Col md="4" key={amenity.id}>
														<Form.Check
															inline
															label={amenity.name}
															name={`group${amenity.id}`}
															id={amenity.id}
															onChange={() => toggleAmenities(amenity.id)}
															checked={saveAmenityIds.includes(amenity.id)}
														/>
														<Button
															variant="alert"
															onClick={() => {
																setShowDelete(true);
																setDeleteAmenityId(amenity.id);
															}}
														>
															{' '}
															<BsX />{' '}
														</Button>
													</Col>
												))
											) : (
												<Loader />
											)}
										</Form.Group>
									</Form>
									<div className="d-flex mt-3 btn-wrap">
										<Button
											variant="primary"
											className="ml-2"
											onClick={saveAmenities}
										>
											Save
										</Button>
									</div>
								</div>
							</div>
							<Modal
								show={ShowAmenities}
								className="add-amenity-modal"
								onHide={() => setShowAmenities(false)}
							>
								<Modal.Header closeButton></Modal.Header>
								<Modal.Body>
									<h3>Add New Amenities</h3>
									<p>
										In order to add amenities to the amenity listing, please
										enter the amenity name and hit the Enter key.
									</p>
									<Form className="mt-4">
										<Form.Group as={Row} className="" controlId="">
											<Form.Label column md="12">
												New Amenities <span class="astrik">*</span>
											</Form.Label>
											<Col md="12">
												<CreatableSelect
													components={components}
													inputValue={inputValue}
													isClearable
													isMulti
													menuIsOpen={false}
													onChange={(newValue) => setValue(newValue)}
													onInputChange={(newValue) => setInputValue(newValue)}
													onKeyDown={handleKeyDown}
													placeholder="Enter amenity name here and press enter"
													value={value}
													className="multi-select-box"
												/>
											</Col>
										</Form.Group>
									</Form>
								</Modal.Body>
								<Modal.Footer>
									<Button
										variant="secondary"
										onClick={() => {
											setShowAmenities(false);
										}}
									>
										No,Cancel
									</Button>
									<Button variant="primary" className="" onClick={addAmenities}>
										Yes, Add ‘Amenities’
									</Button>
								</Modal.Footer>
							</Modal>
							<Modal show={showDelete} onHide={() => setShowDelete(false)}>
								<Modal.Header closeButton></Modal.Header>
								<Modal.Body>
									<h3>Sure you want to Remove Amenity? </h3>
									<p>
										{' '}
										This action will permanently remove this Amenity from the
										list
									</p>
								</Modal.Body>
								<Modal.Footer>
									<Button
										variant="secondary"
										onClick={() => {
											setShowDelete(false);
											setDeleteAmenityId(null);
										}}
									>
										No,Cancel
									</Button>
									<Button className="btn-alert" onClick={deleteAmenity}>
										Yes, Confirm
									</Button>
								</Modal.Footer>
							</Modal>
						</div>
					</div>
				</div>
			</Layout>
		</>
	);
};

export default AmenitiesList;
