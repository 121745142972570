import { createAsyncThunk } from '@reduxjs/toolkit';
import { getInquiryListService } from '../../services/InquiryServices/getInquiryList.service';
import { getInquiryByIdService } from '../../services/InquiryServices/getInquiryById.service';
import { getActiveInquiryListService } from '../../services/InquiryServices/getActiveInquiryList.service';
import { getInActiveInquiryListService } from '../../services/InquiryServices/getInActiveInquiryList.service';

export const getInquiryList = createAsyncThunk(
    'inquiry/getInquiryList',
    async (data, { rejectWithValue }) => {
        const token = localStorage.getItem('token') || null;
        try {
            const response = await getInquiryListService(data?.page,data?.filter,data?.searchTerm,token)
            return {
                status: response.status,
                count: response.data.data.count,
                inquiryData:response.data.data.results
            };
        } catch (error) {
            return rejectWithValue({ data: error });
        }
    }
);

export const getActiveInquiryList = createAsyncThunk(
    'inquiry/getActiveInquiryList',
    async (data, { rejectWithValue }) => {
        const token = localStorage.getItem('token') || null;
        try {
            const response = await getActiveInquiryListService(data?.page,data?.filter,data?.searchTerm,token)
            return {
                status: response.status,
                count: response.data.data.count,
                inquiryData:response.data.data.results
            };
        } catch (error) {
            return rejectWithValue({ data: error });
        }
    }
);

export const getInActiveInquiryList = createAsyncThunk(
    'inquiry/getInActiveInquiryList',
    async (data, { rejectWithValue }) => {
        const token = localStorage.getItem('token') || null;
        try {
            const response = await getInActiveInquiryListService(data?.page,data?.filter,data?.searchTerm,token)
            return {
                status: response.status,
                count: response.data.data.count,
                inquiryData:response.data.data.results
            };
        } catch (error) {
            return rejectWithValue({ data: error });
        }
    }
);

export const getInquiry = createAsyncThunk(
    'inquiry/getInquiry',
    async (id, { rejectWithValue }) => {
        const token = localStorage.getItem('token') || null;
        try {
            const response = await getInquiryByIdService(id,token)
            return {
                status: response.status,
                inquiryData:response.data.data
            };
        } catch (error) {
            return rejectWithValue({ data: error });
        }
    }
);