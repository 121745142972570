import React,{ useState} from "react";
import { Form, Button, Col, Row } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { updatePassword } from "../../redux/slices/authSlice";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

const ChangePassword = ({ show, handleClose }) => {

  const validationSchema = Yup.object().shape({
    new_password: Yup.string()
        .required('Password is required')
        .min(6, 'Password must be at least 6 characters'),
        confirm_password: Yup.string()
        .required('Confirm Password is required')
        .oneOf([Yup.ref('new_password')], 'Confirm Passwords must match')
  });

  const formOptions = { resolver: yupResolver(validationSchema) };

  const dispatch = useDispatch();
  const [isDisabled, setIsDisabled] = useState(false);
  const { register, handleSubmit, reset, formState } = useForm(formOptions);
  const { errors } = formState;

  const onSubmit = async (data) => {
    setIsDisabled(true)
    const formData = new FormData();
    formData.append("check_old_password", true);
    formData.append("old_password", data.old_password);
    formData.append("new_password", data.new_password);
    const response = await dispatch(updatePassword(formData));
    if (response.payload.status === 200) {
      reset();
      localStorage.setItem(
        'user',
        JSON.stringify(response.payload.user)
    );
      toast.success("Password updated successfully");
      handleClose();
    }else{
      toast.error("Try again!");
    }
    setIsDisabled(false)
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      backdrop={true}
      keyboard={false}
      className="update-modal"
    >
      <Modal.Body>
        <h1>Create New Password</h1>
        <p>
          Please create a new password that you don’t use on any other site.
        </p>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Form.Group as={Row} className="mb-0" controlId="">
            <Col md="12" className="d-flex align-items-center">
              <Form.Label column md="6">
                Current Password<span className="astrik">*</span>
              </Form.Label>
            </Col>

            <Col md="12">
              <Form.Control
                type="Current Password"
                placeholder="Add Current Password"
                {...register("old_password")}
              />
              {errors.old_password && (
                <span className="error">This field is required</span>
              )}
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-0" controlId="">
            <Col md="12" className="d-flex align-items-center">
              <Form.Label column md="6">
                New Password<span className="astrik">*</span>
              </Form.Label>
            </Col>

            <Col md="12">
              <Form.Control
                type="New Password"
                placeholder="Add New Password"
                {...register("new_password")}
              />
              {errors.new_password && (
                <span className="error">{errors.new_password?.message}</span>
              )}
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-5" controlId="">
            <Col md="12" className="d-flex align-items-center">
              <Form.Label column md="6">
                Confirm Password<span className="astrik">*</span>
              </Form.Label>
            </Col>

            <Col md="12">
              <Form.Control
                type="Repeat Password"
                placeholder="Repeat Password"
                {...register("confirm_password", { required: true })}
              />
              {errors.confirm_password && (
                <span className="error">{errors.confirm_password?.message}</span>
              )}
            </Col>
          </Form.Group>
          <Button className="w-100" size="lg" type="submit" disabled={isDisabled}>
            Submit
          </Button>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default ChangePassword;
