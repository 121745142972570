import React from 'react';
import GoogleMapReact from 'google-map-react';
import {ImLocation} from 'react-icons/im'
const Marker = ({ children }) => children;

const Map = ({ properties, pinIcon }) => {
  const defaultProps = {
    center: {
      lat: properties.length > 0 ? Number(properties[0].property_id?.address_data?.latitude) : 10.99835602,
      lng: properties.length > 0 ? Number(properties[0].property_id?.address_data?.longitude) : 77.01502627
    },
    zoom: 11
  };
  return (
    <>
      {
        properties.length > 0 ?

          <GoogleMapReact
            bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_API_KEY }}
            defaultCenter={defaultProps.center}
            defaultZoom={defaultProps.zoom}
            yesIWantToUseGoogleMapApiInternals
          >

            {properties.map((property, index) => {
              return (
                <Marker key={`marker-${index}`} lat={Number(property?.property_id?.address_data?.latitude)} lng={Number(property?.property_id?.address_data?.longitude)}  >
                  {
                    pinIcon ? <div className='no-img'><ImLocation size={30} /></div>
                      :
                      <div className="marker-wrap">
                        <div
                          className="property-marker"
                        >
                          ${property?.property_id?.price}
                        </div>
                      </div>
                  }
                </Marker>
              );
            })}

          </GoogleMapReact> : null
      }
    </>
  )
}

export default Map