import axios from "axios";
import { API_BASE_URL } from './constants';

export const updateProjectionDataService = (data,id,token) => {
    const headers = { Authorization: 'Bearer ' + token };
    const payload ={ 
        projection_data:{
            ...data
        }
    }
    return axios.patch(`${API_BASE_URL}property-projection/${id}/`, payload, {
        headers,
    });
};