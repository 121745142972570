import React, { useState } from "react";
import { Form, Button, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import Table from "react-bootstrap/Table";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import "../property/property.scss";
import "./setting.scss";
import Layout from "../../common/layout";

const SettingList = () => {
  // eslint-disable-next-line
  const [showDelete, setShowDelete] = useState(false);
  const [showEmail, setShowEmail] = useState(false);
  // eslint-disable-next-line
  const [newEmailTemplate, setNewEmailTemplate] = useState(false);

  return (
    <>
      <Layout>
        <div className="content-body">
          <div className="content-body-inner">
            <div className="page-header">
              <h1>Email Setting</h1>
              <Breadcrumb>
                <span>
                  <Link to={`/setting`} className="link-btn">
                    Setting
                  </Link>
                </span>

                <span className="mx-1"> / </span>

                <span>Email Setting</span>
              </Breadcrumb>
            </div>
            <div className="page-content setting-page-content">
              <div className="filter-area">
                <div className="tab-bar"></div>
                <div className="table-filter d-flex"></div>
              </div>
              <div className="listing-table setting-listing-table">
                <Table responsive="sm">
                  <thead>
                    <tr>
                      <th className="email-th"> From Email</th>
                      <th className="date-th"> Last Updated Date</th>
                      <th className="action-th"> Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td> revnest@mail.revnest.com</td>

                      <td>
                        <span>26/04/2020</span>,<span>12:42 AM</span>
                      </td>
                      <td>
                        <button
                          to="/"
                          className="link-btn"
                          onClick={() => setShowEmail(true)}
                        >
                          Edit
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>

              <Modal
                show={showEmail}
                className="edit-email-modal"
                onHide={() => setShowEmail(false)}
              >
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                  <h3>Edit Email id </h3>
                  <p>
                    The main email address from which users receive all RevNest
                    email and you can change it as an admin.
                  </p>
                  <Form className="mt-4">
                    <Form.Group as={Row} className="" controlId="">
                      <Form.Label column md="12">
                        Email Id <span>*</span>
                      </Form.Label>
                      <Col md="12">
                        <Form.Control
                          type="email"
                          placeholder="revnest@mail.revnest.com"
                          name="email"
                        />
                      </Col>
                    </Form.Group>
                  </Form>
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    variant="secondary"
                    onClick={() => setShowEmail(false)}
                  >
                    No,Cancel
                  </Button>
                  <Button
                    variant="primary"
                    className=""
                    onClick={() => setShowEmail(false)}
                  >
                    Yes, Update ‘From Email’
                  </Button>
                </Modal.Footer>
              </Modal>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default SettingList;
