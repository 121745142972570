import React, { useState, useEffect } from "react";
import { Form, InputGroup, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import Table from "react-bootstrap/Table";
import Pagination from "react-bootstrap/Pagination";
import { BsSearch } from "react-icons/bs";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Dropdown from "react-bootstrap/Dropdown";
import { IoBedOutline } from "react-icons/io5";
import { HiOutlineUsers } from "react-icons/hi";
import { BsChatLeftText } from "react-icons/bs";
import { BiBath, BiDotsVerticalRounded } from "react-icons/bi";
import { RiShapeLine } from "react-icons/ri";
import { FiHome } from "react-icons/fi";
import { HiOutlineUser } from "react-icons/hi";
import Loader from "../../common/Loader/loader";
import { useDispatch } from "react-redux";
import "./property.scss";
import Layout from "../../common/layout";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import {
  getPropertyList,
  getFeaturePropertyList,
  getActivePropertyList,
  getInActivePropertyList,
  deleteProperty,
  getDashboardPropertyData,
  updatProperty,
} from "../../redux/slices/propertySlice";
import { toast } from "react-toastify";
import { useDebounce } from "../../common/Functions/useDebounce";

const PropertyList = () => {
  const dispatch = useDispatch();
  const [isActive, setIsActive] = useState();
  const [label, setLabel] = useState();
  const [isFeature, setIsFeature] = useState();
  const [showList, setShowList] = useState(false);
  const [showUnlist, setShowUnlist] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [filter, setFilter] = useState("-updated_date");
  const [tab, setTab] = useState("all");
  const [page, setpage] = useState(1);
  const [id, setId] = useState(0);
  const [propertyData, setPropertyData] = useState([]);
  const [dashboardPropertyData, setDashboardPropertyData] = useState([]);
  const [totalrecords, settotalrecords] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  const showListPopup = (e) => {
    if (e.target.checked) {
      setShowList(true);
      setShowUnlist(false);
    } else {
      setShowList(false);
      setShowUnlist(true);
    }
  };

  const pageNums = [];

  for (let i = 1; i <= Math.ceil(totalrecords / 10); i++) {
    pageNums.push(i);
  }

  const getPropertylist = async () => {
    setIsDisabled(true);
    var response = [];
    if (tab === "all") {
      response = await dispatch(getPropertyList({ page, filter, searchTerm }));
    } else if (tab === "featured") {
      response = await dispatch(
        getFeaturePropertyList({ page, filter, searchTerm })
      );
    } else if (tab === "active") {
      response = await dispatch(
        getActivePropertyList({ page, filter, searchTerm })
      );
    } else {
      response = await dispatch(
        getInActivePropertyList({ page, filter, searchTerm })
      );
    }
    if (response.payload.status === 200) {
      setPropertyData(response.payload.propertyData);
      settotalrecords(response.payload.count);
    }
    setIsDisabled(false);
  };

  const getDashboardPropertyDataList = async () => {
    const response = await dispatch(getDashboardPropertyData());
    if (response.payload.status === 200) {
      setDashboardPropertyData(response.payload.dashboardPropertyData);
    }
  };

  useEffect(() => {
    getPropertylist();
    getDashboardPropertyDataList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab, page, filter, debouncedSearchTerm]);

  const removeProperty = async () => {
    try {
      setIsDisabled(true);
      if (id !== 0) {
        const response = await dispatch(deleteProperty(id));
        if (response.payload.status === 204) {
          toast.success("Remove Property successfully");
        }
      } else {
        toast.error("Property Id are required");
      }
    } catch (error) {
      toast.error("something went wrong");
    } finally {
      setIsDisabled(false);
      getPropertylist();
    }
  };

  const updateList = async () => {
    try {
      setIsDisabled(true);
      if (id !== 0) {
        var PropertyDetail;
        if (label === "featured") {
          PropertyDetail = { featured: !isFeature };
        } else {
          PropertyDetail = { is_active: !isActive };
        }
        const response = await dispatch(updatProperty({ PropertyDetail, id }));
        if (response.payload.status === 200) {
          toast.success("List Update successfully");
        }
      } else {
        toast.error("Property Id are required");
      }
    } catch (error) {
      toast.error("something went wrong");
    } finally {
      setIsDisabled(false);
      getPropertylist();
    }
    setLabel("");
  };

  return (
    <>
      <Layout>
        <div className="content-body">
          <div className="content-body-inner">
            <div className="page-header">
              <h1>Properties List</h1>
              <Breadcrumb>
                <Link to={`/properties`} className="link-btn">
                  Properties
                </Link>
                <span className="mx-1">/</span>
                <span>Properties List</span>
              </Breadcrumb>
            </div>
            <div className="page-content proprty-list-wrap">
              <div className="analytics">
                <div className="left-wrap">
                  <div className="total-wrap">
                    <span className="icon">
                      <HiOutlineUser />
                    </span>
                    <div className="data">
                      <p className="m-0">
                        {dashboardPropertyData?.data?.total_customers}
                      </p>
                      <small>Total Customers</small>
                    </div>
                  </div>
                  <div className="total-wrap">
                    <span className="icon">
                      <FiHome />
                    </span>
                    <div className="data">
                      <p className="m-0">
                        {dashboardPropertyData?.data?.total_properties}
                      </p>
                      <small>Total Properties</small>
                    </div>
                  </div>
                  <div className="total-wrap">
                    <span className="icon">
                      <HiOutlineUsers />
                    </span>
                    <div className="data">
                      <p className="m-0">
                        {dashboardPropertyData?.data?.agent}
                      </p>
                      <small>Total Agents</small>
                    </div>
                  </div>
                  <div className="total-wrap">
                    <span className="icon">
                      <BsChatLeftText />
                    </span>
                    <div className="data">
                      <p className="m-0">
                        {dashboardPropertyData?.data?.inquaries}
                      </p>
                      <small>Total Inquiries</small>
                    </div>
                  </div>
                </div>
              </div>
              <div className="filter-area">
                <div className="table-search-bar">
                  <Form.Group
                    className="search-field-wrap"
                    controlId="exampleForm.ControlInput1"
                  >
                    <InputGroup className="searchbox-wrap">
                      <Form.Control
                        placeholder="Search property name here"
                        aria-label="Search property name here"
                        aria-describedby="basic-addon2"
                        value={searchTerm}
                        onChange={(e) => {
                          setSearchTerm(e?.target?.value);
                          setpage(1);
                        }}
                      />
                      <Button variant="outline-secondary" id="button-addon2">
                        <BsSearch />
                      </Button>
                    </InputGroup>
                  </Form.Group>
                </div>
                <div className="table-filter">
                  <Dropdown>
                    <Dropdown.Toggle id="dropdown-basic">
                      {filter === "-updated_date" ? "Newest" : "Oldest"}
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item
                        href="#"
                        onClick={() => {
                          setFilter(
                            filter === "-updated_date"
                              ? "updated_date"
                              : "-updated_date"
                          );
                        }}
                      >
                        {filter === "updated_date" ? "Newest" : "Oldest"}
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
              <div className="tab-table-wrap">
                <Tabs
                  defaultActiveKey="all"
                  id="uncontrolled-tab-example"
                  className=""
                  onSelect={(key) => {
                    setTab(key);
                    setpage(1);
                  }}
                >
                  <Tab eventKey="all" title="All">
                    <div className="listing-table blog-listing-table">
                      <Table responsive="xl">
                        <thead>
                          <tr>
                            {/* <th className="check">
                              <Form.Check
                                type="checkbox"
                                id="default-checkbox"
                                className="checkbox-label"
                              />
                            </th> */}
                            <th className="id-th"> Id</th>
                            <th className="photo-th"> Photo</th>
                            <th className="property-th"> Property</th>
                            <th className="location-th"> Location</th>
                            <th className="specs-th"> Specifications</th>
                            <th className="price-th"> Price</th>
                            <th className="revenue-th"> Revenue</th>
                            <th className="agent-th"> Agent</th>
                            <th className="status-th"> Status</th>
                            <th className="status-th"> Featured</th>
                            <th className="action-th"> Action</th>
                            <th className="empty-th"> </th>
                          </tr>
                        </thead>
                        <tbody>
                          {isDisabled ? (
                            <tr>
                              <td className="text-center" colspan="10">
                                <Loader />
                              </td>
                            </tr>
                          ) : propertyData?.length > 0 ? (
                            propertyData.map((item) => (
                              <tr>
                                <td>{item?.id}</td>
                                <td className="photo">
                                  {item?.media_files[0]?.url && (
                                    <div className="photo-wrap">
                                      <img
                                        src={item?.media_files[0]?.url}
                                        alt="property-photo1"
                                      />
                                    </div>
                                  )}
                                </td>
                                <td>
                                  {" "}
                                  <Link
                                    to={`/property/${item?.id}`}
                                    className="link-btn"
                                  >
                                    {item?.title}{" "}
                                  </Link>
                                </td>
                                <td>{item?.address_data?.address}</td>
                                <td className="specs">
                                  <div className="d-flex">
                                    <div className="d-flex spec-inner-wrap">
                                      <span>
                                        <IoBedOutline />
                                      </span>
                                      <p className="m-0">
                                        {item?.total_bedrooms} Beds
                                      </p>
                                    </div>
                                    <div className="d-flex spec-inner-wrap">
                                      <span>
                                        <BiBath />
                                      </span>
                                      <p className="m-0">
                                        {item?.total_bathrooms} Baths
                                      </p>
                                    </div>
                                    <div className="d-flex spec-inner-wrap">
                                      <span>
                                        <RiShapeLine />
                                      </span>
                                      <p className="m-0">
                                        {item?.property_size} sq ft
                                      </p>
                                    </div>
                                  </div>
                                </td>
                                <td>
                                  {" "}
                                  <p className="m-0">
                                    $ {item?.price.toLocaleString()}
                                  </p>
                                </td>
                                <td>
                                  {" "}
                                  <p className="m-0">
                                    $ {item?.account?.revenue.toLocaleString()}
                                  </p>
                                </td>
                                <td>
                                  {item?.seller_detail?.first_name +
                                    " " +
                                    item?.seller_detail?.last_name}
                                </td>
                                <td className="status">
                                  {" "}
                                  <p
                                    className={`m-0 ${item?.status.toLowerCase()}`}
                                  >
                                    {item?.status}
                                  </p>
                                </td>
                                <td className="action switch">
                                  <Form>
                                    <Form.Check
                                      type="switch"
                                      id="custom-switch"
                                      label="Featured"
                                      checked={item?.featured}
                                      onChange={(e) => {
                                        setId(item?.id);
                                        setIsFeature(item?.featured);
                                        setLabel("featured");
                                        showListPopup(e);
                                      }}
                                    />
                                  </Form>
                                </td>
                                <td className="action switch">
                                  <Form>
                                    <Form.Check
                                      type="switch"
                                      id="custom-switch"
                                      label="List"
                                      checked={item?.is_active}
                                      onChange={(e) => {
                                        setId(item?.id);
                                        setIsActive(item?.is_active);
                                        showListPopup(e);
                                      }}
                                    />
                                  </Form>
                                </td>
                                <td className="dd-action">
                                  <Dropdown>
                                    <Dropdown.Toggle id="dropdown-basic">
                                      <BiDotsVerticalRounded />
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                      <Dropdown.Item
                                        onClick={() => {
                                          setId(item?.id);
                                          setShowDelete(true);
                                        }}
                                      >
                                        Remove
                                      </Dropdown.Item>
                                    </Dropdown.Menu>
                                  </Dropdown>
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td className="text-center" colspan="10">
                                No Property Found
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </Table>
                    </div>
                  </Tab>
                  <Tab eventKey="featured" title="Featured">
                    <div className="listing-table blog-listing-table">
                      <Table responsive="xl">
                        <thead>
                          <tr>
                            <th className="check">
                              <Form.Check
                                type="checkbox"
                                id="default-checkbox"
                                className="checkbox-label"
                              />
                            </th>
                            <th className="id-th"> Id</th>
                            <th className="photo-th"> Photo</th>
                            <th className="property-th"> Property</th>
                            <th className="location-th"> Location</th>
                            <th className="specs-th"> Specifications</th>
                            <th className="price-th"> Price</th>
                            <th className="revenue-th"> Revenue</th>
                            <th className="agent-th"> Agent</th>
                            <th className="status-th"> Status</th>
                            <th className="status-th"> Featured</th>
                            <th className="action-th"> Action</th>
                            <th className="empty-th"> </th>
                          </tr>
                        </thead>
                        <tbody>
                          {isDisabled ? (
                            <tr>
                              <td className="text-center" colspan="10">
                                <Loader />
                              </td>
                            </tr>
                          ) : propertyData?.length > 0 ? (
                            propertyData.map((item) => (
                              <tr>
                                <td className="check">
                                  <Form.Check
                                    type="checkbox"
                                    id="default-checkbox"
                                    className="checkbox-label"
                                  />
                                </td>
                                <td>{item?.id}</td>
                                <td className="photo">
                                  {item?.media_files[0]?.url && (
                                    <div className="photo-wrap">
                                      <img
                                        src={item?.media_files[0]?.url}
                                        alt="property-photo1"
                                      />
                                    </div>
                                  )}
                                </td>
                                <td>
                                  {" "}
                                  <Link
                                    to={`/property/${item?.id}`}
                                    className="link-btn"
                                  >
                                    {item?.title}{" "}
                                  </Link>
                                </td>
                                <td>{item?.address_data?.address}</td>
                                <td className="specs">
                                  <div className="d-flex">
                                    <div className="d-flex spec-inner-wrap">
                                      <span>
                                        <IoBedOutline />
                                      </span>
                                      <p className="m-0">
                                        {item?.total_bedrooms} Beds
                                      </p>
                                    </div>
                                    <div className="d-flex spec-inner-wrap">
                                      <span>
                                        <BiBath />
                                      </span>
                                      <p className="m-0">
                                        {item?.total_bathrooms} Baths
                                      </p>
                                    </div>
                                    <div className="d-flex spec-inner-wrap">
                                      <span>
                                        <RiShapeLine />
                                      </span>
                                      <p className="m-0">
                                        {item?.property_size} sq ft
                                      </p>
                                    </div>
                                  </div>
                                </td>
                                <td>
                                  {" "}
                                  <p className="m-0">
                                    $ {item?.price.toLocaleString()}
                                  </p>
                                </td>
                                <td>
                                  {" "}
                                  <p className="m-0">
                                    $ {item?.account?.revenue.toLocaleString()}
                                  </p>
                                </td>
                                <td>
                                  {item?.seller_detail?.first_name +
                                    " " +
                                    item?.seller_detail?.last_name}
                                </td>
                                <td className="status">
                                  {" "}
                                  <p
                                    className={`m-0 ${item?.status.toLowerCase()}`}
                                  >
                                    {item?.status}
                                  </p>
                                </td>
                                <td className="action switch">
                                  <Form>
                                    <Form.Check
                                      type="switch"
                                      id="custom-switch"
                                      label="Featured"
                                      checked={item?.featured}
                                      onChange={(e) => {
                                        setId(item?.id);
                                        setIsFeature(item?.featured);
                                        setLabel("featured");
                                        showListPopup(e);
                                      }}
                                    />
                                  </Form>
                                </td>
                                <td className="action switch">
                                  <Form>
                                    <Form.Check
                                      type="switch"
                                      id="custom-switch"
                                      label="List"
                                      checked={item?.is_active}
                                      onChange={(e) => {
                                        setId(item?.id);
                                        setIsActive(item?.is_active);
                                        showListPopup(e);
                                      }}
                                    />
                                  </Form>
                                </td>
                                <td className="dd-action">
                                  <Dropdown>
                                    <Dropdown.Toggle id="dropdown-basic">
                                      <BiDotsVerticalRounded />
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                      <Dropdown.Item
                                        onClick={() => {
                                          setId(item?.id);
                                          setShowDelete(true);
                                        }}
                                      >
                                        Remove
                                      </Dropdown.Item>
                                    </Dropdown.Menu>
                                  </Dropdown>
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td className="text-center" colspan="10">
                                No Property Found
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </Table>
                    </div>
                  </Tab>
                  <Tab eventKey="active" title="Active">
                    <div className="listing-table blog-listing-table">
                      <Table responsive="xl">
                        <thead>
                          <tr>
                            <th className="check">
                              <Form.Check
                                type="checkbox"
                                id="default-checkbox"
                                className="checkbox-label"
                              />
                            </th>
                            <th className="id-th"> Id</th>
                            <th className="photo-th"> Photo</th>
                            <th className="property-th"> Property</th>
                            <th className="location-th"> Location</th>
                            <th className="specs-th"> Specifications</th>
                            <th className="price-th"> Price</th>
                            <th className="revenue-th"> Revenue</th>
                            <th className="agent-th"> Agent</th>
                            <th className="status-th"> Status</th>
                            <th className="status-th"> Featured</th>
                            <th className="action-th"> Action</th>
                            <th className="empty-th"> </th>
                          </tr>
                        </thead>
                        <tbody>
                          {isDisabled ? (
                            <tr>
                              <td className="text-center" colspan="10">
                                <Loader />
                              </td>
                            </tr>
                          ) : propertyData?.length > 0 ? (
                            propertyData.map((item) => (
                              <tr>
                                <td className="check">
                                  <Form.Check
                                    type="checkbox"
                                    id="default-checkbox"
                                    className="checkbox-label"
                                  />
                                </td>
                                <td>{item?.id}</td>
                                <td className="photo">
                                  {item?.media_files[0]?.url && (
                                    <div className="photo-wrap">
                                      <img
                                        src={item?.media_files[0]?.url}
                                        alt="property-photo1"
                                      />
                                    </div>
                                  )}
                                </td>
                                <td>
                                  {" "}
                                  <Link
                                    to={`/property/${item?.id}`}
                                    className="link-btn"
                                  >
                                    {item?.title}{" "}
                                  </Link>
                                </td>
                                <td>{item?.address_data?.address}</td>
                                <td className="specs">
                                  <div className="d-flex">
                                    <div className="d-flex spec-inner-wrap">
                                      <span>
                                        <IoBedOutline />
                                      </span>
                                      <p className="m-0">
                                        {item?.total_bedrooms} Beds
                                      </p>
                                    </div>
                                    <div className="d-flex spec-inner-wrap">
                                      <span>
                                        <BiBath />
                                      </span>
                                      <p className="m-0">
                                        {item?.total_bathrooms} Baths
                                      </p>
                                    </div>
                                    <div className="d-flex spec-inner-wrap">
                                      <span>
                                        <RiShapeLine />
                                      </span>
                                      <p className="m-0">
                                        {item?.property_size} sq ft
                                      </p>
                                    </div>
                                  </div>
                                </td>
                                <td>
                                  {" "}
                                  <p className="m-0">
                                    $ {item?.price.toLocaleString()}
                                  </p>
                                </td>
                                <td>
                                  {" "}
                                  <p className="m-0">
                                    $ {item?.account?.revenue.toLocaleString()}
                                  </p>
                                </td>
                                <td>
                                  {item?.seller_detail?.first_name +
                                    " " +
                                    item?.seller_detail?.last_name}
                                </td>
                                <td className="status">
                                  {" "}
                                  <p
                                    className={`m-0 ${item?.status.toLowerCase()}`}
                                  >
                                    {item?.status}
                                  </p>
                                </td>
                                <td className="action switch">
                                  <Form>
                                    <Form.Check
                                      type="switch"
                                      id="custom-switch"
                                      label="Featured"
                                      checked={item?.featured}
                                      onChange={(e) => {
                                        setId(item?.id);
                                        setIsFeature(item?.featured);
                                        setLabel("featured");
                                        showListPopup(e);
                                      }}
                                    />
                                  </Form>
                                </td>
                                <td className="action switch">
                                  <Form>
                                    <Form.Check
                                      type="switch"
                                      id="custom-switch"
                                      label="List"
                                      checked={item?.is_active}
                                      onChange={(e) => {
                                        setId(item?.id);
                                        setIsActive(item?.is_active);
                                        showListPopup(e);
                                      }}
                                    />
                                  </Form>
                                </td>
                                <td className="dd-action">
                                  <Dropdown>
                                    <Dropdown.Toggle id="dropdown-basic">
                                      <BiDotsVerticalRounded />
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                      <Dropdown.Item
                                        onClick={() => {
                                          setId(item?.id);
                                          setShowDelete(true);
                                        }}
                                      >
                                        Remove
                                      </Dropdown.Item>
                                    </Dropdown.Menu>
                                  </Dropdown>
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td className="text-center" colspan="10">
                                No Property Found
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </Table>
                    </div>
                  </Tab>
                  <Tab eventKey="inactive" title="Inactive">
                    <div className="listing-table blog-listing-table">
                      <Table responsive="xl">
                        <thead>
                          <tr>
                            <th className="check">
                              <Form.Check
                                type="checkbox"
                                id="default-checkbox"
                                className="checkbox-label"
                              />
                            </th>
                            <th className="id-th"> Id</th>
                            <th className="photo-th"> Photo</th>
                            <th className="property-th"> Property</th>
                            <th className="location-th"> Location</th>
                            <th className="specs-th"> Specifications</th>
                            <th className="price-th"> Price</th>
                            <th className="revenue-th"> Revenue</th>
                            <th className="agent-th"> Agent</th>
                            <th className="status-th"> Status</th>
                            <th className="status-th"> Featured</th>
                            <th className="action-th"> Action</th>
                            <th className="empty-th"> </th>
                          </tr>
                        </thead>
                        <tbody>
                          {isDisabled ? (
                            <tr>
                              <td className="text-center" colspan="10">
                                <Loader />
                              </td>
                            </tr>
                          ) : propertyData?.length > 0 ? (
                            propertyData.map((item) => (
                              <tr>
                                <td className="check">
                                  <Form.Check
                                    type="checkbox"
                                    id="default-checkbox"
                                    className="checkbox-label"
                                  />
                                </td>
                                <td>{item?.id}</td>
                                <td className="photo">
                                  {item?.media_files[0]?.url && (
                                    <div className="photo-wrap">
                                      <img
                                        src={item?.media_files[0]?.url}
                                        alt="property-photo1"
                                      />
                                    </div>
                                  )}
                                </td>
                                <td>
                                  {" "}
                                  <Link
                                    to={`/property/${item?.id}`}
                                    className="link-btn"
                                  >
                                    {item?.title}{" "}
                                  </Link>
                                </td>
                                <td>{item?.address_data?.address}</td>
                                <td className="specs">
                                  <div className="d-flex">
                                    <div className="d-flex spec-inner-wrap">
                                      <span>
                                        <IoBedOutline />
                                      </span>
                                      <p className="m-0">
                                        {item?.total_bedrooms} Beds
                                      </p>
                                    </div>
                                    <div className="d-flex spec-inner-wrap">
                                      <span>
                                        <BiBath />
                                      </span>
                                      <p className="m-0">
                                        {item?.total_bathrooms} Baths
                                      </p>
                                    </div>
                                    <div className="d-flex spec-inner-wrap">
                                      <span>
                                        <RiShapeLine />
                                      </span>
                                      <p className="m-0">
                                        {item?.property_size} sq ft
                                      </p>
                                    </div>
                                  </div>
                                </td>
                                <td>
                                  {" "}
                                  <p className="m-0">
                                    $ {item?.price.toLocaleString()}
                                  </p>
                                </td>
                                <td>
                                  {" "}
                                  <p className="m-0">
                                    $ {item?.account?.revenue.toLocaleString()}
                                  </p>
                                </td>
                                <td>
                                  {item?.seller_detail?.first_name +
                                    " " +
                                    item?.seller_detail?.last_name}
                                </td>
                                <td className="status">
                                  {" "}
                                  <p
                                    className={`m-0 ${item?.status.toLowerCase()}`}
                                  >
                                    {item?.status}
                                  </p>
                                </td>
                                <td className="action switch">
                                  <Form>
                                    <Form.Check
                                      type="switch"
                                      id="custom-switch"
                                      label="Featured"
                                      checked={item?.featured}
                                      onChange={(e) => {
                                        setId(item?.id);
                                        setIsFeature(item?.featured);
                                        setLabel("featured");
                                        showListPopup(e);
                                      }}
                                    />
                                  </Form>
                                </td>
                                <td className="action switch">
                                  <Form>
                                    <Form.Check
                                      type="switch"
                                      id="custom-switch"
                                      label="List"
                                      checked={item?.is_active}
                                      onChange={(e) => {
                                        setId(item?.id);
                                        setIsActive(item?.is_active);
                                        showListPopup(e);
                                      }}
                                    />
                                  </Form>
                                </td>
                                <td className="dd-action">
                                  <Dropdown>
                                    <Dropdown.Toggle id="dropdown-basic">
                                      <BiDotsVerticalRounded />
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                      <Dropdown.Item
                                        onClick={() => {
                                          setId(item?.id);
                                          setShowDelete(true);
                                        }}
                                      >
                                        Remove
                                      </Dropdown.Item>
                                    </Dropdown.Menu>
                                  </Dropdown>
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td className="text-center" colspan="10">
                                No Property Found
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </Table>
                    </div>
                  </Tab>
                </Tabs>
              </div>

              <div className="listing-table-pagination">
                <div className="pagination-info">
                  <p>
                    Showing <span>{propertyData?.length}</span> from{" "}
                    <span>{totalrecords}</span> data{" "}
                  </p>
                </div>
                <Pagination>
                  {page !== 1 && (
                    <Pagination.Prev
                      className="prev-btn"
                      onClick={() => setpage(page - 1)}
                    />
                  )}

                  {pageNums.map((pageNum) => (
                    <Pagination.Item
                      key={pageNum}
                      active={page === pageNum}
                      onClick={() => setpage(pageNum)}
                    >
                      {pageNum}
                    </Pagination.Item>
                  ))}
                  {page !== Math.ceil(totalrecords / 10) &&
                    totalrecords !== 0 && (
                      <Pagination.Next
                        className="next-btn"
                        onClick={() => setpage(page + 1)}
                      />
                    )}
                </Pagination>
              </div>
            </div>
          </div>
        </div>
        <Modal show={showList} onHide={() => setShowList(false)}>
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <h3>{`Sure you want to again ${
              label === "featured" ? "Featured" : "List"
            } Property? `}</h3>
            <p>
              {`A property will be ${
                label === "featured" ? "Featured" : "Listed"
              } to the list so current users can see it.`}
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => {
                setId(0);
                setShowList(false);
              }}
            >
              No,Cancel
            </Button>
            <Button
              variant="primary"
              onClick={() => {
                updateList();
                setShowList(false);
              }}
            >
              Yes,Confirm
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal show={showUnlist} onHide={() => setShowUnlist(false)}>
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <h3>{`Sure you want to ${
              label === "featured" ? "Unfeatured" : "Unlist"
            } Property? `}</h3>
            <p>
              {`A property will be ${
                label === "featured" ? "Unfeatured" : "unlisted"
              } from the list so current users cannot
              see it.`}
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => {
                setId(0);
                setLabel("");
                setShowUnlist(false);
              }}
            >
              No,Cancel
            </Button>
            <Button
              variant="primary"
              onClick={() => {
                updateList();
                setShowUnlist(false);
              }}
            >
              Yes,Confirm
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal show={showDelete} onHide={() => setShowDelete(false)}>
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <h3>Sure you want to Remove Property? </h3>
            <p>
              {" "}
              This action will permanently remove this property from the list
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => {
                setId(0);
                setShowDelete(false);
              }}
            >
              No,Cancel
            </Button>
            <Button
              className="btn-alert"
              onClick={() => {
                removeProperty();
                setShowDelete(false);
              }}
            >
              Yes,Confirm
            </Button>
          </Modal.Footer>
        </Modal>
      </Layout>
    </>
  );
};

export default PropertyList;
