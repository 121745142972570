import axios from 'axios';

export const getAdminUsersService = (token, offset, ordering) => {
	const headers = { Authorization: 'Bearer ' + token };
	return axios.get(
		`https://dev-api.revnest.com/admins/?offset=${
			(Number(offset) - 1) * 10
		}&limit=10&ordering=${ordering}`,
		{
			headers
		}
	);
};
