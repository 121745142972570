import React from "react";
import { NavLink } from "react-bootstrap";
import RevNest from "../assets/images/revnest-logo-admin.svg";
import { FiHome, FiChevronRight } from "react-icons/fi";
import { RxDashboard } from "react-icons/rx";
import { BsChatLeftText, BsBookmark, BsXLg } from "react-icons/bs";
import { BiUser } from "react-icons/bi";
import { TfiCrown } from "react-icons/tfi";
import { HiOutlineUserCircle } from "react-icons/hi2";
import { IoSettingsOutline } from "react-icons/io5";
import { Link, useNavigate, useLocation } from "react-router-dom";
import "./common.scss";

const Sidebar = ({ isCollapsable, setIsCollapsable }) => {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <div className={`sidebar-wrap ${isCollapsable ? "sidebar-open" : ""}`}>
      <div className="logo">
        <img src={RevNest} alt="RevNest Logo" onClick={() => navigate("/")} />
        <span onClick={() => setIsCollapsable(false)}>
          <BsXLg />
        </span>
      </div>

      <div className="sidebar-inner-wrap">
        <div className="sidebar-menu">
          <ul>
            <li
              onClick={() => {
                navigate("/dashboard");
              }}
            >
              <NavLink
                className={location.pathname === "/dashboard" ? "active" : ""}
              >
                <div className="d-flex">
                  <div className="menu-inner">
                    <span>
                      <RxDashboard />
                    </span>
                    <p className="m-0">Dashboard</p>
                  </div>
                </div>
              </NavLink>
            </li>
            <li
              onClick={() => {
                navigate("/properties");
              }}
            >
              <NavLink
                className={
                  location.pathname.startsWith("/properties") ||
                  location.pathname.startsWith("/property")
                    ? "active"
                    : ""
                }
              >
                <div className="d-flex">
                  <div className="menu-inner">
                    <span>
                      <FiHome />
                    </span>
                    <p className="m-0">Properties</p>
                  </div>
                </div>
                <span>
                  <FiChevronRight />
                </span>
              </NavLink>
            </li>
            <li
              onClick={() => {
                navigate("/inquiries");
              }}
            >
              <NavLink
                className={
                  location.pathname.startsWith("/inquir") ? "active" : ""
                }
              >
                <div className="d-flex">
                  <div className="menu-inner">
                    <span>
                      <BsChatLeftText />
                    </span>
                    <p className="m-0">Inquiries</p>
                  </div>
                </div>
                <span>
                  <FiChevronRight />
                </span>
              </NavLink>
            </li>
            <li
              onClick={() => {
                navigate("/customers");
              }}
            >
              <NavLink
                className={location.pathname === "/customers" ? "active" : ""}
              >
                <div className="d-flex">
                  <div className="menu-inner">
                    <span>
                      <BiUser />
                    </span>
                    <p className="m-0">Customers</p>
                  </div>
                </div>
                <span>
                  <FiChevronRight />
                </span>
              </NavLink>
            </li>

            <li
              onClick={() => {
                navigate("/articles");
              }}
            >
              <NavLink
                className={
                  location.pathname.startsWith("/article") ? "active" : ""
                }
              >
                <div className="d-flex">
                  <div className="menu-inner">
                    <span>
                      <BsBookmark />
                    </span>
                    <p className="m-0">Articles</p>
                  </div>
                </div>
                <span>
                  <FiChevronRight />
                </span>
              </NavLink>
            </li>

            <li
              onClick={() => {
                navigate("/brands");
              }}
            >
              <NavLink
                className={
                  location.pathname.startsWith("/brand") ||
                  location.pathname.startsWith("/add-brands")
                    ? "active"
                    : ""
                }
              >
                <div className="d-flex">
                  <div className="menu-inner">
                    <span>
                      <TfiCrown />
                    </span>
                    <p className="m-0">Brands</p>
                  </div>
                </div>
                <span>
                  <FiChevronRight />
                </span>
              </NavLink>
            </li>

            <li
              onClick={() => {
                navigate("/adminusers");
              }}
            >
              <NavLink
                className={
                  location.pathname.startsWith("/adminusers") ||
                  location.pathname.startsWith("/new-admin")
                    ? "active"
                    : ""
                }
              >
                <div className="d-flex">
                  <div className="menu-inner">
                    <span className="small-icon">
                      <HiOutlineUserCircle />
                    </span>
                    <p className="m-0">Admins</p>
                  </div>
                </div>
                <span>
                  <FiChevronRight />
                </span>
              </NavLink>
            </li>

            <li
              onClick={(e) => {
                e.preventDefault();
                navigate("/setting/email-setting");
              }}
              className={
                location.pathname === "/setting/email-setting" ||
                location.pathname === "/setting/amenities-setting"
                  ? "menu-open"
                  : ""
              }
            >
              <NavLink
                className={
                  location.pathname === "/setting/email-setting" ||
                  location.pathname === "/setting/amenities-setting"
                    ? "active"
                    : ""
                }
              >
                <div className="d-flex">
                  <div className="menu-inner">
                    <span>
                      <IoSettingsOutline />
                    </span>
                    <p className="m-0">Setting</p>
                  </div>
                </div>
                <span className="chevron">
                  <FiChevronRight />
                </span>
              </NavLink>
              <div className="meta-menu">
                <Link
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  to="/setting/email-setting"
                  className={
                    location.pathname === "/setting/email-setting"
                      ? "link-btn active"
                      : "link-btn "
                  }
                >
                  Email Setting
                </Link>
                <Link
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  to="/setting/amenities-setting"
                  className={
                    location.pathname === "/setting/amenities-setting"
                      ? "link-btn active"
                      : "link-btn "
                  }
                >
                  Amenities Setting
                </Link>
              </div>
            </li>
          </ul>
        </div>
        <div className="sidebar-footer">
          <h5>Revnest Real Estate Admin</h5>
          <small>© 2023 All Rights Reserved</small>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
