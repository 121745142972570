import React, { useState } from 'react';
import { Form, Button, Row, Col } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import ChangeContact from './ChangeContact';
import { verifyPassword } from '../../redux/slices/authSlice';
import { useDispatch } from 'react-redux';
import { useForm } from "react-hook-form";
import { toast } from 'react-toastify';

const PhoneUpdate = (props) => {
    const dispatch = useDispatch()
    const [show, setShow] = useState(false);
    const [showContactModal, setShowContactModal] = useState(false);
    const [isDisabled, setIsDisabled] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    // Hide contact modal
    const hideContactModal = () => setShowContactModal(false);

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
      } = useForm();
      const onSubmit = async (data) => {
        setIsDisabled(true)
        const formData = new FormData();
        formData.append("password", data.password);
        const response = await dispatch(verifyPassword(formData));
        if (response.payload.status === 200) {
            reset();
            handleClose()
            toast.success("Password matched successfully");
            setShowContactModal(true)
        }else{
            toast.error("Provided password is mismatch");
        }
        setIsDisabled(false)
      };

   
    return (
        <div>
            <button
                type="button"
                variant="link"
                className="link-btn"
                onClick={handleShow}
            >
                Update Contact Number?
            </button>
            <Modal
                show={show}
                onHide={handleClose}
                backdrop={true}
                keyboard={false}
                className="update-modal"
            >
                <Modal.Body>
                    <h1>Change Contact Number?</h1>
                    <p>
                        For your security, you must re-enter your password to
                        continue.
                    </p>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Form.Group as={Row} className="mb-3" controlId="">
                            <Col md="12" className="d-flex align-items-center">
                                <Form.Label column md="6">
                                    Password<span class="astrik">*</span>
                                </Form.Label>
                                <Col md="6" className="d-flex justify-content-end">
                                   
                                </Col>
                            </Col>

                            <Col md="12">
                                <Form.Control
                                    type="password"
                                    placeholder="Password" {...register("password", { required: true })}
                                />
                                {errors.password && (
                                    <span className="error">This field is required</span>
                                )}
                            </Col>
                        </Form.Group>

                        <Button type='submit'
                            className="w-100"
                            size="lg"
                            disabled={isDisabled}
                        >
                            Continue
                        </Button>
                    </form>
                </Modal.Body>
            </Modal>

            {/* Contact Modal */}
            <ChangeContact
                show={showContactModal}
                handleClose={hideContactModal}
            />
        </div>
    );
};

export default PhoneUpdate;
