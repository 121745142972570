import React, { useState, useEffect } from "react";
import { Form, Button, InputGroup } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import Table from "react-bootstrap/Table";
import Pagination from "react-bootstrap/Pagination";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Dropdown from "react-bootstrap/Dropdown";
import { BiDotsVerticalRounded, BiCategory } from "react-icons/bi";
import { BsSearch } from "react-icons/bs";
import { useDebounce } from "../../common/Functions/useDebounce";
import { FaPlus } from "react-icons/fa";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Layout from "../../common/layout";
import { BsBookmark } from "react-icons/bs";
import { useDispatch } from "react-redux";
import { deleteArticle } from "../../redux/slices/articleSlice";
import {
  getArticleList,
  getPublishArticleList,
  getDraftArticleList,
  updateArticleCategory,
} from "../../redux/slices/articleSlice";
import "../property/property.scss";
import "./article.scss";
import { toast } from "react-toastify";
import Loader from "../../common/Loader/loader";
import { getDashboardPropertyData } from "../../redux/slices/propertySlice";

const ArticleList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showList, setShowList] = useState(false);
  const [tab, setTab] = useState("all");
  const [showUnlist, setShowUnlist] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [listType, setListType] = useState("List");
  const [articleData, setArticleData] = useState([]);
  const [isDisabled, setIsDisabled] = useState(false);
  const [filter, setFilter] = useState("-created_date");
  const [id, setId] = useState(0);
  const [page, setpage] = useState(1);
  const [totalrecords, settotalrecords] = useState(0);
  const [isActive, setIsActive] = useState();
  const [dashboardArticleData, setDashboardArticleyData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const debouncedSearchTerm = useDebounce(searchTerm, 500);
  const showListPopup = (e) => {
    setShowList(listType === "List" ? true : false);
    setShowUnlist(listType === "List" ? false : true);
    setListType(listType === "List" ? "UnList" : "List");
  };

  const pageNums = [];

  for (let i = 1; i <= Math.ceil(totalrecords / 10); i++) {
    pageNums.push(i);
  }

  const getArticlelist = async () => {
    setIsDisabled(true);
    var response = [];
    if (tab === "all") {
      response = await dispatch(getArticleList({ page, filter, searchTerm }));
    } else if (tab === "published") {
      response = await dispatch(
        getPublishArticleList({ page, filter, searchTerm })
      );
    } else {
      response = await dispatch(
        getDraftArticleList({ page, filter, searchTerm })
      );
    }
    if (response.payload.status === 200) {
      setArticleData(response.payload.articleData);
      settotalrecords(response.payload.count);
    }
    setIsDisabled(false);
  };

  const removeArticle = async () => {
    try {
      setIsDisabled(true);
      if (id !== 0) {
        const response = await dispatch(deleteArticle(id));
        if (response.payload.status === 204) {
          toast.success("Remove Article successfully");
        }
      } else {
        toast.error("Article Id are required");
      }
    } catch (error) {
      toast.error("something went wrong");
    } finally {
      setIsDisabled(false);
      getArticlelist();
    }
  };

  const getDashboardArticleDataList = async () => {
    const response = await dispatch(getDashboardPropertyData());
    if (response.payload.status === 200) {
      setDashboardArticleyData(response.payload.dashboardPropertyData);
    }
  };

  useEffect(() => {
    getArticlelist();
    getDashboardArticleDataList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab, page, filter, debouncedSearchTerm]);

  function getFormattedDateTime(formatDate) {
    var date = new Date(formatDate);
    var day = date.getDate().toString().padStart(2, "0");
    var month = (date.getMonth() + 1).toString().padStart(2, "0");
    var year = date.getFullYear().toString();
    var hours = date.getHours();
    var minutes = date.getMinutes().toString().padStart(2, "0");
    var ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12;
    var strTime = hours + ":" + minutes + " " + ampm;
    return day + "/" + month + "/" + year + ", " + strTime;
  }

  function getDateDifference(dateString1, dateString2) {
    const date1 = new Date(dateString1);
    const date2 = new Date(dateString2);

    const timeDiff = Math.abs(date2.getTime() - date1.getTime());

    // Calculate the number of days, hours, minutes, and seconds
    const diffDays = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
    const diffHours = Math.floor(
      (timeDiff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const diffMinutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));
    // const diffSeconds = Math.floor((timeDiff % (1000 * 60)) / 1000);

    return `${diffDays} days, ${diffHours} hours, ${diffMinutes} minutes`;
  }

  const updateList = async () => {
    try {
      setIsDisabled(true);
      if (id !== 0) {
        const articleDetail = { is_active: !isActive };
        const response = await dispatch(
          updateArticleCategory({ articleDetail, id })
        );
        if (response.payload.status === 200) {
          toast.success("List Update successfully");
        }
      } else {
        toast.error("Article Id are required");
      }
    } catch (error) {
      toast.error("something went wrong");
    } finally {
      setIsDisabled(false);
      getArticlelist();
    }
  };

  return (
    <>
      <Layout>
        <div className="content-body">
          <div className="content-body-inner">
            <div className="page-header">
              <h1>Articles List</h1>
              <Breadcrumb>
                <Link to="" className="link-btn">
                  Articles
                </Link>
                <span className="mx-1">/</span>
                <span>ArticlesList</span>
              </Breadcrumb>
            </div>
            <div className="page-content proprty-list-wrap article-list-wrap">
              <div className="analytics">
                <div className="left-wrap">
                  <div className="total-wrap">
                    <span className="icon">
                      <BsBookmark />
                    </span>
                    <div className="data">
                      <p className="m-0">
                        {dashboardArticleData?.data?.total_article}
                      </p>
                      <small>Total Articles</small>
                    </div>
                  </div>
                  <div className="total-wrap">
                    <span className="icon">
                      <BiCategory />
                    </span>
                    <div className="data">
                      <p className="m-0">
                        {dashboardArticleData?.data?.total_category}
                      </p>
                      <small>Total Categories</small>
                    </div>
                  </div>
                </div>
              </div>
              <div className="filter-area">
                <div className="table-search-bar">
                  <Form.Group
                    className="search-field-wrap"
                    controlId="exampleForm.ControlInput1"
                  >
                    <InputGroup className="searchbox-wrap">
                      <Form.Control
                        placeholder="Search Article Title here"
                        aria-label="Search Article Title here"
                        aria-describedby="basic-addon2"
                        value={searchTerm}
                        onChange={(e) => {
                          setSearchTerm(e?.target?.value);
                          setpage(1);
                        }}
                      />
                      <Button variant="outline-secondary" id="button-addon2">
                        <BsSearch />
                      </Button>
                    </InputGroup>
                  </Form.Group>
                </div>
                <div className="table-filter d-flex">
                  <Dropdown>
                    <Dropdown.Toggle id="dropdown-basic">
                      {filter === "-created_date" ? "Newest" : "Oldest"}
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item
                        href="#"
                        onClick={() => {
                          setFilter(
                            filter === "-created_date"
                              ? "created_date"
                              : "-created_date"
                          );
                        }}
                      >
                        {filter === "created_date" ? "Newest" : "Oldest"}
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                  <Button
                    variant="primary"
                    onClick={() => {
                      navigate("/new-article");
                    }}
                  >
                    <FaPlus /> New Article
                  </Button>
                </div>
              </div>
              <div className="tab-table-wrap">
                <Tabs
                  defaultActiveKey="all"
                  id="uncontrolled-tab-example"
                  className=""
                  onSelect={(key) => setTab(key)}
                >
                  <Tab
                    eventKey="all"
                    title="All Article"
                    onClick={() => {
                      setTab("all");
                    }}
                  >
                    <div className="listing-table blog-listing-table">
                      <Table responsive="xl">
                        <thead>
                          <tr>
                            <th className="id-th"> Id</th>
                            <th className="title-th"> Article Title</th>
                            <th className="category-th"> Categories</th>
                            <th className="author-th"> Author</th>
                            <th className="date-th"> Published Date</th>
                            <th className="esttime-th">Est. Time </th>
                            <th className="status-th">Status</th>
                            <th className="action-th"> </th>
                            <th className="empty-th"> </th>
                          </tr>
                        </thead>
                        <tbody>
                          {isDisabled ? (
                            <tr>
                              <td className="text-center" colspan="8">
                                <Loader />
                              </td>
                            </tr>
                          ) : articleData?.length > 0 ? (
                            articleData.map((item) => {
                              return (
                                <tr>
                                  <td>{item?.id}</td>
                                  <td>
                                    <Link
                                      to={{ pathname: `/article/${item?.id}` }}
                                      className="link-btn"
                                    >
                                      {item?.title}
                                    </Link>
                                  </td>
                                  <td>{item?.category?.article_category}</td>
                                  <td>
                                    <p className="m-0">
                                      {item?.author?.first_name
                                        ? `By ${item?.author?.first_name} ${item?.author?.last_name}`
                                        : "-"}
                                    </p>
                                  </td>
                                  <td>
                                    <p className="m-0">
                                      <span>
                                        {item?.publish_date === null
                                          ? "-"
                                          : getFormattedDateTime(
                                              item?.publish_date
                                            )}
                                      </span>
                                    </p>
                                  </td>
                                  <td>
                                    {item?.publish_date === null
                                      ? "-"
                                      : getDateDifference(
                                          item?.publish_date,
                                          new Date()
                                        )}
                                  </td>
                                  <td className="status">
                                    <p className="m-0 published">
                                      {item?.is_published
                                        ? "Published"
                                        : "Drafted"}
                                    </p>
                                  </td>
                                  <td className="dd-action">
                                    <Dropdown>
                                      <Dropdown.Toggle id="dropdown-basic">
                                        <BiDotsVerticalRounded />
                                      </Dropdown.Toggle>

                                      <Dropdown.Menu>
                                        <Dropdown.Item
                                          onClick={() => {
                                            navigate("/new-article", {
                                              state: { data: item },
                                            });
                                          }}
                                        >
                                          Edit
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          onClick={(e) => {
                                            setId(item?.category?.id);
                                            setIsActive(
                                              item?.category?.is_active
                                            );
                                            showListPopup(e);
                                          }}
                                        >
                                          {item?.category?.is_active
                                            ? "List"
                                            : "UnList"}
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          onClick={() => {
                                            setId(item?.id);
                                            setShowDelete(true);
                                          }}
                                        >
                                          Remove
                                        </Dropdown.Item>
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </td>
                                </tr>
                              );
                            })
                          ) : (
                            <tr>
                              <td className="text-center" colspan="8">
                                No Article Found
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </Table>
                    </div>
                  </Tab>
                  <Tab eventKey="published" title="Published">
                    <div className="listing-table blog-listing-table">
                      <Table responsive="sm">
                        <thead>
                          <tr>
                            <th className="id-th"> Id</th>
                            <th className="title-th"> Article Title</th>
                            <th className="category-th"> Categories</th>
                            <th className="author-th"> Author</th>
                            <th className="date-th"> Published Date</th>
                            <th className="esttime-th">Est. Time </th>
                            <th className="status-th">Status</th>
                            <th className="action-th"> </th>
                            <th className="empty-th"> </th>
                          </tr>
                        </thead>
                        <tbody>
                          {isDisabled ? (
                            <tr>
                              <td className="text-center" colspan="8">
                                <Loader />
                              </td>
                            </tr>
                          ) : articleData?.length > 0 ? (
                            articleData.map((item) => {
                              return (
                                <tr>
                                  <td>{item?.id}</td>
                                  <td>
                                    <Link
                                      to={{ pathname: `/article/${item?.id}` }}
                                      className="link-btn"
                                    >
                                      {item?.title}
                                    </Link>
                                  </td>
                                  <td>{item?.category?.article_category}</td>
                                  <td>
                                    <p className="m-0">
                                      {item?.author?.first_name
                                        ? `By ${item?.author?.first_name} ${item?.author?.last_name}`
                                        : "-"}
                                    </p>
                                  </td>
                                  <td>
                                    <p className="m-0">
                                      <span>
                                        {item?.publish_date === null
                                          ? "-"
                                          : getFormattedDateTime(
                                              item?.publish_date
                                            )}
                                      </span>
                                    </p>
                                  </td>
                                  <td>
                                    {item?.publish_date === null
                                      ? "-"
                                      : getDateDifference(
                                          item?.publish_date,
                                          new Date()
                                        )}
                                  </td>
                                  <td className="status">
                                    <p className="m-0 published">
                                      {item?.is_published
                                        ? "Published"
                                        : "Drafted"}
                                    </p>
                                  </td>
                                  <td className="dd-action">
                                    <Dropdown>
                                      <Dropdown.Toggle id="dropdown-basic">
                                        <BiDotsVerticalRounded />
                                      </Dropdown.Toggle>

                                      <Dropdown.Menu>
                                        <Dropdown.Item
                                          onClick={() => {
                                            navigate("/new-article", {
                                              state: { data: item },
                                            });
                                          }}
                                        >
                                          Edit
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          onClick={(e) => {
                                            setId(item?.category?.id);
                                            setIsActive(
                                              item?.category?.is_active
                                            );
                                            showListPopup(e);
                                          }}
                                        >
                                          {item?.category?.is_active
                                            ? "List"
                                            : "UnList"}
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          onClick={() => {
                                            setId(item?.id);
                                            setShowDelete(true);
                                          }}
                                        >
                                          Remove
                                        </Dropdown.Item>
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </td>
                                </tr>
                              );
                            })
                          ) : (
                            <tr>
                              <td className="text-center" colspan="8">
                                No Article Found
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </Table>
                    </div>
                  </Tab>
                  <Tab eventKey="drafted" title="Drafted">
                    <div className="listing-table blog-listing-table">
                      <Table responsive="sm">
                        <thead>
                          <tr>
                            <th className="id-th"> Id</th>
                            <th className="title-th"> Article Title</th>
                            <th className="category-th"> Categories</th>
                            <th className="author-th"> Author</th>
                            <th className="date-th"> Published Date</th>
                            <th className="esttime-th">Est. Time </th>
                            <th className="status-th">Status</th>
                            <th className="action-th"> </th>
                            <th className="empty-th"> </th>
                          </tr>
                        </thead>
                        <tbody>
                          {isDisabled ? (
                            <tr>
                              <td className="text-center" colspan="8">
                                <Loader />
                              </td>
                            </tr>
                          ) : articleData?.length > 0 ? (
                            articleData.map((item) => {
                              return (
                                <tr>
                                  <td>{item?.id}</td>
                                  <td>
                                    <Link
                                      to={{ pathname: `/article/${item?.id}` }}
                                      className="link-btn"
                                    >
                                      {item?.title}
                                    </Link>
                                  </td>
                                  <td>{item?.category?.article_category}</td>
                                  <td>
                                    <p className="m-0">
                                      {item?.author?.first_name
                                        ? `By ${item?.author?.first_name} ${item?.author?.last_name}`
                                        : "-"}
                                    </p>
                                  </td>
                                  <td>
                                    <p className="m-0">
                                      <span>
                                        {item?.publish_date === null
                                          ? "-"
                                          : getFormattedDateTime(
                                              item?.publish_date
                                            )}
                                      </span>
                                    </p>
                                  </td>
                                  <td>
                                    {item?.publish_date === null
                                      ? "-"
                                      : getDateDifference(
                                          item?.publish_date,
                                          new Date()
                                        )}
                                  </td>
                                  <td className="status">
                                    <p className="m-0 published">
                                      {item?.is_published
                                        ? "Published"
                                        : "Drafted"}
                                    </p>
                                  </td>
                                  <td className="dd-action">
                                    <Dropdown>
                                      <Dropdown.Toggle id="dropdown-basic">
                                        <BiDotsVerticalRounded />
                                      </Dropdown.Toggle>

                                      <Dropdown.Menu>
                                        <Dropdown.Item
                                          onClick={() => {
                                            navigate("/new-article", {
                                              state: { data: item },
                                            });
                                          }}
                                        >
                                          Edit
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          onClick={(e) => {
                                            setId(item?.category?.id);
                                            setIsActive(
                                              item?.category?.is_active
                                            );
                                            showListPopup(e);
                                          }}
                                        >
                                          {item?.category?.is_active
                                            ? "List"
                                            : "UnList"}
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          onClick={() => {
                                            setId(item?.id);
                                            setShowDelete(true);
                                          }}
                                        >
                                          Remove
                                        </Dropdown.Item>
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </td>
                                </tr>
                              );
                            })
                          ) : (
                            <tr>
                              <td className="text-center" colspan="8">
                                No Article Found
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </Table>
                    </div>
                  </Tab>
                </Tabs>
              </div>
              <div className="listing-table-pagination">
                <div className="pagination-info">
                  <p>
                    Showing <span>{articleData?.length}</span> from{" "}
                    <span>{totalrecords}</span> data{" "}
                  </p>
                </div>

                <Pagination>
                  {page !== 1 && (
                    <Pagination.Prev
                      className="prev-btn"
                      onClick={() => setpage(page - 1)}
                    />
                  )}

                  {pageNums.map((pageNum) => (
                    <Pagination.Item
                      key={pageNum}
                      active={page === pageNum}
                      onClick={() => setpage(pageNum)}
                    >
                      {pageNum}
                    </Pagination.Item>
                  ))}
                  {page !== Math.ceil(totalrecords / 10) &&
                    totalrecords !== 0 && (
                      <Pagination.Next
                        className="next-btn"
                        onClick={() => setpage(page + 1)}
                      />
                    )}
                </Pagination>
              </div>
            </div>
          </div>
        </div>
        <Modal show={showList} onHide={() => setShowList(false)}>
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <h3>Sure you want to again List Article? </h3>
            <p>
              A Article will be listed to the list so current users can see it.
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => {
                setId(0);
                setShowList(false);
              }}
            >
              No,Cancel
            </Button>
            <Button
              variant="primary"
              onClick={() => {
                updateList();
                setShowList(false);
              }}
            >
              Yes,Confirm
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal show={showUnlist} onHide={() => setShowUnlist(false)}>
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <h3>Sure you want to Unlist Article? </h3>
            <p>
              A Article will be unlisted from the list so current users cannot
              see it.
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => {
                setId(0);
                setShowUnlist(false);
              }}
            >
              No,Cancel
            </Button>
            <Button
              variant="primary"
              onClick={() => {
                updateList();
                setShowUnlist(false);
              }}
            >
              Yes,Confirm
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal show={showDelete} onHide={() => setShowDelete(false)}>
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <h3>Sure you want to Remove Article? </h3>
            <p>
              {" "}
              This action will permanently remove this Article from the list
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => {
                setId(0);
                setShowDelete(false);
              }}
            >
              No,Cancel
            </Button>
            <Button
              className="btn-alert"
              onClick={() => {
                removeArticle();
                setShowDelete(false);
              }}
            >
              Yes,Confirm
            </Button>
          </Modal.Footer>
        </Modal>
      </Layout>
    </>
  );
};

export default ArticleList;
