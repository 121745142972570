import React ,{ useState} from 'react';
import { Form, Button, Row, Col } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { updateEmail } from "../../redux/slices/authSlice";

const ChangeEmail = ({ show, handleClose }) => {
    const dispatch = useDispatch();
    const [isDisabled, setIsDisabled] = useState(false);

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
      } = useForm();
      const onSubmit = async (data) => {
        setIsDisabled(true)
        const formData = new FormData();
        formData.append("email", data.email);
        const response = await dispatch(updateEmail(formData));
        if (response.payload.status === 205) {
            reset();
            handleClose()
            localStorage.setItem(
                'user',
                JSON.stringify(response.payload.user)
            );
            toast.success("Email updated successfully");
        }else{
            toast.success("Try Again!");
        }
        setIsDisabled(false)
     };

    return (
        <Modal
            show={show}
            onHide={handleClose}
            backdrop={true}
            keyboard={false}
            className="update-modal"
        >
            <Modal.Body>
                <h1>Change Email Address?</h1>
                <p>Please add the new email address you wish to add.</p>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Form.Group as={Row} className="mb-3" controlId="">
                        <Col md="12" className="d-flex align-items-center">
                            <Form.Label column md="6">
                                New Email Address<span class="astrik">*</span>
                            </Form.Label>
                        </Col>

                        <Col md="12">
                            <Form.Control
                                type="Email Address"
                                placeholder="Enter New Email Address"
                                {...register("email", { required: true, pattern: /^\S+@\S+$/i })}
                            />
                            {errors && errors.email && errors.email.type === 'required' && <span className="error">email is required field</span>}
                            {errors && errors.email && errors.email.type === 'pattern' && <span className="error">invalid email formate</span>}
                        </Col>
                    </Form.Group>
                    <Button className="w-100" size="lg" type='submit' disabled={isDisabled}>
                        Update Email
                    </Button>
                </form>
            </Modal.Body>
        </Modal>
    );
};

export default ChangeEmail;
