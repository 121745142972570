import axios from "axios";
import { API_BASE_URL } from "../../../services/articleServices/constants";

const token = localStorage.getItem('token') || null;

export default class UploadAdapter {
	constructor(loader) {
		this.url = `${API_BASE_URL}image/`;
		this.loader = loader;
		this.loader.file.then(pic => {
			console.info("pic++ ",pic)
			return (this.file = pic)
		}).then(() => {
			setTimeout(() => {
				this.upload()
			}, 1000);
		})
	}

	// Starts the upload process.
	upload() {

		const headers = { Authorization: 'Bearer ' + token };

		const fd = new FormData();
		fd.append("image", this.file); // your image

		return new Promise((resolve, reject) => {
			axios
				.post(this.url, fd, {
					headers : headers
				})
				.then(response => {
					let json = {
						default: response.data.data.image
					}
					resolve(json);
				})
				.catch(error => {
					reject("Server Error");
					console.log("Server Error : ", error);
				});
		});
	}
}