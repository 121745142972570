import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import SignIn from './pages/auth/SignIn';
import Sidebar from './common/Sidebar';
import Dashboard from './pages/dashboard/Dashboard';
import PropertyList from './pages/property/PropertyList';
import PropertyDetail from './pages/property/PropertyDetail';
import InquiryList from './pages/inquiry/InquiryList';
import InquiryDetail from './pages/inquiry/InquiryDetail';
import CustomerList from './pages/customer/CustomerList';
import ArticleList from './pages/articles/ArticleList';
import AddArticle from './pages/articles/AddArticle';
import ArticleDetail from './pages/articles/ArticleDetail';
import SettingList from './pages/setting/SettingList';
import AddEmailTemplate from './pages/setting/AddEmailtemplate.jsx';
import AmenitiesList from './pages/setting/AmenitiesList.jsx';
import ProfileSetting from './pages/auth/ProfileSetting';
import Navbar from './common/Navbar';
import BrandsList from './pages/brands/BrandsList';
import AdminList from './pages/adminUsers/adminUserList';
import AddBrands from './pages/brands/AddBrands';
import AddNewAdmin from './pages/adminUsers/AddAdmin';
import AuthRoute from './common/auth/AuthRoute';

const AppRoutes = () => {
	return (
		<Routes>
			<Route path="/signin" element={<SignIn />} />

			<Route path="/profile-setting" element={<AuthRoute><ProfileSetting /></AuthRoute>} />
			<Route path="/sidebar" element={<AuthRoute><Sidebar /></AuthRoute>} />
			<Route path="/navbar" element={<AuthRoute><Navbar /></AuthRoute>} />
			<Route path="/dashboard" element={<AuthRoute><Dashboard /></AuthRoute>} />
			<Route path="/properties" element={<AuthRoute><PropertyList /></AuthRoute>} />
			<Route path="/property/:id" element={<AuthRoute><PropertyDetail /></AuthRoute>} />
			<Route path="/inquiries" element={<AuthRoute><InquiryList /></AuthRoute>} />
			<Route path="/inquiry/:id" element={<AuthRoute><InquiryDetail /></AuthRoute>} />
			<Route path="/customers" element={<AuthRoute><CustomerList /></AuthRoute>} />
			<Route path="/articles" element={<AuthRoute><ArticleList /></AuthRoute>} />
			<Route path="/brands" element={<AuthRoute><BrandsList /></AuthRoute>} />
			<Route path="/adminusers" element={<AuthRoute><AdminList /></AuthRoute>} />
			<Route path="/new-article" element={<AuthRoute><AddArticle /></AuthRoute>} />
			<Route path="/article/:articleId" element={<AuthRoute><ArticleDetail /></AuthRoute>} />
			<Route path="/setting">
				<Route path="email-setting" element={<AuthRoute><SettingList /></AuthRoute>} index={true} />
				<Route path="amenities-setting" element={<AuthRoute><AmenitiesList /></AuthRoute>} />
			</Route>
			<Route path="/newemailtemplate" element={<AuthRoute><AddEmailTemplate /></AuthRoute>} />
			<Route path="/add-brands" element={<AuthRoute><AddBrands /></AuthRoute>} />
			<Route path="/new-admin" element={<AuthRoute><AddNewAdmin /></AuthRoute>} />

			<Route path="*" element={<Navigate to={'dashboard'} />} />
		</Routes>
	);
};
//

export default AppRoutes;
