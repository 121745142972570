import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import Avatar from 'react-avatar-edit';
import { AiFillCamera } from 'react-icons/ai';

const ProfileUpload = ({ profileImage = false, setMethod, image = null }) => {
    // eslint-disable-next-line
    const [dialog, setDialog] = useState(false);
    const [imgCrop, setImgCrop] = useState(image);
    const [show, setShow] = useState(false);
    const [imgFile, setImgFile] = useState(null);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const onCrop = (view) => {
        setImgCrop(view);
        var byteString = window.atob(view.split(',')[1]);
        var ab = new ArrayBuffer(byteString.length);
        var ia = new Uint8Array(ab);
        for (var i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        var blob = new Blob([ia], {
            type: 'image/jpeg'
        });
        var file = new File([blob], "image.jpg");
        setImgFile(file);
    };

    const onClose = () => {
        setImgCrop(null);
    };

    const onFileLoad = (file) => {
        setImgFile(file);
    };
    const saveImage = () => {
        let updateImage = profileImage ? { image:  imgFile  } : { seller_image:  imgFile  };
        setMethod((prevValues) => ({
            ...prevValues,
            ...updateImage
        }));
        setDialog(false);
    };

    return (
        <>
            <div className="profile-pic">
                <img
                    src={imgCrop}
                    className="seller-img"
                    alt=""
                    onClick={() => {
                        setDialog(true);
                        handleShow();
                    }}
                />
                <span>
                    <AiFillCamera />
                </span>
            </div>
            <Modal
                show={show}
                onHide={() => {
                    setDialog(false);
                    handleClose();
                }}
                className="img-change-modal"
            >
                <h1 class="text-center">{`Add ${profileImage ? "Profile" : "Seller"} Photo`}</h1>
                <Modal.Body>
                    <Avatar
                        width={250}
                        height={250}
                        border={50}
                        color={[255, 255, 255, 0.6]}
                        scale={1.2}
                        rotate={0}
                        onClose={onClose}
                        onCrop={onCrop}
                        onFileLoad={onFileLoad}
                        className="choose-img"
                    />
                </Modal.Body>
                <Button
                    onClick={() => {
                        saveImage();
                        handleClose();
                    }}
                    className="btn btn-primary"
                >Upload</Button>
            </Modal>
        </>
    );
};
export default ProfileUpload;
