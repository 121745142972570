import React, { useState } from "react";
import { Form, Button, Row, Col } from "react-bootstrap";
import { useDispatch } from "react-redux";
import "../setting/setting.scss";
import Layout from "../../common/layout";
import { toast } from "react-toastify";
import ProfileUpload from "../auth/profileupload";
import { addAdminUser } from "../../redux/slices/authSlice";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";

const AddNewAdmin = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const schema = Yup.object().shape({
    first_name: Yup.string().required("First Name is required"),
    last_name: Yup.string().required("Last Name is required"),
    password: Yup.string()
      .required("Password is required")
      .matches(
        /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*\W)(?!.* ).{6,}$/,
        "Password must contain one digit from 1 to 9, one lowercase letter, one uppercase letter, one special character, no space, and it must be 6 characters long or more."
      ),
    email: Yup.string()
      .email("Invalid Email Address")
      .required("Email Address is required"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const [isDisabled, setIsDisabled] = useState(false);
  const [userImage, setUserImage] = useState(null);

  const adminUserData = new FormData();

  const submitHandler = async (data) => {
    for (const key in data) {
      adminUserData.append(key, data[key]);
    }

    userImage && adminUserData.append("image", userImage?.image);

    adminUserData.append("role", "ADMIN");

    console.log(adminUserData.get("image"));

    try {
      setIsDisabled(true);
      const response = await dispatch(addAdminUser(adminUserData));

      if (response.payload.status === 201) {
        toast.success(
          "User registered successfully please verify email & then login"
        );
        navigate("/adminusers");
      }
    } catch (error) {
      toast.error(error);
      console.log(error);
    } finally {
      setIsDisabled(false);
      // reset();
    }
  };

  return (
    <>
      <Layout>
        <div className="content-body">
          <div className="content-body-inner">
            <div className="page-header">
              <h1>Add New Admin User</h1>
            </div>
            <div className="page-content setting-page-content">
              <Form
                className="user-profile-wrap"
                onSubmit={handleSubmit(submitHandler)}
              >
                <Row className="">
                  <Col sm="12" md="4" lg="4">
                    <Form.Label column md="12">
                      Profile Photo
                    </Form.Label>
                    <div className="seller-detail-wrap">
                      <div className="seller-profile-image">
                        <ProfileUpload
                          profileImage={true}
                          setMethod={setUserImage}
                          image={userImage}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col sm="12" md="8" lg="8">
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formSignUpFirstName"
                    >
                      <Col md="6">
                        <Form.Label column md="12">
                          First Name <span class="astrik">*</span>
                        </Form.Label>

                        <Form.Control
                          name="first_name"
                          type="text"
                          placeholder="Enter First Name"
                          {...register("first_name")}
                        />
                        {errors.first_name && (
                          <span className="error">
                            {errors.first_name.message}
                          </span>
                        )}
                      </Col>

                      <Col md="6">
                        <Form.Label column md="12">
                          Last Name<span class="astrik">*</span>
                        </Form.Label>

                        <Form.Control
                          name="last_name"
                          type="text"
                          placeholder="Enter Last Name"
                          {...register("last_name")}
                        />
                        {errors.last_name && (
                          <span className="error">
                            {errors.last_name.message}
                          </span>
                        )}
                      </Col>

                      <Col md="6">
                        <Form.Label column md="12">
                          Password<span class="astrik">*</span>
                        </Form.Label>

                        <Form.Control
                          name="password"
                          type="password"
                          placeholder="Enter Password"
                          {...register("password")}
                        />
                        {errors.password && (
                          <span className="error">
                            {errors.password.message}
                          </span>
                        )}
                      </Col>
                      <Col md="6"></Col>
                      <Col md="6">
                        <Form.Label column md="12">
                          Email Address<span class="astrik">*</span>
                        </Form.Label>

                        <Form.Control
                          name="email"
                          type="email"
                          placeholder="Enter Agent Email Address"
                          {...register("email")}
                        />
                        {errors.email && (
                          <span className="error">{errors.email.message}</span>
                        )}
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <div className="bottom-control d-flex justify-content-end">
                  <Button
                    sx={{ padding: "10px 15px" }}
                    type="submit"
                    disabled={isDisabled}
                  >
                    Save
                  </Button>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default AddNewAdmin;
