import React, { useEffect, useMemo, useState } from 'react'
import DataTable from 'react-data-table-component'
import { LeftArrow } from "../../../assets/svg"
import { useDispatch } from 'react-redux'
import { getProjectionData, updatProjectionData } from '../../../redux/slices/propertySlice'

const CustomDataTable = ({ propertyId }) => {
    //==> Statics <==
    const MONTHS = {
        jan: 'January',
        feb: 'February',
        mar: 'March',
        apr: 'April',
        may: 'May',
        jun: 'June',
        jul: 'July',
        aug: 'August',
        sep: 'September',
        oct: 'October',
        nov: 'November',
        dec: 'December',
    }

    const subColumns = [
        {
            name: 'Title',
            selector: row => row.title,
        },
        {
            name: 'ADR',
            cell: (row) => {
                return (
                    <>
                        <div className='ctm-table-input-container'>
                            <input type="text" className='ctm-table-input' id={`projection-table-adr-${row.dataId}`} onChange={(e) => handleChange(e, 'adr', row.dataId, `projection-table-adr-${row.dataId}`)}
                                onBlur={(e) => handleUpdate()} value={row.adr} />
                        </div>
                    </>
                )
            },
        },
        {
            name: 'Night Booked',
            cell: (row) => {
                return (
                    <>
                        <div className='ctm-table-input-container'>
                            <input type="text" className='ctm-table-input' id={`projection-table-nightBooked-${row.dataId}`} onChange={(e) => handleChange(e, 'nightBooked', row.dataId, `projection-table-nightBooked-${row.dataId}`)}
                                onBlur={(e) => handleUpdate()} value={row.nightBooked} />
                        </div>
                    </>
                )
            },
        },
        {
            name: 'Owner Night Used',
            cell: (row) => {
                return (
                    <>
                        <div className='ctm-table-input-container'>
                            <input type="text" className='ctm-table-input' id={`projection-table-nightUsed-${row.dataId}`} onChange={(e) => handleChange(e, 'nightUsed', row.dataId, `projection-table-nightUsed-${row.dataId}`)}
                                onBlur={(e) => handleUpdate()} value={row.nightUsed} />
                        </div>
                    </>
                )
            },
        }
    ]


    //==> States <== 
    const [currentRow, setCurrentRow] = useState([]);
    const [data, setData] = useState([]);
    const [tableData, setTableData] = useState([])
    const dispatch = useDispatch()
    const [projectionData, setProjectionData] = useState({
        jan: {
            adr: {
                midWeek: 20,
                weekEnd: 0
            },
            nightBooked: {
                midWeek: 0,
                weekEnd: 0
            },
            ownerNightUsed: {
                midWeek: 0,
                weekEnd: 0
            }
        },
        feb: {
            adr: {
                midWeek: 0,
                weekEnd: 0
            },
            nightBooked: {
                midWeek: 0,
                weekEnd: 0
            },
            ownerNightUsed: {
                midWeek: 0,
                weekEnd: 0
            }
        },
        mar: {
            adr: {
                midWeek: 0,
                weekEnd: 0
            },
            nightBooked: {
                midWeek: 0,
                weekEnd: 0
            },
            ownerNightUsed: {
                midWeek: 0,
                weekEnd: 0
            }
        },
        apr: {
            adr: {
                midWeek: 0,
                weekEnd: 0
            },
            nightBooked: {
                midWeek: 0,
                weekEnd: 0
            },
            ownerNightUsed: {
                midWeek: 0,
                weekEnd: 0
            }
        },
        may: {
            adr: {
                midWeek: 0,
                weekEnd: 0
            },
            nightBooked: {
                midWeek: 0,
                weekEnd: 0
            },
            ownerNightUsed: {
                midWeek: 0,
                weekEnd: 0
            }
        },
        jun: {
            adr: {
                midWeek: 0,
                weekEnd: 0
            },
            nightBooked: {
                midWeek: 0,
                weekEnd: 0
            },
            ownerNightUsed: {
                midWeek: 0,
                weekEnd: 0
            }
        },
        jul: {
            adr: {
                midWeek: 0,
                weekEnd: 0
            },
            nightBooked: {
                midWeek: 0,
                weekEnd: 0
            },
            ownerNightUsed: {
                midWeek: 0,
                weekEnd: 0
            }
        },
        aug: {
            adr: {
                midWeek: 0,
                weekEnd: 0
            },
            nightBooked: {
                midWeek: 0,
                weekEnd: 0
            },
            ownerNightUsed: {
                midWeek: 0,
                weekEnd: 0
            }
        },
        sep: {
            adr: {
                midWeek: 0,
                weekEnd: 0
            },
            nightBooked: {
                midWeek: 0,
                weekEnd: 0
            },
            ownerNightUsed: {
                midWeek: 0,
                weekEnd: 0
            }
        },
        oct: {
            adr: {
                midWeek: 0,
                weekEnd: 0
            },
            nightBooked: {
                midWeek: 0,
                weekEnd: 0
            },
            ownerNightUsed: {
                midWeek: 0,
                weekEnd: 0
            }
        },
        nov: {
            adr: {
                midWeek: 0,
                weekEnd: 0
            },
            nightBooked: {
                midWeek: 0,
                weekEnd: 0
            },
            ownerNightUsed: {
                midWeek: 0,
                weekEnd: 0
            }
        },
        dec: {
            adr: {
                midWeek: 0,
                weekEnd: 0
            },
            nightBooked: {
                midWeek: 0,
                weekEnd: 0
            },
            ownerNightUsed: {
                midWeek: 0,
                weekEnd: 0
            }
        }
    }
    );


    //==> Memos <==
    const columns = useMemo(() => {
        return ([
            {
                name: 'Months',
                cell: (row) => {
                    return (<>
                        <div className="header-cell">
                            <span>{row.month}</span>
                            <span
                                className={`btn-arrow ${isIncluded(row) ? 'arrow-down' : ''}`}
                                onClick={() => toggleCurrentRow(row)}
                            ><LeftArrow /></span>
                        </div>
                    </>)
                }
            },
            {
                name: 'ADR',
                cell: (row) => {
                    return (<>
                        <div className='ctm-table-cell'>{row.adr}</div>
                    </>)
                }
            },
            {
                name: 'Night Booked',
                cell: (row) => {
                    return (<>
                        <div className='ctm-table-cell'>{row.nightBooked}</div>
                    </>)
                }
            },
            {
                name: 'Owner Night Used',
                cell: (row) => {
                    return (<>
                        <div className='ctm-table-cell'>{row.nightUsed}</div>
                    </>)
                }
            }
        ])
    }, [currentRow])


    //==> State 
    useEffect(() => {
        const convertData = () => {
            const newData = { ...projectionData };

            tableData.forEach((monthData) => {
                const monthName = monthData.month.toLowerCase().substring(0, 3);

                newData[monthName] = {
                    adr: {
                        midWeek: monthData.subData[0].adr,
                        weekEnd: monthData.subData[1].adr,
                    },
                    nightBooked: {
                        midWeek: monthData.subData[0].nightBooked,
                        weekEnd: monthData.subData[1].nightBooked,
                    },
                    ownerNightUsed: {
                        midWeek: monthData.subData[0].nightUsed,
                        weekEnd: monthData.subData[1].nightUsed,
                    },
                };
            });

            setProjectionData(newData);
        };
        convertData();
    }, [tableData]);



    //==> Functionalities <==
    const toggleCurrentRow = (row) => {
        setCurrentRow((old) => {
            const tmp = []
            let flag = true
            old.forEach((elemRow) => {
                if (elemRow.id === row.id) {
                    flag = false
                } else {
                    tmp.push(elemRow)
                }
            })
            if (flag) {
                tmp.push(row)
            }
            return tmp
        })
    }

    const isIncluded = ({ id }) => {
        let flag = false
        currentRow?.forEach((elem) => {
            if (elem.id === id) {
                flag = true
            }
        })
        return flag
    }

    const ExpandedComponent = ({ data }) => {
        const tableData = data.subData
        return (
            <>
                <DataTable
                    className="custom-sub-table-comp"
                    columns={subColumns}
                    data={tableData}
                    dense={true}
                    noTableHead={true}
                />
            </>
        )
    }

    const getProjections = async () => {
        const response = await dispatch(getProjectionData(propertyId));
        if (response?.payload?.status === 200) {
            setData(response?.payload?.projectionsData?.projection_data);
        }
    }

    const handleChange = (e, key, dataId, fieldId) => {

        if (e.target.value !== '' && isNaN(e.target.value)) {
            return
        }

        let input = document.getElementById(fieldId)
        let startPos = input.selectionStart

        const [id, type] = dataId.split('_')
        const tmpData = tableData?.map((elem) => {
            if (Number(elem.id) === Number(id)) {
                let tmpElem = { ...elem }
                if (type === 'M') {
                    tmpElem = {
                        ...tmpElem,
                        [key]: Number(e.target.value ?? 0) + tmpElem.subData[1][key],
                        subData: [
                            {
                                ...tmpElem.subData[0], [key]: Number(e.target.value ?? 0)
                            },
                            {
                                ...tmpElem.subData[1]
                            }
                        ]
                    }
                } else if (type === 'W') {
                    tmpElem = {
                        ...tmpElem,
                        [key]: Number(e.target.value ?? 0) + tmpElem.subData[0][key],
                        subData: [
                            {
                                ...tmpElem.subData[0]
                            },
                            {
                                ...tmpElem.subData[1], [key]: Number(e.target.value ?? 0)
                            }
                        ]
                    }
                }
                return tmpElem
            }
            else return elem
        })

        setTableData(tmpData)

        setTimeout(() => {
            let input = document.getElementById(fieldId)
            input.focus()
            input.setSelectionRange(startPos ?? 0, startPos ?? 0)
        }, 50);
    }
    const handleUpdate = () => {
        dispatch(updatProjectionData({ projectionData, propertyId }));
    }


    //==> Effects <==
    useEffect(() => {
        getProjections()
    }, [propertyId])

    useEffect(() => {
        const tmpData = Object.keys(data)?.map((key, index) => {
            return {
                id: index,
                month: MONTHS[key],
                adr: Number(data[key]?.adr?.midWeek ?? 0) + Number(data[key]?.adr?.weekEnd ?? 0),
                nightBooked: Number(data[key]?.nightBooked?.midWeek ?? 0) + Number(data[key]?.nightBooked?.weekEnd ?? 0),
                nightUsed: Number(data[key]?.ownerNightUsed?.midWeek ?? 0) + Number(data[key]?.ownerNightUsed?.weekEnd ?? 0),
                subData: [{
                    dataId: `${index}_M`,
                    title: 'Midweek',
                    adr: data[key]?.adr?.midWeek ?? 0,
                    nightBooked: data[key]?.nightBooked?.midWeek ?? 0,
                    nightUsed: data[key]?.ownerNightUsed?.midWeek ?? 0,
                },
                {
                    dataId: `${index}_W`,
                    title: 'Weekend',
                    adr: data[key]?.adr?.weekEnd ?? 0,
                    nightBooked: data[key]?.nightBooked?.weekEnd ?? 0,
                    nightUsed: data[key]?.ownerNightUsed?.weekEnd ?? 0,
                }]
            }
        })
        setTableData(tmpData)
    }, [data])


    return (
        <>
            <DataTable
                className="custom-table-comp"
                columns={columns}
                data={tableData}
                expandableRows
                expandableRowExpanded={(row) => isIncluded(row)}
                expandableRowsComponent={ExpandedComponent}
                // onRowExpandToggled={(bool, row) => { bool ? setCurrentRow(row) : setCurrentRow(null) }}
                // expandOnRowClicked={true}
                expandableRowsHideExpander
                direction='center'
            />
        </>
    )
}

export default CustomDataTable                              