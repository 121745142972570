import React from "react";
import { Form, Button, Row, Col } from "react-bootstrap";
import { AiOutlineEye } from "react-icons/ai";
import EmailTemplateEditor from "./EmailTemplateEditor";
const AddEmailtemplate = () => {
  return (
    <div className="main-wrap">
      <div className="page-header">
        <h1>Add New Email Template</h1>
      </div>
      <div className="page-content">
        <div className="add-email-template">
          <Row className="">
            <Form>
              <Col md="3" lg="3">
                <Form.Label column md="12">
                  Email Type
                </Form.Label>

                <Form.Select
                  aria-label="Default select example"
                  placeholder="Select Email Type"
                >
                  <option>Select Email Type</option>
                  <option value="1"> New User</option>
                  <option value="2">Change Email</option>
                  <option value="3">Change Phone Number</option>
                  <option value="2">New Property Added</option>
                </Form.Select>
              </Col>
              <Col md="9" lg="9">
                <Form.Group className="" controlId="">
                  <Form.Label>
                    Email Subject <span class="astrik">*</span>
                  </Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Enter Subject for Email Template"
                    name="email"
                  />
                </Form.Group>
              </Col>
              <Col md="9" lg="9">
                <Form.Group className="" controlId="">
                  <Form.Label>
                    Email Body <span class="astrik">*</span>
                  </Form.Label>
                </Form.Group>
                <div className="editor-wrapper">
                  <EmailTemplateEditor />
                </div>
              </Col>
              <Col md="9" lg="9">
                <div className="d-flex mt-3 btn-wrap">
                  <Button variant="secondary">
                    {" "}
                    <span>
                      <AiOutlineEye />
                    </span>{" "}
                    Preview
                  </Button>
                  <Button variant="primary" className="ml-2">
                    Save
                  </Button>
                  {/* <Button className="ml-2" disabled={isDisabled} onClick={isEditable ? updateBrand : addNewBrand} variant="primary">{isEditable ? 'Update Brand':'Add Brand'}</Button> */}
                </div>
              </Col>
            </Form>
          </Row>
        </div>
      </div>
    </div>
  );
};
export default AddEmailtemplate;
