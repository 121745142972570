import React, { useState, useEffect } from "react";
import { Form, Button, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { useSelector, useDispatch } from "react-redux";
import "../setting/setting.scss";
import Layout from "../../common/layout";
import { toast } from "react-toastify";
import ProfileUpload from "./profileupload.jsx";
import { updateUser } from "../../redux/slices/authSlice";
import PasswordUpdate from "./passwordUpdate";
import EmailUpdate from "./emailUpdate";
import PhoneUpdate from "./phoneUpdate";

const ProfileSetting = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const [isUpdate, setIsUpdate] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  const [profileDetail, setProfileDetails] = useState({
    first_name: user?.first_name ? user?.first_name : "",
    last_name: user?.last_name ? user.last_name : "",
    email: user?.email ? user.email : "",
    contact_no: user?.contact_no ? user?.contact_no : "",
    image: user?.image ? user?.image : "",
  });

  const handleChange = (e) => {
    setProfileDetails((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  useEffect(() => {
    setProfileDetails({
      first_name: user?.first_name ? user?.first_name : "",
      last_name: user?.last_name ? user.last_name : "",
      email: user?.email ? user.email : "",
      contact_no: user?.contact_no ? user?.contact_no : "",
      image: user?.image ? user?.image : "",
    });
  }, [user]);

  const updateProfile = async (formData) => {
    setIsDisabled(true);
    const response = await dispatch(updateUser(formData));
    if (response.payload.status === 205) {
      localStorage.setItem("user", JSON.stringify(response.payload.user));
      toast.success("Profile updated successfully");
    }
    setIsDisabled(false);
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    setIsDisabled(true);
    if (isUpdate) {
      if (profileDetail?.first_name && profileDetail?.last_name) {
        const { email, contact_no, ...details } = profileDetail;
        const formData = new FormData();
        if (typeof details.image === "string") {
          delete details.image;
        }
        Object.keys(details)?.forEach((field) =>
          formData.append(field, profileDetail[field])
        );

        updateProfile(formData);
      } else {
        toast.error("first name & last name are required");
      }
      setIsUpdate(false);
    }
    setIsDisabled(false);
  };

  return (
    <>
      <Layout>
        <div className="content-body">
          <div className="content-body-inner">
            <div className="page-header">
              <h1>Profile Setting</h1>
              <Breadcrumb>
                <span>
                  <Link to={`/profile-setting`} className="link-btn">
                    Setting
                  </Link>
                </span>

                <span className="mx-1"> / </span>

                <span>Profile Setting</span>
              </Breadcrumb>
            </div>
            <div className="page-content setting-page-content">
              <Form className="user-profile-wrap" onSubmit={handleFormSubmit}>
                <Row className="">
                  <Col sm="12" md="4" lg="4">
                    <Form.Label column md="12">
                      Profile Photo
                    </Form.Label>
                    <div className="seller-detail-wrap">
                      <div className="seller-profile-image">
                        <ProfileUpload
                          profileImage={true}
                          setMethod={setProfileDetails}
                          image={profileDetail.image}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col sm="12" md="8" lg="8">
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formSignUpFirstName"
                    >
                      <Col md="6">
                        <Form.Label column md="12">
                          First Name<span class="astrik">*</span>
                        </Form.Label>

                        <Form.Control
                          name="first_name"
                          type="text"
                          placeholder="Enter First Name"
                          value={profileDetail.first_name}
                          onChange={handleChange}
                        />
                      </Col>

                      <Col md="6">
                        <Form.Label column md="12">
                          Last Name<span class="astrik">*</span>
                        </Form.Label>

                        <Form.Control
                          name="last_name"
                          type="text"
                          placeholder="Enter Last Name"
                          value={profileDetail.last_name}
                          onChange={handleChange}
                        />
                      </Col>

                      <Col md="6">
                        <Form.Label column md="12">
                          Contact No
                        </Form.Label>

                        <Form.Control
                          name="contact_no"
                          type="text"
                          placeholder="Enter Contact No"
                          value={profileDetail.contact_no}
                          readOnly={true}
                        />
                      </Col>
                      <Col md="6">
                        <Form.Label
                          column
                          md="12"
                          className="fake-label"
                        ></Form.Label>
                        <div className="modal-btn">
                          <PhoneUpdate />
                        </div>
                      </Col>
                      <Col md="6">
                        <Form.Label column md="12">
                          Email Address
                        </Form.Label>

                        <Form.Control
                          name="email"
                          type="text"
                          placeholder="Enter Agent Email Address"
                          value={profileDetail.email}
                          readOnly={true}
                        />
                      </Col>
                      <Col md="6">
                        <Form.Label
                          column
                          md="12"
                          className="fake-label"
                        ></Form.Label>
                        <div className="modal-btn">
                          <EmailUpdate />
                        </div>
                      </Col>
                      <Col md="6">
                        <div className="modal-btn">
                          <PasswordUpdate />
                        </div>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <div className="bottom-control d-flex justify-content-end">
                  <Button
                    sx={{ padding: "10px 15px" }}
                    type="submit"
                    onClick={() => setIsUpdate(true)}
                    disabled={isDisabled}
                  >
                    Update
                  </Button>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default ProfileSetting;
