import React, { useState } from 'react';
import { Form, Button, Row, Col } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import ChangeEmail from './ChangeEmail';
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { verifyPassword } from "../../redux/slices/authSlice";

const EmailUpdate = (props) => {
    const dispatch = useDispatch();
    const [isDisabled, setIsDisabled] = useState(false);
    const [show, setShow] = useState(false);
    const [showEmailModal, setShowEmailModal] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
      } = useForm();
      const onSubmit = async (data) => {
        setIsDisabled(true)
        const formData = new FormData();
        formData.append("password", data.password);
        const response = await dispatch(verifyPassword(formData));
        if (response.payload.status === 200) {
            reset();
            handleClose()
            toast.success("Password matched successfully");
            setShowEmailModal(true)
        }else{
            toast.error("Provided password is mismatch");
        }
        setIsDisabled(false)
      };

    // Hide Email modal
    const hideEmailModal = () => setShowEmailModal(false);
    return (
        <div>
            <button
                type="button"
                variant="link"
                className="link-btn"
                onClick={handleShow}
            >
                Update Email Address?
            </button>
            <Modal
                show={show}
                onHide={handleClose}
                backdrop={true}
                keyboard={false}
                className="update-modal"
            >
                <Modal.Body>
                    <h1>Change Email Address?</h1>
                    <p>
                        For your security, you must re-enter your password to
                        continue.
                    </p>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Form.Group as={Row} className="mb-3" controlId="">
                            <Col md="12" className="d-flex align-items-center">
                                <Form.Label column md="6">
                                    Password<span class="astrik">*</span>
                                </Form.Label>
                                <Col md="6" className="d-flex justify-content-end">
                                    
                                </Col>
                            </Col>

                            <Col md="12">
                                <Form.Control
                                    type="password"
                                    placeholder="Password" {...register("password", { required: true })}
                                    />
                                    {errors.password && (
                                    <span className="error">This field is required</span>
                                    )}
                            </Col>
                        </Form.Group>

                        <Button type='submit'
                            className="w-100"
                            size="lg"
                            disabled={isDisabled}
                        >
                            Continue
                        </Button>
                    </form>
                </Modal.Body>
            </Modal>
            {/* Contact Modal */}
            <ChangeEmail show={showEmailModal} handleClose={hideEmailModal} />
        </div>
    );
};

export default EmailUpdate;
