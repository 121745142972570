import React,{useState} from 'react';
import { Form, Button, Col, Row } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { updateContact } from '../../redux/slices/authSlice';
import { useDispatch } from 'react-redux';
import { useForm } from "react-hook-form";
import { toast } from 'react-toastify';

const ChangeContact = ({ show, handleClose }) => {
    const dispatch = useDispatch();
    const [isDisabled, setIsDisabled] = useState(false);
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
      } = useForm();
      const onSubmit = async (data) => {
        setIsDisabled(true)
        const formData = new FormData();
        formData.append("contact_no", data.contact_no);
        const response = await dispatch(updateContact(formData));
        if (response.payload.status === 205) {
            reset();
            handleClose()
            localStorage.setItem(
                'user',
                JSON.stringify(response.payload.user)
            );
            toast.success("contactNo updated successfully");
        }else{
            toast.erroe("Try Again!!");
        }
        setIsDisabled(false)
     };

    return (
        <Modal
            show={show}
            onHide={handleClose}
            backdrop={true}
            keyboard={false}
            className="update-modal"
        >
            <Modal.Body>
                <h1>Change Phone Number?</h1>
                <p>Please add the new contact address you wish to add.</p>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Form.Group as={Row} className="mb-3" controlId="">
                        <Col md="12" className="d-flex align-items-center">
                            <Form.Label column md="6">
                                New Contact No<span class="astrik">*</span>
                            </Form.Label>
                        </Col>

                        <Col md="12">
                            <Form.Control
                                type="phone"
                                placeholder="Enter New Contact No"
                                {...register("contact_no", { required: true })}
                            />
                            {errors && errors.contact_no && (
                                <p style={{ color: "red" }}>This field is required.</p>
                            )}
                        </Col>
                    </Form.Group>
                    <Button className="w-100" size="lg" type="submit" disabled={isDisabled}>
                        Update Contact No
                    </Button>
                </form>
            </Modal.Body>
        </Modal>
    );
};

export default ChangeContact;
