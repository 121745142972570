import React, { useState, useEffect } from "react";
import { Form, Button } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import Table from "react-bootstrap/Table";
import Pagination from "react-bootstrap/Pagination";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { InputGroup } from "react-bootstrap";
import { BsSearch } from "react-icons/bs";
import Dropdown from "react-bootstrap/Dropdown";
import { BiDotsVerticalRounded } from "react-icons/bi";
import { FaPlus } from "react-icons/fa";
import Layout from "../../common/layout";
import { useSelector } from "react-redux";
import { getBrandsService } from "../../services/brandServices/getBrands.service";
import { deleteBrandService } from "../../services/brandServices/deleteBrand.service";
import "../property/property.scss";
import "./brands.scss";
import { toast } from "react-toastify";
import { saveBrandsService } from "../../services/brandServices/saveBrands.service";
import Loader from "../../common/Loader/loader";
import { useDebounce } from "../../common/Functions/useDebounce";

const BrandsList = () => {
  const navigate = useNavigate();
  const { token } = useSelector((state) => state.auth);
  const [showDelete, setShowDelete] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [filter, setFilter] = useState("-created_date");
  // eslint-disable-next-line
  const [isEditable, setIsEditable] = useState(true);

  const [brands, setBrands] = useState([]);
  const [totalRecords, setTotalRecords] = useState(null);
  const [id, setId] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const debouncedSearchTerm = useDebounce(searchTerm, 500);
  const [page, setPage] = useState(1);

  const pageNums = [];

  for (let i = 1; i <= Math.ceil(totalRecords / 10); i++) {
    pageNums.push(i);
  }

  const getBrands = async () => {
    setIsDisabled(true);
    const response = await getBrandsService({ page, filter, searchTerm });
    setBrands(response?.data?.data?.results);
    setTotalRecords(response?.data?.data?.count);
    setIsDisabled(false);
  };

  const deleteBrand = async () => {
    try {
      setIsDisabled(true);
      const response = await deleteBrandService(id, token);
      if (response.status === 204) {
        toast.success("Brand deleted successfully");
        setId("");
        setShowDelete(false);
      }
      getBrands();
    } catch (err) {
      setIsDisabled(false);
      toast.error("Something went wrong");
    } finally {
      setIsDisabled(false);
    }
  };

  const updateBrand = async (item) => {
    const data = new FormData();
    data.append("is_active", !item.is_active);
    try {
      setIsDisabled(true);
      const response = await saveBrandsService(token, item.id, data);
      if (response.status === 200) {
        toast.success("Brand updated successfully");
        getBrands();
      }
    } catch (err) {
      setIsDisabled(false);
      toast.error("Something went wrong");
    } finally {
      setIsDisabled(false);
    }
  };

  useEffect(() => {
    getBrands();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, filter, debouncedSearchTerm]);

  // eslint-disable-next-line
  function getFormattedDateTime(formatDate) {
    var date = new Date(formatDate);
    var day = date.getDate().toString().padStart(2, "0");
    var month = (date.getMonth() + 1).toString().padStart(2, "0");
    var year = date.getFullYear().toString();
    var hours = date.getHours();
    var minutes = date.getMinutes().toString().padStart(2, "0");
    var ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12;
    var strTime = hours + ":" + minutes + " " + ampm;
    return day + "/" + month + "/" + year + ", " + strTime;
  }

  // eslint-disable-next-line
  function getDateDifference(dateString1, dateString2) {
    const date1 = new Date(dateString1);
    const date2 = new Date(dateString2);

    const timeDiff = Math.abs(date2.getTime() - date1.getTime());

    // Calculate the number of days, hours, minutes, and seconds
    const diffDays = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
    const diffHours = Math.floor(
      (timeDiff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const diffMinutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));
    // const diffSeconds = Math.floor((timeDiff % (1000 * 60)) / 1000);

    return `${diffDays} days, ${diffHours} hours, ${diffMinutes} minutes`;
  }

  return (
    <>
      <Layout>
        <div className="content-body">
          <div className="content-body-inner">
            <div className="page-header">
              <h1>Brands List</h1>
              <Breadcrumb>
                <Link to={`/brands`} className="link-btn">
                  Brands
                </Link>
                <span className="mx-1">/</span>
                <span>BrandsList</span>
              </Breadcrumb>
            </div>
            <div className="page-content  proprty-list-wrap admin-list-wrap brand-list-table">
              <div className="filter-area">
                <div className="tab-bar"></div>
                <div className="table-search-bar">
                  <Form.Group
                    className="search-field-wrap"
                    controlId="exampleForm.ControlInput1"
                  >
                    <InputGroup className="searchbox-wrap">
                      <Form.Control
                        placeholder="Search brand name here"
                        aria-label="Search brand name here"
                        aria-describedby="basic-addon2"
                        onChange={(e) => {
                          setSearchTerm(e?.target?.value);
                          setPage(1);
                        }}
                        value={searchTerm}
                      />
                      <Button variant="outline-secondary" id="button-addon2">
                        <BsSearch />
                      </Button>
                    </InputGroup>
                  </Form.Group>
                </div>
                <div className="table-filter d-flex">
                  <Dropdown>
                    <Dropdown.Toggle id="dropdown-basic">
                      {filter === "-created_date" ? "Newest" : "Oldest"}
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item
                        href="#"
                        onClick={() => {
                          setFilter(
                            filter === "-created_date"
                              ? "created_date"
                              : "-created_date"
                          );
                        }}
                      >
                        {filter === "-created_date" ? "Oldest" : "Newest"}
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                  <Button
                    variant="primary"
                    onClick={() => {
                      navigate("/add-brands");
                    }}
                  >
                    <FaPlus /> New Brand
                  </Button>
                </div>
              </div>
              <div className="brand-list-table">
                <div className="listing-table blog-listing-table">
                  <Table responsive="xl">
                    <thead>
                      <tr>
                        <th className="id-th"> Id</th>
                        <th className="img-th">Image</th>
                        <th className="name-th">Name</th>

                        <th className="url-th">Url</th>
                        <th className="active-th">Action</th>
                        <th class="empty-th"> </th>
                      </tr>
                    </thead>
                    <tbody>
                      {isDisabled ? (
                        <tr>
                          <td className="text-center" colspan="10">
                            <Loader />
                          </td>
                        </tr>
                      ) : brands.length > 0 ? (
                        brands.map((item) => {
                          return (
                            <tr>
                              <td>{item?.id}</td>
                              <td>
                                <img src={item?.image} alt="brand-img" />
                              </td>
                              <td>{item?.name}</td>

                              <td>{item?.url}</td>
                              <td>
                                <Form.Check
                                  type="switch"
                                  id="custom-switch"
                                  label=""
                                  value={item?.is_active}
                                  checked={item?.is_active}
                                  onChange={() => updateBrand(item)}
                                />
                              </td>
                              <td className="dd-action">
                                <Dropdown>
                                  <Dropdown.Toggle id="dropdown-basic">
                                    <BiDotsVerticalRounded />
                                  </Dropdown.Toggle>

                                  <Dropdown.Menu>
                                    <Dropdown.Item
                                      onClick={() => {
                                        navigate("/add-brands", {
                                          state: {
                                            data: isEditable,
                                            id: item.id,
                                          },
                                        });
                                      }}
                                    >
                                      Edit
                                    </Dropdown.Item>

                                    <Dropdown.Item
                                      onClick={() => {
                                        setId(item.id);
                                        setShowDelete(true);
                                      }}
                                      disabled={isDisabled}
                                    >
                                      Remove
                                    </Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td className="text-center" colspan="5">
                            No Brands Found
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                </div>
              </div>
              <div className="listing-table-pagination">
                <div className="pagination-info">
                  <p>
                    Showing <span>{brands?.length}</span> from{" "}
                    <span>{totalRecords}</span> data{" "}
                  </p>
                </div>

                <Pagination>
                  {page !== 1 && (
                    <Pagination.Prev
                      className="prev-btn"
                      onClick={() => setPage(page - 1)}
                    />
                  )}

                  {pageNums.map((pageNum) => (
                    <Pagination.Item
                      key={pageNum}
                      active={page === pageNum}
                      onClick={() => setPage(pageNum)}
                    >
                      {pageNum}
                    </Pagination.Item>
                  ))}
                  {page !== Math.ceil(totalRecords / 10) &&
                    totalRecords !== 0 && (
                      <Pagination.Next
                        className="next-btn"
                        onClick={() => setPage(page + 1)}
                      />
                    )}
                </Pagination>
              </div>
            </div>
          </div>
        </div>

        <Modal show={showDelete} onHide={() => setShowDelete(false)}>
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <h3>Sure you want to remove this Brand? </h3>
            <p> This action will permanently remove this Brand from the list</p>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => {
                setShowDelete(false);
              }}
            >
              No,Cancel
            </Button>
            <Button className="btn-alert" onClick={deleteBrand}>
              Yes,Confirm
            </Button>
          </Modal.Footer>
        </Modal>
      </Layout>
    </>
  );
};

export default BrandsList;
