import Spinner from 'react-bootstrap/Spinner';
import './loader.scss';

const Loader = ({fallback}) => {
  return (
    <div className={`spinner-div ${fallback ? "spinner-fallback-height" : ""}`}>
    <Spinner animation="border" role="status" />
    </div>
  );
}

export default Loader;