import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import ProgressBar from "react-bootstrap/ProgressBar";
import { FiHome } from "react-icons/fi";
import { useDispatch } from "react-redux";
import Chart from "react-apexcharts";
import {
  BsChatLeftText,
  BsFillArrowUpCircleFill,
  BsFillArrowDownCircleFill,
} from "react-icons/bs";
import "./dashboard.scss";
import Layout from "../../common/layout";
import { getDashboardData } from "../../redux/slices/propertySlice";
import Loader from "../../common/Loader/loader";
import { Link } from "react-router-dom";

const Dashboard = () => {
  const dispatch = useDispatch();
  const [dashboardData, setDashboardData] = useState([]);
  const [isDisabled, setIsDisabled] = useState(false)
  const pieChartData = {
    series: [dashboardData?.total_agents,dashboardData?.total_customers],
    options: {
      chart: {
        width: 380,
        type: 'pie',
      },
      colors: ['#406275', '#72D1DB'],
      labels: ['Agent', 'Customers'],
      responsive: [{
        breakpoint: 480,
        options: {
          chart: {
            width: 300
          },
          legend: {
            position: 'bottom',
            horizontalAlign: 'center',
          }
        }
      }]
    }, 
  }

  const listingData = dashboardData?.new_listing_month?.map(obj => Object.values(obj)[0]);
  const viewData = dashboardData?.total_view_month?.map(obj => Object.values(obj)[0]);
  const combinedArray = listingData?.length > 0 && viewData?.length > 0 ? listingData.concat(viewData) : [];
  const lineChartData= {
    series: [
      {
        name: "Views",
        data: viewData ? viewData : []
      },
      {
        name: "New Listing",
        data: listingData ? listingData : []
      }
    ],
    options: {
      chart: {
        height: 350,
        type: 'line',
        dropShadow: {
          enabled: true,
          color: '#000',
          top: 18,
          left: 7,
          blur: 10,
          opacity: 0.2
        },
        toolbar: {
          show: false
        }
      },
      colors: ['#007f7f','#72D1DB'],
      dataLabels: {
        enabled: true,
      },
      stroke: {
        curve: 'smooth'
      },
      title: {
        text: '',
        align: 'left'
      },
      grid: {
        borderColor: '#e7e7e7',
        row: {
          colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
          opacity: 0.5
        },
      },
      markers: {
        size: 1
      },
      xaxis: {
        categories: dashboardData?.new_listing_month?.map(obj => Object.keys(obj)[0]),
        title: {
          text: ''
        }
      },
      yaxis: {
        title: {
          text: ''
        },
        min:  combinedArray?.length > 0 ? Math.min(...combinedArray) : 0,
        max:  combinedArray?.length > 0 ? Math.max(...combinedArray) : 50
      },
      legend: {
        position: 'top',
        horizontalAlign: 'right',
        floating: true,
        offsetY: -25,
        offsetX: -5
      }
    },
  }

  const getDashboardDataList = async () => {
    setIsDisabled(true)
    const response = await dispatch(getDashboardData());
    if (response.payload.status === 200) {
      setDashboardData(response.payload.dashboardData);
    }
    setIsDisabled(false)
  };

  useEffect(() => {
    getDashboardDataList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function formatDate(customerdate) {
    const date = new Date(customerdate);
    const day = date.getDate().toString().padStart(2, '0'); // get day and pad with 0 if needed
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // get month and pad with 0 if needed
    const year = date.getFullYear().toString(); // get year
    return `${day}/${month}/${year}`; 
  }

  function formatTime(customerTime) {
    const date = new Date(customerTime);
    let hours = date.getHours();
    const minutes = date.getMinutes().toString().padStart(2, '0'); // get minutes and pad with 0 if needed
    const amPm = hours >= 12 ? 'PM' : 'AM'; // determine if it's AM or PM
    hours %= 12; // convert to 12-hour format
    hours = hours || 12; 
    const formattedTime = `${hours}:${minutes} ${amPm}`; 
    return formattedTime;
  }

  return (
    <>
      <Layout>
        <div className="content-body">
          <div className="content-body-inner">
            <div className="page-header">
              <h1>Dashboard</h1>
              <Breadcrumb>
                <span>Dashboard</span>
                <span className="seprator">{/* <AiFillCaretRight /> */}/</span>
                <span>Dashboard</span>
              </Breadcrumb>
            </div>
            <div className="page-content">
              {isDisabled ? (
                <Loader/>
              ):(
                <>
                <div className="main-analytics-card">
                  <Row className="align-items-center">
                    <Col md="3" lg="3">
                      <div className="card-wrapper">
                        <div className="card-info">
                          <small>Properties</small>
                          <h2>{dashboardData?.properties}</h2>
                        </div>
                        <div className="card-graph">
                          <p className="m-0">
                            Graph
                            <span className="icon">
                              <FiHome />
                            </span>
                          </p>
                        </div>
                      </div>
                    </Col>
                    <Col md="3" lg="3">
                      <div className="card-wrapper">
                        <div className="card-info">
                          <small>Inquiries</small>
                          <h2>{dashboardData?.inquaries}</h2>
                        </div>
                        <div className="card-graph">
                          <p className="m-0">
                            Graph
                            <span className="icon">
                              {" "}
                              <BsChatLeftText />{" "}
                            </span>
                          </p>
                        </div>
                      </div>
                    </Col>
                    <Col md="3" lg="3">
                      <div className="card-wrapper">
                        <div className="card-info">
                          <small>New Agents</small>
                          <div className="d-flex">
                            <h2>{ dashboardData?.new_agents ? Math.abs(dashboardData?.new_agents) : '-'}</h2>
                            <span className="up">
                              {" "}
                              {dashboardData?.new_agents > 0 ? (
                                <BsFillArrowUpCircleFill className="green-color" />
                              ) : (
                                <BsFillArrowDownCircleFill className="red-color" />
                              )}
                            </span>
                          </div>
                        </div>
                        <div className="card-graph">
                          <p className="m-0">Graph</p>
                        </div>
                      </div>
                    </Col>
                    <Col md="3" lg="3">
                      <div className="card-wrapper">
                        <div className="card-info">
                          <small>New Customers</small>
                          <div className="d-flex">
                            <h2>{dashboardData?.total_customers ? Math.abs(dashboardData?.total_customers) : '-'}</h2>
                            <span className="down">
                              {dashboardData?.total_customers > 0 ? (
                                <BsFillArrowUpCircleFill className="green-color" />
                              ) : (
                                <BsFillArrowDownCircleFill className="red-color" />
                              )}
                            </span>
                          </div>
                        </div>
                        <div className="card-graph">
                          <p className="m-0">Graph</p>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
                <Row className="">
                  <Col sm="12" md="6" lg="5" xl="6">
                    <div className="main-analytics-card">
                      <Row className="align-items-center">
                        <Col md="6" lg="6">
                          <div className="card-wrapper property-info-card">
                            <div className="card-info">
                              <small>Featured Property</small>
                              <div className="d-flex">
                                <h2>{dashboardData?.featured_properties}</h2>
                                <span>/{dashboardData?.properties}</span>
                              </div>
                            </div>
                            <div className="card-graph">
                              <Link to="/property" class="link-btn">
                                View All
                              </Link>
                            </div>
                          </div>
                        </Col>
                        <Col md="6" lg="6">
                          <div className="card-wrapper property-info-card">
                            <div className="card-info">
                              <small>Active Property</small>
                              <div className="d-flex">
                                <h2>{dashboardData?.active_properties}</h2>
                                <span>/{dashboardData?.properties}</span>
                              </div>
                            </div>
                            <div className="card-graph">
                              <Link to="/property" class="link-btn">
                                View All
                              </Link>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div>
                    <div className="overview-wrap card-wrapper ">
                      <div className="title-wrap">
                        <h3>Overview</h3>
                      </div>
                      <div className="count-wrap">
                        <div className="count total-view">
                          <span className="icon">
                            <FiHome />
                          </span>
                          <div className="data">
                            <small>Total Views</small>
                            <p className="m-0">
                              {dashboardData?.overview?.total_unit} Unit
                            </p>
                          </div>
                        </div>
                        <div className="count new-list">
                          <span className="icon">
                            <FiHome />
                          </span>
                          <div className="data">
                            <small>New Listings</small>
                            <p className="m-0">
                              {dashboardData?.overview?.new_listing} Unit
                            </p>
                          </div>
                        </div>
                        <div className="performance">
                          <div className="data">
                            <p className="m-0 green-color">
                              {dashboardData?.overview?.then_last_week ? Math.abs(dashboardData?.overview?.then_last_week) : '0' } %
                            </p>
                            <small>then last week</small>
                          </div>
                          <span className="icon">
                            {" "}
                            {dashboardData?.overview?.then_last_week > 0 ? (
                              <BsFillArrowUpCircleFill className="green-color" />
                            ) : (
                              <BsFillArrowDownCircleFill className="red-color" />
                            )}
                          </span>
                        </div>
                      </div>
                      <div className="graph-wrap">
                        <Chart options={lineChartData.options} series={lineChartData.series} type="line" height={350} />
                      </div>
                    </div>
                  </Col>
                  <Col sm="12" md="6" lg="3" xl="3">
                    <div className="agent-customer card-wrapper">
                      <Chart options={pieChartData.options} series={[dashboardData?.total_agents,dashboardData?.total_customers]} type="pie" />
                    </div>
                    <div className="property-analytics card-wrapper">
                      <div className="progress-inner-wrap inquiry-wrap">
                        <div className="d-flex title">
                          <h4>Property Inquired </h4>
                          <p>
                            <span>{dashboardData?.property_inquired}</span>/days
                          </p>
                        </div>
                        <ProgressBar now={dashboardData?.property_inquired} />
                      </div>
                      <div className="progress-inner-wrap view-wrap">
                        <div className="d-flex title">
                          <h4>Frequently Viewed Property</h4>
                          <p>
                            <span>{dashboardData?.frequently_viewed_property}</span> Unit
                          </p>
                        </div>
                        <ProgressBar now={dashboardData?.frequently_viewed_property} />
                      </div>
                    </div>
                    <div className="blog-analytics card-wrapper">
                      <div className="blog-inner-wrap view-wrap">
                        <div className="d-flex title">
                          <h4>Article Viewed</h4>
                          <p>
                            <span>{dashboardData?.articles_viewed}</span>/days
                          </p>
                        </div>
                        <ProgressBar now={dashboardData?.articles_viewed} />
                      </div>
                      <div className="blog-inner-wrap list-wrap">
                        <div className="d-flex title">
                          <h4>Article Listed</h4>
                          <p>
                            <span>{dashboardData?.article_listed}</span>
                          </p>
                        </div>
                        <ProgressBar now={dashboardData?.article_listed} />
                      </div>
                      <div className="blog-inner-wrap month-wrap">
                        <div className="d-flex title">
                          <h4>Article This Month</h4>
                          <p>
                            <span>{dashboardData?.article_this_month}</span>
                          </p>
                        </div>
                        <ProgressBar now={dashboardData?.article_this_month} />
                      </div>
                    </div>
                  </Col>
                  <Col sm="12" md="6" lg="4" xl="3">
                    <div className="recent-customer card-wrapper">
                      <div className="title-wrap">
                        <h3>Recent Customer</h3>
                      </div>
                      <div className="list-wrap">
                        {dashboardData?.recent_customer?.length > 0 ? dashboardData?.recent_customer?.map((item) => (
                          <div className="list-row">
                            <div className="avatar">
                              {item?.image && (
                                <img
                                  src={item?.image}
                                  alt="recent-customer-avatr"
                                />
                              )}
                            </div>
                            <div className="list-info">
                              <h4>{item?.first_name + " " + item?.last_name}</h4>
                              <p>
                                JOIN IN <span className="date">{item?.date_joined ? formatDate(item?.date_joined) : '-'}</span>,{" "}
                                <span className="time">{item?.date_joined ? formatTime(item?.date_joined) : '-'}</span>
                              </p>
                            </div>
                          </div>
                        )) : (
                          <p>No Recent Customer Found</p>
                        )}
                      </div>
                    </div>
                  </Col>
                </Row>
                </>
              )}
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default Dashboard;
