import { createAsyncThunk } from '@reduxjs/toolkit';
import { addArticleCategoryService } from '../../services/articleServices/addArticleCategory.service';
import { getArticleCategoryService } from '../../services/articleServices/getArticleCategory.service';
import { addArticleService } from '../../services/articleServices/addArticle.service';
import { getArticleService } from '../../services/articleServices/getArticle.service';
import { getArticleByIdService } from '../../services/articleServices/getArticleById.service';
import { deleteArticleService } from '../../services/articleServices/deleteArticle.service';
import { imageUploadService } from '../../services/articleServices/imageUpload.service';
import { getDraftArticleService } from '../../services/articleServices/getDraftArticle.service';
import { getPublishArticleService } from '../../services/articleServices/getPublishArticle.service';
import { updateArticleService } from '../../services/articleServices/updateArticle.service'
import { updateArticleCategoryService } from '../../services/articleServices/updateArticleCategory.service'

const token = () => localStorage.getItem('token') || null;

export const addArticleCategory = createAsyncThunk(
    'article/addCategory',
    async (articleCategory, { rejectWithValue }) => {
        try {
            const response = await addArticleCategoryService(articleCategory, token())
            return {
                status: response.status,
            };
        } catch (error) {
            return rejectWithValue({ data: error });
        }
    }
);

export const getArticleCategoryList = createAsyncThunk(
    'article/getcategory',
    async (token, { rejectWithValue }) => {
        try {
            const response = await getArticleCategoryService(localStorage.getItem('token'))
            return {
                status: response.status,
                articleCategoryData: response.data.data.results
            };
        } catch (error) {
            return rejectWithValue({ data: error });
        }
    }
);

export const updateArticle = createAsyncThunk(
    'article/updateArticle',
    async (data, { rejectWithValue }) => {
        try {
            const response = await updateArticleService(data?.articleDetail, data?.id, token())
            return {
                status: response.status,
            };
        } catch (error) {
            return rejectWithValue({ data: error });
        }
    }
);

export const updateArticleCategory = createAsyncThunk(
    'article/updateArticleCategory',
    async (data, { rejectWithValue }) => {
        try {
            const response = await updateArticleCategoryService(data?.articleDetail, data?.id, token())
            return {
                status: response.status,
            };
        } catch (error) {
            return rejectWithValue({ data: error });
        }
    }
);

export const addArticle = createAsyncThunk(
    'article/addArticle',
    async (articleDetail, { rejectWithValue }) => {
        try {
            const response = await addArticleService(articleDetail, token())
            return {
                status: response.status,
                articleId: response.data.data.id
            };
        } catch (error) {
            return rejectWithValue({ data: error });
        }
    }
);

export const getArticleList = createAsyncThunk(
    'article/getArticleList',
    async (data, { rejectWithValue }) => {
        try {
            const response = await getArticleService(data?.page, data?.filter, data?.searchTerm, token())
            return {
                status: response.status,
                count: response.data.data.count,
                articleData: response.data.data.results
            };
        } catch (error) {
            return rejectWithValue({ data: error });
        }
    }
);

export const getPublishArticleList = createAsyncThunk(
    'article/getPublishArticleList',
    async (data, { rejectWithValue }) => {
        try {
            const response = await getPublishArticleService(data?.page, data?.filter, data?.searchTerm, token())
            return {
                status: response.status,
                count: response.data.data.count,
                articleData: response.data.data.results
            };
        } catch (error) {
            return rejectWithValue({ data: error });
        }
    }
);

export const getDraftArticleList = createAsyncThunk(
    'article/getDraftArticleList',
    async (data, { rejectWithValue }) => {
        try {
            const response = await getDraftArticleService(data?.page, data?.filter, data?.searchTerm, token())
            return {
                status: response.status,
                count: response.data.data.count,
                articleData: response.data.data.results
            };
        } catch (error) {
            return rejectWithValue({ data: error });
        }
    }
);

export const getArticle = createAsyncThunk(
    'article/getArticle',
    async (id, { rejectWithValue }) => {
        try {
            const response = await getArticleByIdService(id, token())
            return {
                status: response.status,
                articleData: response.data.data
            };
        } catch (error) {
            return rejectWithValue({ data: error });
        }
    }
);

export const deleteArticle = createAsyncThunk(
    'article/deleteArticle',
    async (id, { rejectWithValue }) => {
        try {
            const response = await deleteArticleService(id, token())
            return {
                status: response.status,
            };
        } catch (error) {
            return rejectWithValue({ data: error });
        }
    }
);

export const imageUpload = createAsyncThunk(
    'article/imageUpload',
    async (img, { rejectWithValue }) => {
        try {
            const response = await imageUploadService(img, token())
            return {
                status: response.status,
                imgUrl: response.data.data.image
            };
        } catch (error) {
            return rejectWithValue({ data: error });
        }
    }
);