import { useState } from 'react';
import { Button, Dropdown } from 'react-bootstrap';
import { BiDotsVerticalRounded } from 'react-icons/bi';
import { HiChevronDown, HiChevronUp } from 'react-icons/hi';
import { useSelector } from 'react-redux';
import { viewInquiryService } from '../../services/customerServices/viewInquiry.service';

const CustomerDetail = ({ customers, showDeleteModal, handleId }) => {
	// eslint-disable-next-line
	const [isDisabled, setIsDisabled] = useState(false);
	const [showInquiry, setShowInquiry] = useState(false);
	const [viewInquiryData, setViewInquiryData] = useState([]);

	const { token } = useSelector((state) => state.auth);

	const getInquiry = async (id) => {
		setShowInquiry(!showInquiry);
		const response = await viewInquiryService(id, token);
		setViewInquiryData(response?.data?.data?.results);
		console.log(response?.data?.data?.results);
	};

	const formatDate = (date) => {
		const year = new Date(date).getFullYear();
		const month = new Date(date).getMonth() + 1;
		const day = new Date(date).getDate();
		// eslint-disable-next-line
		const time = new Date(date).getTime();

		return `${day}/ ${month}/ ${year}`;
	};

	return (
		<>
			<div className="table-row">
				<div className="avatar">
					<div className=" ">
						{/* <img src={customers?.image} alt="recent-customer-avatr5" /> */}
						{customers?.image ? (
							<img src={customers?.image} alt="recent-customer-avatr5" />
						) : (
							<div className="no-img">
								<span>
									{(customers?.first_name ? customers?.first_name[0] : '') +
										(customers?.last_name ? customers?.last_name[0] : '')}
								</span>
							</div>
						)}
					</div>
				</div>
				<div className="main-info">
					<div className="inner-wrap">
						<small>#{customers?.id}</small>
						<h5>
							{customers?.first_name} {customers?.last_name}
						</h5>
						<label className="mb-0">
							Join on <span>{formatDate(customers?.date_joined)}</span>
						</label>
					</div>
				</div>

				<div className="phone">
					<div className="inner-wrap">
						<label className="mb-0">Phone Number</label>
						<p className="mb-0">{customers?.contact_no}</p>
					</div>
				</div>
				<div className="email">
					<div className="inner-wrap">
						<label className="mb-0">Email Address</label>
						<p className="mb-0">{customers?.email}</p>
					</div>
				</div>
				<div className="inquiry-history">
					<Button
						variant="link"
						className={showInquiry ? 'active' : ''}
						onClick={() => getInquiry(customers.id)}
					>
						View Inquiry{' '}
						<span> {showInquiry ? <HiChevronUp /> : <HiChevronDown />}</span>
					</Button>

					{showInquiry ? (
						<div className="inquiry-history-block">
							{viewInquiryData.length > 0 ? (
								viewInquiryData.map((inquiry) => {
									return (
										<div className="inquiry-history-row d-flex">
											<div className="property-img">
												<div className="property-img-inner">
													<img
														src={inquiry?.property_id?.media_files[0]?.url}
														alt="property"
													/>
												</div>
											</div>
											<div className="inquiry-info">
												<p className="mb-0 property-name">
													{inquiry?.property_id?.title}
												</p>
												<p className="date">
													<span>{inquiry?.property_id?.created_date}</span> -{' '}
													<span>4 June 2019</span>
												</p>
												<div className="agent-info">
													<div className="d-flex inner-wrap">
														<div className="agent-img">
															<img
																src={
																	customers?.property_id?.seller_detail?.image
																}
																alt="recent-customer-avatr6"
															/>
														</div>
														<p className="agent-name">
															{inquiry?.property_id?.seller_detail?.first_name}{' '}
															{inquiry?.property_id?.seller_detail?.last_name}
														</p>
													</div>
													<tag>Seller</tag>
												</div>
											</div>
										</div>
									);
								})
							) : (
								<div>No Inquiry To Show</div>
							)}
						</div>
					) : null}
				</div>
				<div className="dd-action">
					<Dropdown>
						<Dropdown.Toggle id="dropdown-basic">
							<BiDotsVerticalRounded />
						</Dropdown.Toggle>

						<Dropdown.Menu>
							<Dropdown.Item
								onClick={() => {
									handleId(customers.id);
									showDeleteModal();
								}}
								disabled={isDisabled}
							>
								Remove
							</Dropdown.Item>
						</Dropdown.Menu>
					</Dropdown>
				</div>
			</div>
		</>
	);
};

export default CustomerDetail;
