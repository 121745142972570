import React, { useEffect, useState } from "react";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { Row, Col } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import { ReactComponent as BedIcon } from "../../assets/icons/icon-bed.svg";
import { ReactComponent as BathIcon } from "../../assets/icons/icon-bath.svg";
import { ReactComponent as SizeIcon } from "../../assets/icons/icon-size.svg";
import { ReactComponent as GarageIcon } from "../../assets/icons/icon-garage.svg";
import { ReactComponent as YearIcon } from "../../assets/icons/icon-year.svg";
import { BiCheckCircle, BiPhone, BiEnvelope } from "react-icons/bi";
import { HiOutlineBuildingOffice } from "react-icons/hi2";
import { AiOutlineIdcard } from 'react-icons/ai';
import { MdVerified } from "react-icons/md";
import { ReactComponent as LocationIcon } from "../../assets/icons/icon-location-hero.svg";
import Layout from "../../common/layout";
import { getInquiry } from "../../redux/slices/inquirySlice";
import { useDispatch } from "react-redux";
import Map from "./map/map";
import Loader from "../../common/Loader/loader";

const InquiryDetail = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [data, setData] = useState(null);
  const [isDisabled, setIsDisabled] = useState(false)

  const getInquiryDetail = async () => {
    setIsDisabled(true)
    const response = await dispatch(getInquiry(id));
    if (response.payload.status === 200) {
      setData(response.payload.inquiryData);
    }
    setIsDisabled(false)
  };

  useEffect(() => {
    getInquiryDetail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function formatDate(date) {
    const day = date.getDate();
    const suffix = getSuffix(day); // get the day suffix (st, nd, rd, or th)
    const month = getMonthName(date.getMonth());
    const year = date.getFullYear();
    return `${day}${suffix} ${month} ${year}`;
  }

  function getSuffix(day) {
    if (day >= 11 && day <= 13) {
      return "th";
    }
    switch (day % 10) {
      case 1: return "st";
      case 2: return "nd";
      case 3: return "rd";
      default: return "th";
    }
  }

  function getMonthName(monthIndex) {
    const months = [
      "Jan", "Feb", "Mar", "Apr", "May", "Jun",
      "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];
    return months[monthIndex];
  }

  return (
    <>
      <Layout>
        <div className="content-body">
          <div className="content-body-inner">
            <div className="page-header">
              <h1>Inquiry Details</h1>
              <Breadcrumb>
                <Link to={`/inquiry`} className="link-btn">
                  Inquiry
                </Link>
                <span className="mx-1">/ </span>
                <span> {data?.property_id?.address_data?.address}</span>
              </Breadcrumb>
            </div>
            <div className="page-content">
              {isDisabled ? (
                <Loader />) : (
                <div className="property-detail-wrap">
                  <div className="header">
                    <div className="header-inner">
                      <div className="property-img">
                        <img
                          src={data?.property_id?.media_files[0].url}
                          alt="property-photo1"
                        />
                      </div>
                      <div className="status">{data?.property_id?.status}</div>
                      <Row className="align-items-center property-basic-info">
                        <Col md="4" lg="4"></Col>
                        <Col md="8" lg="8">
                          <div className="basic-info">
                            <h1>{data?.property_id?.title} </h1>
                            <h4>{data?.property_id?.address_data?.address}</h4>
                            <div className="specs">
                              <div className="content d-flex">
                                <div className="icon-wrap d-flex align-items-cente">
                                  <div className="icon">
                                    <BedIcon />
                                  </div>
                                  <p>
                                    <span>
                                      {data?.property_id?.total_bedrooms}
                                    </span>{" "}
                                    Bedrooms
                                  </p>
                                </div>
                                <div className="icon-wrap d-flex align-items-cente">
                                  <div className="icon">
                                    <BathIcon />
                                  </div>
                                  <p>
                                    <span>
                                      {data?.property_id?.total_bathrooms}
                                    </span>{" "}
                                    Bathrooms
                                  </p>
                                </div>
                                <div className="icon-wrap d-flex align-items-cente">
                                  <div className="icon">
                                    <GarageIcon />
                                  </div>
                                  <p>
                                    <span>{data?.property_id?.total_garage}</span>{" "}
                                    Garage
                                  </p>
                                </div>
                                <div className="icon-wrap d-flex align-items-cente">
                                  <div className="icon">
                                    <SizeIcon />
                                  </div>
                                  <p>
                                    <span>
                                      {data?.property_id?.property_size}
                                    </span>{" "}
                                    sqft
                                  </p>
                                </div>
                                <div className="icon-wrap d-flex align-items-cente">
                                  <div className="icon">
                                    <YearIcon />
                                  </div>
                                  <p>
                                    Yearbuild:{" "}
                                    <span>
                                      {data?.property_id?.year_of_build}
                                    </span>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                  <Row className="property-meta-data">
                    <Col md="4" lg="4">
                      <div className="left-side-data">
                        <div className="seller-info mb-5">
                          <div className="img-wrap">
                            {data?.property_id?.seller_detail?.image && (
                              <img
                                src={data?.property_id?.seller_detail?.image}
                                alt="seller1"
                              />
                            )}
                          </div>
                          <div className="info">
                            <div className="name">
                              <h3>
                                {data?.property_id?.seller_detail?.first_name +
                                  " " +
                                  data?.property_id?.seller_detail?.last_name}
                              </h3>
                              <MdVerified />
                            </div>
                            {/* <p>{data?.seller_detail?.company_name}</p> */}
                          </div>
                          <div className="contact-info">
                            <div className="d-flex">
                              <BiPhone />
                              <p>
                                {data?.property_id?.seller_detail?.contact_no}
                              </p>
                            </div>
                            <div className="d-flex">
                              <BiEnvelope />
                              <p>{data?.property_id?.seller_detail?.email}</p>
                            </div>
                            {data?.property_id?.seller_detail?.company_name && (
                              <div className="d-flex">
                                <HiOutlineBuildingOffice />
                                <p>
                                  {data?.property_id?.seller_detail?.company_name}
                                </p>
                              </div>
                            )}
                            {data?.seller_detail?.agent_license_number && (
                              <div className="d-flex">
                                <AiOutlineIdcard />
                                <p>{data?.seller_detail?.agent_license_number}</p>
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="price-wrap mb-5">
                          <h2>Price</h2>
                          <div className="price-inner-wrap">
                            <ul>
                              <li>
                                <div className="info-inner">
                                  <p>Annual Revenue</p>
                                  <span>
                                    $ {data?.property_id?.account?.revenue.toLocaleString()}
                                  </span>
                                </div>
                              </li>
                              <li>
                                <div className="info-inner">
                                  <p>Annual Expenses </p>
                                  <span>
                                    $ {data?.property_id?.account?.expenses.toLocaleString()}
                                  </span>
                                </div>
                              </li>
                              <li>
                                <div className="info-inner">
                                  <p>Net Operating Income</p>
                                  <span>$
                                    {
                                      data?.property_id?.account
                                        ?.net_operating_income.toLocaleString()
                                    }
                                  </span>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="detail-wrap mb-5">
                          <h2>Details</h2>
                          <div className="detail-inner-wrap">
                            <ul>
                              <li>
                                <div className="info-inner">
                                  <p>Build Year</p>
                                  <span>{data?.year_of_build}</span>
                                </div>
                              </li>
                              <li>
                                <div className="info-inner">
                                  <p>Bathroom</p>
                                  <span>{data?.total_bathrooms}</span>
                                </div>
                              </li>
                              <li>
                                <div className="info-inner">
                                  <p>Price</p>
                                  <span>{data?.property_id?.price.toLocaleString()}</span>
                                </div>
                              </li>
                              <li>
                                <div className="info-inner">
                                  <p>Garage</p>
                                  <span>{data?.property_id?.total_garage}</span>
                                </div>
                              </li>
                              <li>
                                <div className="info-inner">
                                  <p>Bedroom</p>
                                  <span>{data?.property_id?.total_bedrooms}</span>
                                </div>
                              </li>
                              <li>
                                <div className="info-inner">
                                  <p>Property Size</p>
                                  <span>
                                    {data?.property_id?.property_size} sqft
                                  </span>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col md="8" lg="8">
                      <div className="inquiry mb-5">
                        <h2>Inquiry History</h2>
                        <div className="content">
                          {[data?.sender_id]?.length > 0 &&
                            [data?.sender_id]?.map((item) => (
                              <div className="inquiry-detail-row d-flex">
                                <div className="basic-info d-flex">
                                  <div className="avatar">
                                    {item?.image && (
                                      <img
                                        src={item?.image}
                                        alt="recent-customer-avatr1"
                                      />
                                    )}
                                  </div>
                                  <div className="info">
                                    <p className="m-0">
                                      {item?.first_name + " " + item?.last_name}
                                    </p>
                                    <small>{item?.date_joined ? formatDate(new Date(item?.date_joined)) : '-'}</small>
                                  </div>
                                </div>
                                <div className="other-info-wrap phone">
                                  <small>Phone Number</small>
                                  <p className="m-0">{item?.contact_no}</p>
                                </div>
                                <div className="other-info-wrap email">
                                  <small>Email Address</small>
                                  <p className="m-0">{item?.email}</p>
                                </div>
                                <div className="seller-info-wrap">
                                  <div className="d-flex">
                                    <div className="avatar">
                                      {data?.property_id?.seller_detail
                                        ?.image && (
                                          <img
                                            src={
                                              data?.property_id?.seller_detail
                                                ?.image
                                            }
                                            alt="recent-customer-avatr2"
                                          />
                                        )}
                                    </div>
                                    <p className="m-0"> </p>
                                  </div>
                                  <tag>
                                    {data?.property_id?.seller_detail?.role}
                                  </tag>
                                </div>
                              </div>
                            ))}
                        </div>
                      </div>

                      <div className="description mb-5">
                        {data?.property_id?.property_description && (
                          <>
                            <h2>Desciption</h2>
                            <div className="content">
                              <p>{data?.property_description}</p>
                            </div>
                          </>
                        )}
                      </div>
                      {data?.property_id?.media_files?.length > 0 && (
                        <div className="photo mb-5">
                          <h2>Photos</h2>
                          <div className="img-gallary">
                            {data?.property_id?.media_files?.map((img) => (
                              <div className="img-inner-wrap">
                                <img src={img?.url} alt="Property1" />
                              </div>
                            ))}
                          </div>
                        </div>
                      )}
                      {data?.property_id?.property_aminities?.length > 0 && (
                        <div className="amenities mb-5">
                          <h2>Amenities</h2>
                          <div className="content">
                            <Row className="">
                              {data?.property_id?.property_aminities?.map(
                                (item) => (
                                  <Col xs="12" sm="6" md="3" lg="3">
                                    <div className="amenities-wrap">
                                      <BiCheckCircle />
                                      <span>{item?.name}</span>
                                    </div>
                                  </Col>
                                )
                              )}
                            </Row>
                          </div>
                        </div>
                      )}
                      <div className="financials mb-5">
                        <h2>Financials</h2>
                        <div className="content">
                          <Row className="">
                            <Col md="6" lg="6">
                              <div className="inner-wrap">
                                <label>Average Price Per Night</label>
                                <span className="value">
                                  ${" "}
                                  {
                                    data?.property_id?.property_financial
                                      ?.average_price_per_night.toLocaleString()
                                  }
                                  /Night
                                </span>
                              </div>
                            </Col>
                            <Col md="6" lg="6">
                              <div className="inner-wrap">
                                <label>Revenue</label>
                                <span className="value">
                                  $ {data?.property_id?.account?.revenue.toLocaleString()}
                                </span>
                              </div>
                            </Col>
                            <Col md="6" lg="6">
                              <div className="inner-wrap">
                                <label>Occupancy rate</label>
                                <span className="value">
                                  {
                                    data?.property_id?.property_financial
                                      ?.occupancy_rate
                                  }{" "}
                                  %
                                </span>
                              </div>
                            </Col>
                            <Col md="6" lg="6">
                              <div className="inner-wrap">
                                <label>Monthly Income</label>
                                <span className="value">
                                  ${" "}
                                  {
                                    data?.property_id?.account?.monthly_income
                                  }
                                </span>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </div>
                      <div className="expenses mb-5">
                        <h2>Expenses</h2>
                        <div className="content">
                          <Row className="">
                            <Col md="6" lg="6">
                              <div className="inner-wrap">
                                <label>HOA</label>
                                <span className="value">
                                  $ {data?.property_id?.property_expences?.hoa.toLocaleString()}
                                </span>
                              </div>
                            </Col>
                            <Col md="6" lg="6">
                              <div className="inner-wrap">
                                <label>Mgmt Fee</label>
                                <span className="value">
                                  {data?.property_id?.property_expences?.mgmt_fee.toLocaleString()}{" "}
                                  %
                                </span>
                              </div>
                            </Col>
                            <Col md="6" lg="6">
                              <div className="inner-wrap">
                                <label>Utilities</label>
                                <span className="value">
                                  ${" "}
                                  {
                                    data?.property_id?.property_expences
                                      ?.utilities.toLocaleString()
                                  }
                                </span>
                              </div>
                            </Col>
                            <Col md="6" lg="6">
                              <div className="inner-wrap">
                                <label>Maintenance</label>
                                <span className="value">
                                  ${" "}
                                  {
                                    data?.property_id?.property_expences
                                      ?.maintainance.toLocaleString()
                                  }
                                </span>
                              </div>
                            </Col>
                            <Col md="6" lg="6">
                              <div className="inner-wrap">
                                <label>STR License Fee</label>
                                <span className="value">
                                  ${" "}
                                  {
                                    data?.property_id?.property_expences
                                      ?.str_license_fee.toLocaleString()
                                  }
                                </span>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </div>
                      <div className="Location">
                        <h2>Location</h2>
                        <div className="content">
                          <div className="d-flex address">
                            <LocationIcon />
                            <p>{data?.property_id?.address_data?.address}</p>
                          </div>
                          <div className="add-map">
                            {data !== null ? (
                              <Map properties={[data]} pinIcon={true} />
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              )}
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};
export default InquiryDetail;
