import React, { useState, useEffect } from "react";
import { Form, InputGroup, Button } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import Table from "react-bootstrap/Table";
import Pagination from "react-bootstrap/Pagination";
import { BsSearch } from "react-icons/bs";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Dropdown from "react-bootstrap/Dropdown";
import { IoBedOutline } from "react-icons/io5";
import { BiBath } from "react-icons/bi";
import { RiShapeLine } from "react-icons/ri";
import { FiHome } from "react-icons/fi";
import { GrDocumentUser } from "react-icons/gr";
import { HiOutlineUsers } from "react-icons/hi";
import { BsChatLeftText } from "react-icons/bs";
import "./inquiry.scss";
import "../property/property.scss";
import Layout from "../../common/layout";
import { useDispatch } from "react-redux";
import {
  getInquiryList,
  getActiveInquiryList,
  getInActiveInquiryList,
} from "../../redux/slices/inquirySlice";
import { getDashboardPropertyData } from "../../redux/slices/propertySlice";
import { useDebounce } from "../../common/Functions/useDebounce";
import Loader from "../../common/Loader/loader";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";

const InquiryList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isDisabled, setIsDisabled] = useState(false);
  const [filter, setFilter] = useState("-created_date");
  const [tab, setTab] = useState("all");
  const [page, setpage] = useState(1);
  const [inquiryData, setInquiryData] = useState([]);
  const [dashboardInquiryData, setDashboardInquiryData] = useState([]);
  const [totalrecords, settotalrecords] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const debouncedSearchTerm = useDebounce(searchTerm, 500);
  const [showList, setShowList] = useState(false);
  const [showUnlist, setShowUnlist] = useState(false);
  const [showDelete, setShowDelete] = useState(false);

  const pageNums = [];

  for (let i = 1; i <= Math.ceil(totalrecords / 10); i++) {
    pageNums.push(i);
  }

  const getInquirylist = async () => {
    setIsDisabled(true);
    var response = [];
    if (tab === "all") {
      response = await dispatch(getInquiryList({ page, filter, searchTerm }));
    } else if (tab === "active") {
      response = await dispatch(
        getActiveInquiryList({ page, filter, searchTerm })
      );
    } else {
      response = await dispatch(
        getInActiveInquiryList({ page, filter, searchTerm })
      );
    }

    if (response.payload.status === 200) {
      setInquiryData(response.payload.inquiryData);
      settotalrecords(response.payload.count);
    }
    setIsDisabled(false);
  };

  const getDashboardInquiryDataList = async () => {
    const response = await dispatch(getDashboardPropertyData());
    if (response.payload.status === 200) {
      setDashboardInquiryData(response.payload.dashboardPropertyData);
    }
  };

  useEffect(() => {
    getInquirylist();
    getDashboardInquiryDataList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, filter, tab, debouncedSearchTerm]);

  return (
    <>
      <Layout>
        <div className="content-body">
          <div className="content-body-inner">
            <div className="page-header">
              <h1>Inquiries List</h1>
              <Breadcrumb>
                <Link to={`/inquiries`} className="link-btn">
                  Inquiries
                </Link>
                <span className="mx-1">/</span>
                <span>Inquiries List</span>
              </Breadcrumb>
            </div>
            <div className="page-content proprty-list-wrap">
              <div className="analytics">
                <div className="left-wrap">
                  <div className="total-wrap">
                    <span className="icon">
                      <FiHome />
                    </span>
                    <div className="data">
                      <p className="m-0">
                        {dashboardInquiryData?.data?.total_properties}
                      </p>
                      <small>Total Properties</small>
                    </div>
                  </div>
                  <div className="total-wrap">
                    <span className="icon">
                      <BsChatLeftText />
                    </span>
                    <div className="data">
                      <p className="m-0">
                        {dashboardInquiryData?.data?.inquaries}
                      </p>
                      <small>Total Inquiries</small>
                    </div>
                  </div>
                  <div className="total-wrap">
                    <span className="icon">
                      <HiOutlineUsers />
                    </span>
                    <div className="data">
                      <p className="m-0">{dashboardInquiryData?.data?.agent}</p>
                      <small>Total Agents</small>
                    </div>
                  </div>
                </div>
              </div>
              <div className="filter-area">
                <div className="table-search-bar">
                  <Form.Group
                    className="search-field-wrap"
                    controlId="exampleForm.ControlInput1"
                  >
                    <InputGroup className="searchbox-wrap">
                      <Form.Control
                        placeholder="Search property name here"
                        aria-label="Search property name here"
                        aria-describedby="basic-addon2"
                        value={searchTerm}
                        onChange={(e) => {
                          setSearchTerm(e?.target?.value);
                          setpage(1);
                        }}
                      />
                      <Button variant="outline-secondary" id="button-addon2">
                        <BsSearch />
                      </Button>
                    </InputGroup>
                  </Form.Group>
                </div>
                <div className="table-filter">
                  <Dropdown>
                    <Dropdown.Toggle id="dropdown-basic">
                      {filter === "-created_date" ? "Newest" : "Oldest"}
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item
                        href="#"
                        onClick={() => {
                          setFilter(
                            filter === "-created_date"
                              ? "created_date"
                              : "-created_date"
                          );
                        }}
                      >
                        {filter === "created_date" ? "Newest" : "Oldest"}
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>

              <div className="tab-table-wrap">
                <Tabs
                  defaultActiveKey="all"
                  id="uncontrolled-tab-example"
                  className=""
                  onSelect={(key) => {
                    setTab(key);
                    setpage(1);
                  }}
                >
                  <Tab eventKey="all" title="All">
                    <div className="listing-table inquiry-listing-table">
                      <Table responsive="xl">
                        <thead>
                          <tr>
                            <th className="id-th"> Id</th>
                            <th className="photo-th"> Photo</th>
                            <th className="property-th"> Property</th>
                            <th className="location-th"> Location</th>
                            <th className="specs-th"> Specifications</th>
                            <th className="price-th"> Price</th>
                            <th className="revenue-th"> Revenue</th>
                            <th className="agent-th"> Agent</th>
                            <th className="status-th"> Status</th>
                            <th className="action-th"> Action</th>
                            {/* <th className="empty-th"> </th> */}
                          </tr>
                        </thead>
                        <tbody>
                          {isDisabled ? (
                            <tr>
                              <td className="text-center" colspan="10">
                                <Loader />
                              </td>
                            </tr>
                          ) : inquiryData?.length > 0 ? (
                            inquiryData.map((item) => (
                              <tr>
                                <td>{item?.id}</td>
                                <td className="photo">
                                  {item?.property_id?.media_files[0]?.url && (
                                    <div className="photo-wrap">
                                      <img
                                        src={
                                          item?.property_id?.media_files[0]?.url
                                        }
                                        alt="property-photo1"
                                      />
                                    </div>
                                  )}
                                </td>
                                <td>
                                  {" "}
                                  <Link
                                    to={`/inquiry/${item?.id}`}
                                    className="link-btn"
                                  >
                                    {item?.property_id?.title}{" "}
                                  </Link>
                                </td>
                                <td>
                                  {item?.property_id?.address_data?.address}
                                </td>
                                <td className="specs">
                                  <div className="d-flex">
                                    <div className="d-flex spec-inner-wrap">
                                      <span>
                                        <IoBedOutline />
                                      </span>
                                      <p className="m-0">
                                        {item?.property_id?.total_bedrooms} Beds
                                      </p>
                                    </div>
                                    <div className="d-flex spec-inner-wrap">
                                      <span>
                                        <BiBath />
                                      </span>
                                      <p className="m-0">
                                        {item?.property_id?.total_bathrooms}{" "}
                                        Baths
                                      </p>
                                    </div>
                                    <div className="d-flex spec-inner-wrap">
                                      <span>
                                        <RiShapeLine />
                                      </span>
                                      <p className="m-0">
                                        {item?.property_id?.property_size} sq ft
                                      </p>
                                    </div>
                                  </div>
                                </td>
                                <td>
                                  {" "}
                                  <p className="m-0">
                                    {item?.property_id?.price.toLocaleString()}
                                  </p>
                                </td>
                                <td>
                                  {" "}
                                  <p className="m-0">
                                    $ {item?.property_id?.account?.revenue.toLocaleString()}
                                  </p>
                                </td>
                                <td>
                                  {item?.property_id?.seller_detail
                                    ?.first_name +
                                    " " +
                                    item?.property_id?.seller_detail?.last_name}
                                </td>
                                <td className="status">
                                  {" "}
                                  <p
                                    className={`m-0 ${item?.property_id?.status.toLowerCase()}`}
                                  >
                                    {item?.property_id?.status}
                                  </p>
                                </td>
                                <td className="action view-inquiry">
                                  <Button
                                    variant="link"
                                    onClick={() =>
                                      navigate(`/inquiry/${item?.id}`)
                                    }
                                  >
                                    View Inquiry{" "}
                                    <span>
                                      {" "}
                                      <GrDocumentUser />{" "}
                                    </span>
                                  </Button>
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td className="text-center" colspan="10">
                                No Inquiry Found
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </Table>
                    </div>
                  </Tab>
                  <Tab eventKey="active" title="Active">
                    <div className="listing-table inquiry-listing-table">
                      <Table responsive="xl">
                        <thead>
                          <tr>
                            <th className="id-th"> Id</th>
                            <th className="photo-th"> Photo</th>
                            <th className="property-th"> Property</th>
                            <th className="location-th"> Location</th>
                            <th className="specs-th"> Specifications</th>
                            <th className="price-th"> Price</th>
                            <th className="revenue-th"> Revenue</th>
                            <th className="agent-th"> Agent</th>
                            <th className="status-th"> Status</th>
                            <th className="action-th"> Action</th>
                            {/* <th className="empty-th"> </th> */}
                          </tr>
                        </thead>
                        <tbody>
                          {isDisabled ? (
                            <tr>
                              <td className="text-center" colspan="10">
                                <Loader />
                              </td>
                            </tr>
                          ) : inquiryData?.length > 0 ? (
                            inquiryData.map((item) => (
                              <tr>
                                <td>{item?.id}</td>
                                <td className="photo">
                                  {item?.property_id?.media_files[0]?.url && (
                                    <div className="photo-wrap">
                                      <img
                                        src={
                                          item?.property_id?.media_files[0]?.url
                                        }
                                        alt="property-photo1"
                                      />
                                    </div>
                                  )}
                                </td>
                                <td>
                                  {" "}
                                  <Link
                                    to={`/inquiry/${item?.id}`}
                                    className="link-btn"
                                  >
                                    {item?.property_id?.title}{" "}
                                  </Link>
                                </td>
                                <td>
                                  {item?.property_id?.address_data?.address}
                                </td>
                                <td className="specs">
                                  <div className="d-flex">
                                    <div className="d-flex spec-inner-wrap">
                                      <span>
                                        <IoBedOutline />
                                      </span>
                                      <p className="m-0">
                                        {item?.property_id?.total_bedrooms} Beds
                                      </p>
                                    </div>
                                    <div className="d-flex spec-inner-wrap">
                                      <span>
                                        <BiBath />
                                      </span>
                                      <p className="m-0">
                                        {item?.property_id?.total_bathrooms}{" "}
                                        Baths
                                      </p>
                                    </div>
                                    <div className="d-flex spec-inner-wrap">
                                      <span>
                                        <RiShapeLine />
                                      </span>
                                      <p className="m-0">
                                        {item?.property_id?.property_size} sq ft
                                      </p>
                                    </div>
                                  </div>
                                </td>
                                <td>
                                  {" "}
                                  <p className="m-0">
                                    {item?.property_id?.price.toLocaleString()}
                                  </p>
                                </td>
                                <td>
                                  {" "}
                                  <p className="m-0">
                                    $ {item?.property_id?.account?.revenue.toLocaleString()}
                                  </p>
                                </td>
                                <td>
                                  {item?.property_id?.seller_detail
                                    ?.first_name +
                                    " " +
                                    item?.property_id?.seller_detail?.last_name}
                                </td>
                                <td className="status">
                                  {" "}
                                  <p
                                    className={`m-0 ${item?.property_id?.status.toLowerCase()}`}
                                  >
                                    {item?.property_id?.status}
                                  </p>
                                </td>
                                <td className="action view-inquiry">
                                  <Button
                                    variant="link"
                                    onClick={() =>
                                      navigate(`/inquiry/${item?.id}`)
                                    }
                                  >
                                    View Inquiry{" "}
                                    <span>
                                      {" "}
                                      <GrDocumentUser />{" "}
                                    </span>
                                  </Button>
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td className="text-center" colspan="10">
                                No Inquiry Found
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </Table>
                    </div>
                  </Tab>
                  <Tab eventKey="inActive" title="Inactive">
                    <div className="listing-table inquiry-listing-table">
                      <Table responsive="xl">
                        <thead>
                          <tr>
                            <th className="id-th"> Id</th>
                            <th className="photo-th"> Photo</th>
                            <th className="property-th"> Property</th>
                            <th className="location-th"> Location</th>
                            <th className="specs-th"> Specifications</th>
                            <th className="price-th"> Price</th>
                            <th className="revenue-th"> Revenue</th>
                            <th className="agent-th"> Agent</th>
                            <th className="status-th"> Status</th>
                            <th className="action-th"> Action</th>
                            {/* <th className="empty-th"> </th> */}
                          </tr>
                        </thead>
                        <tbody>
                          {isDisabled ? (
                            <tr>
                              <td className="text-center" colspan="10">
                                <Loader />
                              </td>
                            </tr>
                          ) : inquiryData?.length > 0 ? (
                            inquiryData.map((item) => (
                              <tr>
                                <td>{item?.id}</td>
                                <td className="photo">
                                  {item?.property_id?.media_files[0]?.url && (
                                    <div className="photo-wrap">
                                      <img
                                        src={
                                          item?.property_id?.media_files[0]?.url
                                        }
                                        alt="property-photo1"
                                      />
                                    </div>
                                  )}
                                </td>
                                <td>
                                  {" "}
                                  <Link
                                    to={`/inquiry/${item?.id}`}
                                    className="link-btn"
                                  >
                                    {item?.property_id?.title}{" "}
                                  </Link>
                                </td>
                                <td>
                                  {item?.property_id?.address_data?.address}
                                </td>
                                <td className="specs">
                                  <div className="d-flex">
                                    <div className="d-flex spec-inner-wrap">
                                      <span>
                                        <IoBedOutline />
                                      </span>
                                      <p className="m-0">
                                        {item?.property_id?.total_bedrooms} Beds
                                      </p>
                                    </div>
                                    <div className="d-flex spec-inner-wrap">
                                      <span>
                                        <BiBath />
                                      </span>
                                      <p className="m-0">
                                        {item?.property_id?.total_bathrooms}{" "}
                                        Baths
                                      </p>
                                    </div>
                                    <div className="d-flex spec-inner-wrap">
                                      <span>
                                        <RiShapeLine />
                                      </span>
                                      <p className="m-0">
                                        {item?.property_id?.property_size} sq ft
                                      </p>
                                    </div>
                                  </div>
                                </td>
                                <td>
                                  {" "}
                                  <p className="m-0">
                                    {item?.property_id?.price.toLocaleString()}
                                  </p>
                                </td>
                                <td>
                                  {" "}
                                  <p className="m-0">
                                    $ {item?.property_id?.account?.revenue.toLocaleString()}
                                  </p>
                                </td>
                                <td>
                                  {item?.property_id?.seller_detail
                                    ?.first_name +
                                    " " +
                                    item?.property_id?.seller_detail?.last_name}
                                </td>
                                <td className="status">
                                  {" "}
                                  <p
                                    className={`m-0 ${item?.property_id?.status.toLowerCase()}`}
                                  >
                                    {item?.property_id?.status}
                                  </p>
                                </td>
                                <td className="action view-inquiry">
                                  <Button
                                    variant="link"
                                    onClick={() =>
                                      navigate(`/inquiry/${item?.id}`)
                                    }
                                  >
                                    View Inquiry{" "}
                                    <span>
                                      {" "}
                                      <GrDocumentUser />{" "}
                                    </span>
                                  </Button>
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td className="text-center" colspan="10">
                                No Inquiry Found
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </Table>
                    </div>
                  </Tab>
                </Tabs>
              </div>
              <div className="listing-table-pagination">
                <div className="pagination-info">
                  <p>
                    Showing <span>{inquiryData?.length}</span> from{" "}
                    <span>{totalrecords}</span> data{" "}
                  </p>
                </div>
                <Pagination>
                  {page !== 1 && (
                    <Pagination.Prev
                      className="prev-btn"
                      onClick={() => setpage(page - 1)}
                    />
                  )}

                  {pageNums.map((pageNum) => (
                    <Pagination.Item
                      key={pageNum}
                      active={page === pageNum}
                      onClick={() => setpage(pageNum)}
                    >
                      {pageNum}
                    </Pagination.Item>
                  ))}
                  {page !== Math.ceil(totalrecords / 10) &&
                    totalrecords !== 0 && (
                      <Pagination.Next
                        className="next-btn"
                        onClick={() => setpage(page + 1)}
                      />
                    )}
                </Pagination>
              </div>
            </div>
          </div>
        </div>
        <Modal show={showList} onHide={() => setShowList(false)}>
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <h3>Sure you want to again List Property? </h3>
            <p>
              A property will be listed to the list so current users can see it.
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowList(false)}>
              No,Cancel
            </Button>
            <Button variant="primary" onClick={() => setShowList(false)}>
              Yes,Confirm
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal show={showUnlist} onHide={() => setShowUnlist(false)}>
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <h3>Sure you want to Unlist Property? </h3>
            <p>
              A property will be unlisted from the list so current users cannot
              see it.
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowUnlist(false)}>
              No,Cancel
            </Button>
            <Button variant="primary" onClick={() => setShowUnlist(false)}>
              Yes,Confirm
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal show={showDelete} onHide={() => setShowDelete(false)}>
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <h3>Sure you want to Remove Property? </h3>
            <p>
              {" "}
              This action will permanently remove this property from the list
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowDelete(false)}>
              No,Cancel
            </Button>
            <Button className="btn-alert" onClick={() => setShowDelete(false)}>
              Yes,Confirm
            </Button>
          </Modal.Footer>
        </Modal>
      </Layout>
    </>
  );
};

export default InquiryList;
