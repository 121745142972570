import React, { useState, useEffect } from "react";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { Form, Button, Row, Col } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { AiOutlineEye } from "react-icons/ai";
import Layout from "../../common/layout";
import { useDispatch } from "react-redux";
import {
  getArticleCategoryList,
  getArticle,
} from "../../redux/slices/articleSlice";
import {
  addArticleCategory,
  updateArticle,
} from "../../redux/slices/articleSlice";
import { addArticle } from "../../redux/slices/articleSlice";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { toast } from "react-toastify";
import UploadAdapter from "./upload/UploadAdapter";

const AddArticle = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [editorData, setEditorData] = useState("");
  const [articleTitle, setArticleTitle] = useState("");
  const [articleSubTitle, setArticleSubTitle] = useState("");
  const [anonymousWriter, setAnonymousWriter] = useState(false);
  const [isPublished, setIsPublished] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [preview, setPreview] = useState(false);
  const [articleCategory, setArticleCategory] = useState("");
  const [articleCategoryId, setArticleCategoryId] = useState();
  const [articleCategorydata, setArticleCategorydata] = useState([]);
  const [openCategoryModal, setOpenCategoryModal] = useState(false);

  const getArticleCategory = async () => {
    const response = await dispatch(getArticleCategoryList());
    if (response.payload.status === 200) {
      setArticleCategorydata(response.payload.articleCategoryData);
    }
  };

  useEffect(() => {
    if(preview){
      localStorage.setItem('preview',preview)
    }
  }, [preview])
  

  const updateArticleDetail = async () => {
    setIsDisabled(true);
    try {
      if (editorData !== "") {
        const articleDetail = {
          content: editorData,
          is_published: isPublished,
          anonymous_writer: anonymousWriter,
          category: articleCategoryId,
          sub_title: articleSubTitle,
          title: articleTitle,
        };
        const response = await dispatch(
          updateArticle({ articleDetail, id: location?.state?.data?.id })
        );
        if (response.payload.status === 200) {
          toast.success("Update Article successfully");
          if (localStorage.getItem('preview') === 'true') {
            navigate(`/article/${location?.state?.data?.id}`, {
              target: "_blank",
            });
            localStorage.removeItem('preview');
          } else {
            navigate("/articles");
          }
        }
      } else {
        toast.error("Article Category field are required");
      }
    } catch (error) {
      toast.error("something went wrong");
    } finally {
      setIsDisabled(false);
    }
  };

  const addArticleDetail = async () => {
    setIsDisabled(true);
    try {
      if (editorData !== "") {
        const articleDetail = {
          content: editorData,
          is_published: isPublished,
          anonymous_writer: anonymousWriter,
          category: articleCategoryId,
          title: articleTitle,
          sub_title: articleSubTitle,
          publish_date: new Date(),
        };
        const response = await dispatch(addArticle(articleDetail));
        if (response.payload.status === 201) {
          toast.success("add Article successfully");
          if (localStorage.getItem('preview') === 'true') {
            navigate(`/article/${response.payload.articleId}`, {
              target: "_blank",
            });
            localStorage.removeItem('preview');
          } else {
            navigate("/articles");
          }
        }
      } else {
        toast.error("Article Category field are required");
      }
    } catch (error) {
      toast.error("something went wrong");
    } finally {
      setIsDisabled(false);
    }
  };

  const submitCategory = async (e) => {
    e.preventDefault();

    try {
      setIsDisabled(true);
      if (articleCategory !== "") {
        const response = await dispatch(addArticleCategory(articleCategory));
        if (response.payload.status === 201) {
          toast.success("add Article successfully");
        }
      } else {
        toast.error("Article Data are required");
      }
    } catch (error) {
      toast.error("something went wrong");
    } finally {
      setIsDisabled(false);
      getArticleCategory();
    }
  };

  useEffect(() => {
    getArticleCategory();
    if (location?.state?.data) {
      getArticleDetail(location?.state?.data?.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getArticleDetail = async (articleId) => {
    const response = await dispatch(getArticle(articleId));
    if (response.payload.status === 200) {
      setIsPublished(response.payload.articleData.is_published);
      setAnonymousWriter(response.payload.articleData.anonymous_writer);
      setArticleCategoryId(response.payload.articleData.category?.id);
      setEditorData(response.payload.articleData.content);
      setArticleTitle(response.payload.articleData.title);
      setArticleSubTitle(response.payload.articleData.sub_title);
    }
  };

  // Custom Upload Adapter Plugin function
  function CustomUploadAdapterPlugin(editor) {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
      // Create new object and pass server url
      return new UploadAdapter(loader);
    };
  }

  const config = {
    language: "en", // fa - for persian language ( rtl )
    extraPlugins: [CustomUploadAdapterPlugin],
  };

  return (
    <>
      <Layout>
        <div className="content-body">
          <div className="content-body-inner">
            <div className="page-header">
              <h1>
                {location?.state?.data ? "Update Article" : "Add Article"}
              </h1>
              <Breadcrumb>
                <Link to={`/articles`} className="link-btn">
                  Articles
                </Link>
                <span className="mx-1">{/* <AiFillCaretRight /> */} / </span>
                <span>
                  {location?.state?.data
                    ? "Update Article"
                    : "Create a New Article"}{" "}
                </span>
              </Breadcrumb>
            </div>
            <div className="page-content">
              <div className="create-blog-wrap">
                <Row className="create-blog">
                  <Col
                    sm="12"
                    md="4"
                    lg="5"
                    xl="4"
                    xxl="3"
                    className="left-blog-wrap"
                  >
                    <div className="left-blog">
                      <div className="dd-wrap">
                        <h4>Choose Category</h4>
                        <label>This Article will appear in:</label>
                        <br />
                        {articleCategorydata?.length > 0 && (
                          <Form.Select
                            aria-label="Select Article Category"
                            onChange={(e) => {
                              setArticleCategoryId(e.target.value);
                            }}
                            value={articleCategoryId}
                          >
                            <option>Select Article Category</option>
                            {articleCategorydata.map((item) => {
                              return (
                                <option value={item?.id}>
                                  {item?.article_category}
                                </option>
                              );
                            })}
                          </Form.Select>
                        )}
                        <span
                          className="add-category"
                          onClick={() =>
                            setOpenCategoryModal(!openCategoryModal)
                          }
                        >
                          + New Category
                        </span>
                      </div>
                      <div className="dd-wrap">
                        <h4 className="mb-3">Settings Up</h4>
                        <div className="d-flex setting-wrap mb-2">
                          <Form.Check
                            type="checkbox"
                            label="Anonymous Writer"
                            id="default-checkbox1"
                            className="checkbox-label"
                            checked={anonymousWriter}
                            onChange={(e) =>
                              setAnonymousWriter(e.target.checked)
                            }
                          />
                        </div>
                        <div className="d-flex setting-wrap">
                          <Form.Check
                            type="checkbox"
                            label="Publish"
                            id="default-checkbox2"
                            className="checkbox-label"
                            checked={isPublished}
                            onChange={(e) => setIsPublished(e.target.checked)}
                          />
                          {/* <tag>
                            Tonight - <span>3:00 PM </span>
                          </tag> */}
                        </div>
                      </div>
                      <div className="btn-wrap d-flex">
                        <Button
                          variant="secondary"
                          disabled={isDisabled}
                          onClick={() => {
                            setPreview(true);
                            location?.state?.data
                              ? updateArticleDetail()
                              : addArticleDetail();
                          }}
                        >
                          {" "}
                          <span>
                            <AiOutlineEye />
                          </span>{" "}
                          Preview
                        </Button>
                        <Button
                          variant="primary"
                          disabled={isDisabled}
                          onClick={
                            location?.state?.data
                              ? updateArticleDetail
                              : addArticleDetail
                          }
                        >
                          Publish
                        </Button>
                      </div>
                    </div>
                  </Col>
                  <Col
                    sm="12"
                    md="8"
                    lg="7"
                    xl="8"
                    xxl="9"
                    className="right-blog-wrapper"
                  >
                    <div className="right-blog">
                      <div className="text-center">
                        <Form className="mt-4">
                          <Form.Group as={Row} className="" controlId="">
                            <Form.Label column md="2">
                              Title <span className="astrik">*</span>
                            </Form.Label>
                            <Col md="10">
                              <Form.Control
                                type="text"
                                placeholder="Enter your article title here"
                                name="title"
                                value={articleTitle}
                                onChange={(e) => {
                                  setArticleTitle(e.target.value);
                                }}
                              />
                            </Col>
                          </Form.Group>
                          <Form.Group as={Row} className="" controlId="">
                            <Form.Label column md="2">
                              SubTitle
                            </Form.Label>
                            <Col md="10">
                              <Form.Control
                                type="text"
                                placeholder="Enter your article Subtitle here"
                                name="subtitle"
                                value={articleSubTitle}
                                onChange={(e) => {
                                  setArticleSubTitle(e.target.value);
                                }}
                              />
                            </Col>
                          </Form.Group>
                          <Form.Group as={Row} className="" controlId="">
                            <Form.Label column md="2">
                              Cover Image
                            </Form.Label>
                            <Col md="10">
                              <Form.Control type="file" name="name" />
                            </Col>
                          </Form.Group>
                        </Form>
                        <CKEditor
                          editor={ClassicEditor}
                          config={config}
                          data={editorData || ""}
                          onChange={(event, editor) => {
                            const data = editor.getData();
                            setEditorData(data);
                          }}
                        />
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </div>

        <Modal
          show={openCategoryModal}
          className="edit-email-modal"
          onHide={() => setOpenCategoryModal(false)}
        >
          <Modal.Header closeButton></Modal.Header>
          <Form className="" onSubmit={submitCategory}>
            <Modal.Body>
              <h3>Add New Category</h3>
              <p>
                {" "}
                In order to add new Category to the category listing, please
                enter the category name and your article will appear in that
                category
              </p>
              <Form.Group as={Row} className="mt-4" controlId="">
                <Form.Label column md="12">
                  New Category <span class="astrik">*</span>
                </Form.Label>
                <Col md="12">
                  <Form.Control
                    type="text"
                    placeholder="Enter Category name here"
                    name="category"
                    onChange={(e) => {
                      setArticleCategory(e.target.value);
                    }}
                  />
                </Col>
              </Form.Group>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={() => setOpenCategoryModal(false)}
              >
                No,Cancel
              </Button>
              <Button
                variant="primary"
                className=""
                type="submit"
                onClick={() => setOpenCategoryModal(false)}
              >
                Yes, Add ‘Category’
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
      </Layout>
    </>
  );
};
export default AddArticle;
