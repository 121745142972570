import React, { useEffect, useState } from "react";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { Link, useParams } from "react-router-dom";
import Layout from "../../common/layout";
import { useDispatch } from "react-redux";
import { getArticle } from "../../redux/slices/articleSlice";
import Loader from "../../common/Loader/loader";

const ArticleDetail = () => {
  const dispatch = useDispatch();
  const [data, setData] = useState();
  const { articleId } = useParams();
  const [isDisabled, setIsDisabled] = useState(true)


  const getArticleDetail = async () => {
    setIsDisabled(true)
    const response = await dispatch(getArticle(articleId));
    if (response.payload.status === 200) {
      setData(response.payload.articleData);
    }
    setIsDisabled(false)
  };

  useEffect(() => {
    getArticleDetail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Layout>
        <div className="content-body">
          <div className="content-body-inner">
            <div className="page-header">
              <h1>Article Detail</h1>
              <Breadcrumb>
                <Link to={`/articles`} className="link-btn">
                  Articles
                </Link>
                <span className="mx-1">/ </span>
                <span>Digital Marketing for Real Esta..</span>
              </Breadcrumb>
            </div>
            <div className="blog-wrap">
              {isDisabled ? (
                <Loader/>
              ) : (
              <div className="blog-detail-wrap">
                <div className="main-title mb-3">
                  {data?.title && <h4 className="blog-title">{data?.title}</h4>}
                  {data?.title && (
                    <p className="sub-title">{data?.sub_title}</p>
                  )}
                </div>
                {data !== "" ? (
                  <div
                    dangerouslySetInnerHTML={{ __html: data?.content }}
                  ></div>
                ) : (
                  <h3>Blog Content Not Found</h3>
                )}
              </div>
              )}
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};
export default ArticleDetail;
