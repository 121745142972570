import React from "react";
import "./assets/styles/App.scss";
import 'react-toastify/dist/ReactToastify.css';
import { Routes, Route } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import AppRoutes from "./routes";

function App() {
  return (
    <>
    <ToastContainer
                position="top-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                theme="colored"
                pauseOnFocusLoss
                draggable
                pauseOnHover
                className="toast-text"
            />
    <Routes>
      <Route path="/*" element={<AppRoutes />} />
    </Routes>
    </>
  );
}

export default App;
