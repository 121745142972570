import { createAsyncThunk } from '@reduxjs/toolkit';
import { getPropertyListService } from '../../services/PropertyServices/getPropertyList.service';
import { getFeaturePropertyListService } from '../../services/PropertyServices/getFeaturePropertyList.service'
import { getActivePropertyListService } from '../../services/PropertyServices/getActivePropertyList.service'
import { getInActivePropertyListService } from '../../services/PropertyServices/getInActivePropertyList.service'
import { deletePropertyService } from '../../services/PropertyServices/deleteProperty.service'
import { getPropertyByIdService } from '../../services/PropertyServices/getPropertyById.service'
import { getDashboardPropertyDataService } from '../../services/PropertyServices/getDashboardPropertyData.service'
import { getDashboardDataService } from '../../services/PropertyServices/getDashboardData.service'
import { updatePropertyService } from '../../services/PropertyServices/updateProperty.service'
import { getProjectionDataService } from '../../services/PropertyServices/getProjectionData.service';
import { updateProjectionDataService } from '../../services/PropertyServices/updateProjectionData.service';


export const getPropertyList = createAsyncThunk(
    'property/getPropertyList',
    async (data, { rejectWithValue }) => {
        const token = localStorage.getItem('token') || null;
        try {
            const response = await getPropertyListService(data?.page, data?.filter, data?.searchTerm, token)
            return {
                status: response.status,
                count: response.data.data.count,
                propertyData: response.data.data.results
            };
        } catch (error) {
            return rejectWithValue({ data: error });
        }
    }
);

export const getDashboardPropertyData = createAsyncThunk(
    'property/getDashboardPropertyData',
    async (token, { rejectWithValue }) => {
        try {
            const response = await getDashboardPropertyDataService(localStorage.getItem('token'))
            return {
                status: response.status,
                dashboardPropertyData: response.data
            };
        } catch (error) {
            return rejectWithValue({ data: error });
        }
    }
);

export const getDashboardData = createAsyncThunk(
    'dashboard/getDashboardData',
    async (token, { rejectWithValue }) => {
        try {
            const response = await getDashboardDataService(localStorage.getItem('token'))
            return {
                status: response.status,
                dashboardData: response.data.data
            };
        } catch (error) {
            return rejectWithValue({ data: error });
        }
    }
);

export const getFeaturePropertyList = createAsyncThunk(
    'property/getFeaturePropertyList',
    async (data, { rejectWithValue }) => {
        try {
            const token = localStorage.getItem('token') || null;
            const response = await getFeaturePropertyListService(data?.page, data?.filter, data?.searchTerm, token)
            return {
                status: response.status,
                count: response.data.data.count,
                propertyData: response.data.data.results
            };
        } catch (error) {
            return rejectWithValue({ data: error });
        }
    }
);

export const updatProperty = createAsyncThunk(
    'property/updatProperty',
    async (data, { rejectWithValue }) => {
        const token = localStorage.getItem('token') || null;
        try {
            const response = await updatePropertyService(data?.PropertyDetail, data?.id, token)
            return {
                status: response.status,
            };
        } catch (error) {
            return rejectWithValue({ data: error });
        }
    }
);

export const getActivePropertyList = createAsyncThunk(
    'property/getActivePropertyList',
    async (data, { rejectWithValue }) => {
        const token = localStorage.getItem('token') || null;
        try {
            const response = await getActivePropertyListService(data?.page, data?.filter, data?.searchTerm, token)
            return {
                status: response.status,
                count: response.data.data.count,
                propertyData: response.data.data.results
            };
        } catch (error) {
            return rejectWithValue({ data: error });
        }
    }
);

export const getInActivePropertyList = createAsyncThunk(
    'property/getActivePropertyList',
    async (data, { rejectWithValue }) => {
        const token = localStorage.getItem('token') || null;
        try {
            const response = await getInActivePropertyListService(data?.page, data?.filter, data?.searchTerm, token)
            return {
                status: response.status,
                count: response.data.data.count,
                propertyData: response.data.data.results
            };
        } catch (error) {
            return rejectWithValue({ data: error });
        }
    }
);

export const deleteProperty = createAsyncThunk(
    'property/deleteProperty',
    async (id, { rejectWithValue }) => {
        const token = localStorage.getItem('token') || null;
        try {
            const response = await deletePropertyService(id, token)
            return {
                status: response.status,
            };
        } catch (error) {
            return rejectWithValue({ data: error });
        }
    }
);

export const getProperty = createAsyncThunk(
    'property/getProperty',
    async (id, { rejectWithValue }) => {
        const token = localStorage.getItem('token') || null;
        try {
            const response = await getPropertyByIdService(id, token)
            return {
                status: response.status,
                propertyData: response.data.data
            };
        } catch (error) {
            return rejectWithValue({ data: error });
        }
    }
);

export const getProjectionData = createAsyncThunk(
    'property/getProjectionData',
    async (id, { rejectWithValue }) => {
        const token = localStorage.getItem('token') || null;
        try {
            const response = await getProjectionDataService(id, token)
            return {
                status: response.status,
                projectionsData: response?.data?.data
            };
        } catch (error) {
            return rejectWithValue({ data: error });
        }
    }
);
export const updatProjectionData = createAsyncThunk(
    'property/updateProjectionData',
    async (data, { rejectWithValue }) => {
        const token = localStorage.getItem('token') || null;
        try {
            const response = await updateProjectionDataService(data?.projectionData, data?.propertyId, token)
            return {
                status: response.status,
            };
        } catch (error) {
            return rejectWithValue({ data: error });
        }
    }
);