import React from "react";
import { Button, Dropdown } from "react-bootstrap";
import { BsPower } from "react-icons/bs";
import avatarImg from "../assets/images/avatar-img.jpg";
import { RiMenuLine } from "react-icons/ri";
import "./common.scss";
import { useSelector, useDispatch } from "react-redux";
import { logoutUser } from "../redux/slices/authSlice";
import { toast } from "react-toastify";
import { useNavigate, NavLink } from "react-router-dom";
import { ReactComponent as UserIcon } from "../assets/icons/icon-nav-user.svg";

const Navbar = ({ setIsCollapsable }) => {
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const signOutUser = () => {
    dispatch(logoutUser());
    navigate("/signin");
    toast.success("Logged Out Successfully");
  };

  return (
    <div className="nav-bar-wrap">
      <div className="header-left">
        <div className="collapse-menu-btn">
          <RiMenuLine onClick={() => setIsCollapsable(true)} />
        </div>
      </div>

      <div className="header-right">
        <div className="logout-icon">
          <Button variant="link" onClick={signOutUser}>
            <BsPower />
          </Button>
        </div>
        <Dropdown>
          <Dropdown.Toggle
            as={"div"}
            id="dropdown-basic"
            className="user-info-btn"
          >
            <div className="admin-info d-flex">
              <div className="admin-info">
                <h6>{user?.first_name}</h6>
                <p className="m-0">{user?.email}</p>
              </div>
              <div className="admin-avatar">
                <img
                  src={user?.image ? user?.image : avatarImg}
                  alt="user-avatr"
                />
              </div>
            </div>
          </Dropdown.Toggle>
          <div className="user-info-inner-wrap">
            <Dropdown.Menu as="ul" variant="" className="user-info-menu">
              <Dropdown.Item as="li">
                <NavLink to="/profile-setting">
                  {" "}
                  <UserIcon />
                  <span>Profile setting</span>{" "}
                </NavLink>
              </Dropdown.Item>
            </Dropdown.Menu>
          </div>
        </Dropdown>
      </div>
    </div>
  );
};

export default Navbar;
