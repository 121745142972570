import React, { useState } from "react";
import Sidebar from "./Sidebar";
import Navbar from "./Navbar";

const Layout = ({ children }) => {
  const [isCollapsable, setIsCollapsable] = useState(false);
  return (
    <>
      <div className={`main-wrap ${isCollapsable ? "overlay" : ""}`}>
        <Sidebar
          isCollapsable={isCollapsable}
          setIsCollapsable={setIsCollapsable}
        />
        <Navbar setIsCollapsable={setIsCollapsable} />
        <main>{children}</main>
      </div>
    </>
  );
};

export default Layout;
