import axios from "axios";
import { API_BASE_URL } from './constants';


export const loginUserService = (user) => {

    return axios.post(
        `${API_BASE_URL}user/login/`,
        user
    );
}