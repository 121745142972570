import React, { useState, useEffect } from 'react';
import { Form, InputGroup, Button } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { Link, useNavigate } from 'react-router-dom';
import Pagination from 'react-bootstrap/Pagination';
import { BsSearch } from 'react-icons/bs';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Dropdown from 'react-bootstrap/Dropdown';
import { BiDotsVerticalRounded } from 'react-icons/bi';
import './admin.scss';
import Layout from '../../common/layout';
import { FaPlus } from 'react-icons/fa';
import { getAdminUsersService } from '../../services/adminUsers/adminUsers.service';
import { deleteAdminUserService } from '../../services/adminUsers/deleteAdminUser.service';
import { searchAdminService } from '../../services/adminUsers/searchAdmin.service';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import Loader from '../../common/Loader/loader';
import { useDebounce } from '../../common/Functions/useDebounce';

const AdminList = () => {
	const navigate = useNavigate();
	const [showDelete, setShowDelete] = useState(false);
	const [adminUsers, setAdminUsers] = useState([]);
	// eslint-disable-next-line
	const [isDisabled, setIsDisabled] = useState(false);
	const [searchQuery, setSearchQuery] = useState('');
	const debouncedSearchTerm = useDebounce(searchQuery, 500);
	// eslint-disable-next-line
	const [page, setPage] = useState(1);
	// eslint-disable-next-line
	const [totalRecords, setTotalRecords] = useState(null);
	// eslint-disable-next-line
	const [filter, setFilter] = useState('-user__date_joined');

	const [id, setId] = useState('');
	const [isLoading, setIsLoading] = useState(false);
	const { token } = useSelector((state) => state.auth);
	const getAdminUsers = async () => {
		setIsLoading(true);
		try {
			const response = await getAdminUsersService(token, page, filter);
			if (response.status === 200) {
				setAdminUsers(response?.data?.data?.results);
				setTotalRecords(response?.data?.data?.count);
			}
		} catch (e) {
			toast.error('Something went wrong');
			console.log(e);
		} finally {
			setIsLoading(false);
		}
		setIsDisabled(false);
	};

	const deleteAdmin = async () => {
		try {
			setIsDisabled(true);
			const response = await deleteAdminUserService(id, token);
			if (response.status === 205) {
				toast.success('Admin User deleted successfully');
				setId('');
				setShowDelete(false);
			}
			getAdminUsers();
		} catch (err) {
			setIsDisabled(false);
			toast.error('Something went wrong');
		} finally {
			setIsDisabled(false);
		}
	};

	const searchAdminUser = async () => {
		setIsLoading(true);
		setIsDisabled(true);
		if (searchQuery === '') {
			getAdminUsers();
			return;
		}
		try {
			const response = await searchAdminService(token, searchQuery);
			if (response.status === 200) {
				setAdminUsers(response?.data?.data?.results);
				// setTotalRecords(response?.data?.data?.count);
			}
		} catch (err) {
			toast.error('Something went wrong');
		} finally {
			setIsDisabled(false);
			setIsLoading(false);
		}
	};

	useEffect(() => {
		searchAdminUser();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [debouncedSearchTerm]);

	const pageNums = [];

	for (let i = 1; i <= Math.ceil(totalRecords / 10); i++) {
		pageNums.push(i);
	}

	const formatDate = (date) => {
		const year = new Date(date).getFullYear();
		const month = new Date(date).getMonth() + 1;
		const day = new Date(date).getDate();
		// eslint-disable-next-line
		const time = new Date(date).getTime();

		return `${day}/ ${month}/ ${year}`;
	};

	useEffect(() => {
		getAdminUsers();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [page, filter]);

	return (
		<>
			<Layout>
				<div className="content-body">
					<div className="content-body-inner">
						<div className="page-header">
							<h1>Admins List</h1>
							<Breadcrumb>
								<Link to={`/customer`} className="link-btn">
									Admins
								</Link>
								<span className="mx-1">/</span>
								<span>Admins List</span>
							</Breadcrumb>
						</div>
						<div className="page-content proprty-list-wrap admin-list-wrap">
							<div className="filter-area">
								<div className="table-search-bar">
									<Form.Group
										className="search-field-wrap"
										controlId="exampleForm.ControlInput1"
									>
										<InputGroup className="searchbox-wrap">
											<Form.Control
												placeholder="Search Admin name here"
												aria-label="Search Admin name here"
												aria-describedby="basic-addon2"
												value={searchQuery}
												onChange={(e) => setSearchQuery(e.target.value)}
											/>
											<Button variant="outline-secondary" id="button-addon2">
												<BsSearch />
											</Button>
										</InputGroup>
									</Form.Group>
								</div>

								<div className="table-filter d-flex">
									<Dropdown>
										<Dropdown.Toggle id="dropdown-basic">
											{filter === '-user__date_joined' ? 'Newest' : 'Oldest'}
										</Dropdown.Toggle>

										<Dropdown.Menu>
											<Dropdown.Item
												href="#"
												onClick={() => {
													setFilter(
														filter === '-user__date_joined'
															? 'user__date_joined'
															: '-user__date_joined'
													);
												}}
											>
												{filter === 'user__date_joined' ? 'Newest' : 'Oldest'}
											</Dropdown.Item>
										</Dropdown.Menu>
									</Dropdown>
									<Button
										variant="primary"
										onClick={() => {
											navigate('/new-admin');
										}}
									>
										<FaPlus />
										New Admin
									</Button>
								</div>
							</div>
							<div className="listing-table customer-table">
								<div className="customer-table-inner">
									{isLoading && <Loader />}
									{!isLoading && adminUsers.length > 0 ? (
										adminUsers.map((adminUser) => (
											<div className="table-row">
												<div className="avatar">
													<div className=" ">
														{adminUser?.image ? (
															<img
																src={adminUser?.image}
																alt="recent-customer-avatr5"
															/>
														) : (
															<div className="no-img">
																<span>
																	{(adminUser?.first_name
																		? adminUser?.first_name[0]
																		: '') +
																		(adminUser?.last_name
																			? adminUser?.last_name[0]
																			: '')}
																</span>
															</div>
														)}
													</div>
												</div>
												<div className="main-info">
													<div className="inner-wrap">
														<small>#{adminUser.id}</small>
														<h5>
															{adminUser?.first_name} {adminUser?.last_name}
														</h5>
														<label className="mb-0">
															Join on{' '}
															<span>{formatDate(adminUser?.date_joined)}</span>
														</label>
													</div>
												</div>

												<div className="email">
													<div className="inner-wrap">
														<label className="mb-0">Email Address</label>
														<p className="mb-0">{adminUser?.email}</p>
													</div>
												</div>

												<div className="dd-action">
													<div className="dd-action">
														<Dropdown>
															<Dropdown.Toggle id="dropdown-basic">
																<BiDotsVerticalRounded />
															</Dropdown.Toggle>

															<Dropdown.Menu>
																<Dropdown.Item
																	onClick={() => {
																		setId(adminUser.id);
																		setShowDelete(true);
																	}}
																>
																	Remove
																</Dropdown.Item>
															</Dropdown.Menu>
														</Dropdown>
													</div>
												</div>
											</div>
										))
									) : (
										<div className="text-center">No Admin User Found</div>
									)}
								</div>
							</div>

							<div className="listing-table-pagination">
								<div className="pagination-info">
									<p>
										Showing <span>5</span> from <span>{totalRecords}</span> data{' '}
									</p>
								</div>
								<Pagination>
									{page !== 1 && (
										<Pagination.Prev
											className="prev-btn"
											onClick={() => setPage(page - 1)}
										/>
									)}

									{pageNums.map((pageNum) => (
										<Pagination.Item
											key={pageNum}
											active={page === pageNum}
											onClick={() => setPage(pageNum)}
										>
											{pageNum}
										</Pagination.Item>
									))}
									{page !== Math.ceil(totalRecords / 10) &&
										totalRecords !== 0 && (
											<Pagination.Next
												className="next-btn"
												onClick={() => setPage(page + 1)}
											/>
										)}
								</Pagination>
							</div>
						</div>
					</div>
				</div>

				<Modal show={showDelete} onHide={() => setShowDelete(false)}>
					<Modal.Header closeButton></Modal.Header>
					<Modal.Body>
						<h3>Sure you want to Remove User? </h3>
						<p> This action will permanently remove this User from the list</p>
					</Modal.Body>
					<Modal.Footer>
						<Button variant="secondary" onClick={() => setShowDelete(false)}>
							No,Cancel
						</Button>
						<Button className="btn-alert" onClick={deleteAdmin}>
							Yes,Confirm
						</Button>
					</Modal.Footer>
				</Modal>
			</Layout>
		</>
	);
};

export default AdminList;
