import React, { useState, useEffect } from "react";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { Form, Button, Row, Col } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Layout from "../../common/layout";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { addBrandsService } from "../../services/brandServices/addBrand.service";
import { getBrandsService } from "../../services/brandServices/getBrands.service";
import { saveBrandsService } from "../../services/brandServices/saveBrands.service";
import { useForm } from "react-hook-form";

// import UploadAdapter from "./upload/UploadAdapter";
//

const AddBrands = () => {
  const location = useLocation();
  const isEditable = location?.state?.data;
  const brandId = location?.state?.id;
  const {
    register,
    handleSubmit,
    reset,
    getValues,
    formState: { errors },
  } = useForm({mode: "onChange"});

  const [brand, setBrand] = useState({
    brandName: "",
    brandImage: "",
    brandURL: "",
  });
  const [isDisabled, setIsDisabled] = useState(false);
  const navigate = useNavigate();
  const { token } = useSelector((state) => state.auth);

  const getBrand = async (id) => {
    const response = await getBrandsService({ id });
    if (response.status === 200) {
      setBrand({
        brandName: response?.data?.data?.name,
        brandImage: response?.data?.data?.image,
        brandURL: response?.data?.data?.url
      });
    }
  };
// eslint-disable-next-line
  const values = getValues();

  useEffect(() => {
    isEditable && getBrand(brandId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = (detail) => {
    setIsDisabled(true)
    if (isEditable) {
      updateBrand(detail)
      reset()
    } else {
      addNewBrand(detail)
    }
    setIsDisabled(false)
  }

  const addNewBrand = async (detail) => {
    const data = new FormData();
    data.append("name", detail.brandName);
    data.append("image", detail.brandImage[0]);
    data.append("url", detail.brandURL)
    try {
      setIsDisabled(true);
      const response = await addBrandsService(token, data);
      if (response.status === 201) {
        toast.success("New Brand added successfully");
        setBrand({ brandName: "", brandImage: "", brandURL: "" });
        navigate("/brands");
      }
    } catch (e) {
      setIsDisabled(false);
      toast.error("Something went wrong");
      console.error(e);
    } finally {
      setIsDisabled(false);
    }
  };

  const updateBrand = async (detail) => {
    const data = new FormData();
    data.append("name", detail.brandName || brand.brandName);
    if(detail.brandImage[0] !== undefined){
      data.append("image", detail.brandImage[0]);
    }
    data.append("url", detail.brandURL || brand.brandURL)
    try {
      setIsDisabled(true);
      const response = await saveBrandsService(token, brandId, data);
      if (response.status === 200) {
        toast.success("Brand updated successfully");
        setBrand({ brandName: "", brandImage: "" });
        navigate("/brands");
      }
    } catch (err) {
      setIsDisabled(false);
      toast.error("Something went wrong");
    } finally {
      setIsDisabled(false);
    }
  };

  // Custom Upload Adapter Plugin function
  // function CustomUploadAdapterPlugin(editor) {
  // 	editor.plugins.get("FileRepository").createUploadAdapter = loader => {
  // 		// Create new object and pass server url
  // 		return new UploadAdapter(loader);
  // 	};
  // }

  // const config = {
  //   language: "en", // fa - for persian language ( rtl )
  //   extraPlugins: [CustomUploadAdapterPlugin]
  // };

  return (
    <>
      <Layout>
        <div className="content-body">
          <div className="content-body-inner">
            <div className="page-header">
              <h1>{isEditable ? "Update Brand" : "Add Brand"}</h1>
              <Breadcrumb>
                <Link to={`/brands`} className="link-btn">
                  Brands
                </Link>
                <span className="mx-1">{/* <AiFillCaretRight /> */} / </span>
                {/* <span>{location?.state?.data ? 'Update Article' : 'Create a New Article'} </span> */}
              </Breadcrumb>
            </div>
            <div className="page-content">
              <div className="create-brand-wrap">
                <Form className="" onSubmit={handleSubmit(onSubmit)}>
                  <Row className="">
                    <Col sm="12" md="6" lg="6">
                      <Form.Label column md="2">
                        Brand Title <span className="astrik">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter brand name here"
                        name="brandName"
                        defaultValue={brand.brandName}
                        {...register("brandName",  { required: isEditable ? false : true })}
                      />
                      {errors && errors.brandName  && (
                        <p style={{ color: "red" }}>Brand Name is required.</p>
                      )}
                    </Col>
                    <Col sm="12" md="6" lg="6">
                      <Form.Label column md="2">
                        Brand URL <span className="astrik">*</span>
                      </Form.Label>
                      <Form.Control
                        type="url"
                        placeholder="Enter brand url here"
                        name="brandURL"
                        value={brand.brandURL}
                        {...register("brandURL",  { required: isEditable ? false : true })}
                      />
                      {errors && errors.brandURL && (
                        <p style={{ color: "red" }}>Brand URL is required.</p>
                      )}
                    </Col>
                    <Col sm="12" md="12" lg="12">
                      <Form.Label column md="2">
                        Brand Image <span className="astrik">*</span>
                      </Form.Label>
                      <Form.Control
                        type="file"
                        name="brandImage"
                        accept="image/png, image/gif, image/jpeg"
                       {...register("brandImage", { required: isEditable ? false : true })} 
                      />
                      {errors && errors.brandImage && (
                        <p style={{ color: "red" }}>Brand Image is required.</p>
                      )}
                    </Col>
                  </Row>
                  {/* <Form.Group as={Row} className="" controlId="">
                            <Form.Label column md="2">
                              Title <span>*</span>
                            </Form.Label>
                            <Col md="10">
                              <Form.Control
                                type="text"
                                placeholder="Enter brand name here"
                                name="title"
                                value={brand.brandName}
                                onChange={(e) =>
                                  setBrand((prev) => ({
                                    ...prev,
                                    brandName: e.target.value,
                                  }))
                                }
                              />
                            </Col>
                          </Form.Group>
                          <Form.Group as={Row} className="" controlId="">
                            <Form.Label column md="2">
                              Brand Image <span>*</span>
                            </Form.Label>
                            <Col md="10">
                              <Form.Control
                                type="file"
                                name="name"
                                onChange={(e) =>
                                  setBrand((prev) => ({
                                    ...prev,
                                    brandImage: e.target.files[0],
                                  }))
                                }
                              />
                            </Col>
                          </Form.Group>
                          <Form.Group as={Row} className="" controlId="">
                            <Form.Label column md="2">
                              Brand URL <span>*</span>
                            </Form.Label>
                            <Col md="10">
                              <Form.Control
                                type="text"
                                placeholder="Enter brand Url here"
                                name="url"
                              />
                            </Col>
                          </Form.Group> */}
                  <div className="btn-grp">
                    <Button
                      disabled={isDisabled}
                      variant="primary"
                      type="submit"
                    >
                      {isEditable ? "Update Brand" : "Add Brand"}
                    </Button>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>

        {/* <Modal show={openCategoryModal} className="edit-email-modal" onHide={() => setOpenCategoryModal(false)}>
          <Modal.Header closeButton></Modal.Header>
          <Form className="" onSubmit={submitCategory}>
            <Modal.Body>
              <h3>Add New Category</h3>
              <p>
                {" "}
                In order to add new Category to the category listing, please enter the category name and your article will appear in that category
              </p>
              <Form.Group as={Row} className="mt-4" controlId="">
                <Form.Label column md="12">
                  New Category <span>*</span>
                </Form.Label>
                <Col md="12">
                  <Form.Control
                    type="text"
                    placeholder="Enter Category name here"
                    name="category"
                    onChange={(e) => { setArticleCategory(e.target.value) }}
                  />
                </Col>
              </Form.Group>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => setOpenCategoryModal(false)}>
                No,Cancel
              </Button>
              <Button variant="primary" className="" type="submit" onClick={() => setOpenCategoryModal(false)}>
                Yes, Add ‘Category’
              </Button>
            </Modal.Footer>
          </Form>
        </Modal> */}
      </Layout>
    </>
  );
};
export default AddBrands;
