import React, { useState, useEffect } from "react";
import { Form, InputGroup, Button } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";
import Pagination from "react-bootstrap/Pagination";
import { BsSearch } from "react-icons/bs";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Dropdown from "react-bootstrap/Dropdown";
import "./customer.scss";
import Layout from "../../common/layout";
import { getCustomersService } from "../../services/customerServices/getCustomer.service";
import { deleteCustomerService } from "../../services/customerServices/deletCustomer.service";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import CustomerDetail from "./CustomerDetail";
import { searchCustomerService } from "../../services/customerServices/searchCustomer.service";
import Loader from "../../common/Loader/loader";
import { useDebounce } from "../../common/Functions/useDebounce";

const CustomerList = () => {
  const [showDelete, setShowDelete] = useState(false);
  const [isCustomers, setIsCustomers] = useState([]);
  const [id, setId] = useState("");
  // eslint-disable-next-line
  const [isDisabled, setIsDisabled] = useState(false);
  const [page, setPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(null);
  const [filter, setFilter] = useState("-user__date_joined");
  const [searchQuery, setSearchQuery] = useState("");
  const debouncedSearchTerm = useDebounce(searchQuery, 500);
  const [isLoading, setisLoading] = useState(true)

  const { token } = useSelector((state) => state.auth);

  const handleId = (id) => setId(id);
  const showDeleteModal = () => setShowDelete(true);

  const getCustomers = async () => {
    setisLoading(true)
    try {
      const response = await getCustomersService(token, page, filter);
      if (response.status === 200) {
        setIsCustomers(response?.data?.data?.results);
        setTotalRecords(response?.data?.data?.count);
      }
    } catch (e) {
      toast.error("Something went wrong");
    }
    setisLoading(false)
  };

  const deleteCustomer = async () => {
    try {
      setIsDisabled(true);
      const response = await deleteCustomerService(id, token);
      if (response.status === 205) {
        toast.success("Customer deleted successfully");
        setId("");
        setShowDelete(false);
      }
      getCustomers();
    } catch (err) {
      setIsDisabled(false);
      toast.error("Something went wrong");
    } finally {
      setIsDisabled(false);
    }
  };

  const searchCustomer = async () => {
    if (searchQuery === "") {
      getCustomers();
      return;
    }
    try {
      setisLoading(true)
      setIsDisabled(true);
      const response = await searchCustomerService(token, searchQuery);
      if (response.status === 200) {
        setIsCustomers(response?.data?.data?.results);
        setTotalRecords(response?.data?.data?.count);
      }
    } catch (err) {
      toast.error("Something went wrong");
    } finally {
      setIsDisabled(false);
      setisLoading(false)
    }
  };

  useEffect(() => {
    searchCustomer()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[debouncedSearchTerm])

  const pageNums = [];

  for (let i = 1; i <= Math.ceil(totalRecords / 10); i++) {
    pageNums.push(i);
  }

  useEffect(() => {
    getCustomers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, filter]);

  return (
    <>
      <Layout>
        <div className="content-body">
          <div className="content-body-inner customer-list-wrap">
            <div className="page-header">
              <h1>Customers List</h1>
              <Breadcrumb>
                <Link to={`/customers`} className="link-btn">
                  Customers
                </Link>
                <span className="mx-1">/</span>
                <span>Customer List</span>
              </Breadcrumb>
            </div>
            <div className="page-content">
              <div className="filter-area">
                <div className="table-search-bar">
                  <Form.Group
                    className="search-field-wrap"
                    controlId="exampleForm.ControlInput1"
                  >
                    <InputGroup className="searchbox-wrap">
                      <Form.Control
                        placeholder="Search customer name here"
                        aria-label="Search customer name here"
                        aria-describedby="basic-addon2"
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                      />
                      <Button
                        variant="outline-secondary"
                        id="button-addon2"
                      >
                        <BsSearch />
                      </Button>
                    </InputGroup>
                  </Form.Group>
                </div>
                <div className="table-filter">
                  <Dropdown>
                    <Dropdown.Toggle id="dropdown-basic">
                      {filter === "-user__date_joined" ? "Newest" : "Oldest"}
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item
                        href="#"
                        onClick={() => {
                          setFilter(
                            filter === "-user__date_joined"
                              ? "user__date_joined"
                              : "-user__date_joined"
                          );
                        }}
                      >
                        {filter === "user__date_joined" ? "Newest" : "Oldest"}
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>

              <div className="listing-table customer-table">
                <div className="customer-table-inner">
                  {isCustomers.length > 0 && !isLoading && (
                    isCustomers.map((customers) => (
                      <CustomerDetail
                        showDeleteModal={showDeleteModal}
                        handleId={handleId}
                        customers={customers}
                      />
                    ))
                  )}
                  {isLoading && <Loader />}
                  {!isLoading && isCustomers.length < 1 && <div className="table-row w-100 justify-content-center">No Customers Found</div>}
                </div>
              </div>
              <div className="listing-table-pagination">
                <div className="pagination-info">
                  <p>
                    Showing <span>{isCustomers.length}</span> from{" "}
                    <span>{totalRecords}</span> data{" "}
                  </p>
                </div>
                <Pagination>
                  {page !== 1 && (
                    <Pagination.Prev
                      className="prev-btn"
                      onClick={() => setPage(page - 1)}
                    />
                  )}

                  {pageNums.map((pageNum) => (
                    <Pagination.Item
                      key={pageNum}
                      active={page === pageNum}
                      onClick={() => setPage(pageNum)}
                    >
                      {pageNum}
                    </Pagination.Item>
                  ))}
                  {page !== Math.ceil(totalRecords / 10) &&
                    totalRecords !== 0 && (
                      <Pagination.Next
                        className="next-btn"
                        onClick={() => setPage(page + 1)}
                      />
                    )}
                </Pagination>
              </div>
            </div>
          </div>
        </div>

        <Modal show={showDelete} onHide={() => setShowDelete(false)}>
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <h3>Sure you want to Remove Customer? </h3>
            <p>
              {" "}
              This action will permanently remove this Customer from the list
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowDelete(false)}>
              No,Cancel
            </Button>
            <Button className="btn-alert" onClick={deleteCustomer}>
              Yes,Confirm
            </Button>
          </Modal.Footer>
        </Modal>
      </Layout>
    </>
  );
};

export default CustomerList;
