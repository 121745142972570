import React, { useEffect, useState } from "react";
import { Form, Button, Row, Col, Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import "./auth.scss";
import RevNest from "../../assets/images/revnest-logo-admin.svg";
import { useDispatch } from "react-redux";
import { loginUser } from "../../redux/slices/authSlice";
import { toast } from 'react-toastify';

const SignIn = (props) => {
  const [isDisabled, setIsDisabled] = useState(false)
  const [user, setUser] = useState({
    email: '',
    password: '',
  });

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const changeHandler = (e) => {
    const { name, value } = e.target;
    setUser((prevValue) => ({
      ...prevValue,
      [name]: value,
    }));
  };

  const validateEmail = (email) => {
    // eslint-disable-next-line
    const regularExpression = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (regularExpression.test(email)) {
      return true;
    } else {
      return false;
    }
  };
  const checkInput = () => {
    return user.password !== '' && user.email !== '';
  };

  const submitHandler = async (e) => {
    e.preventDefault();

    try {
      setIsDisabled(true);
      if (checkInput()) {
        if (validateEmail(user.email)) {
          const response = await dispatch(loginUser(user));

          if (response.payload.status === 200 && response.payload.user.role === "ADMIN") {
            setUser({
              email: '',
              password: '',
            });

            localStorage.setItem('token', response.payload.token);
            localStorage.setItem(
              'user',
              JSON.stringify(response.payload.user)
            );
            // getLocations();

            setUser({
              email: '',
              password: '',
            });

            // navigate(from);
            navigate('/dashboard')
            toast.success('Logged in successfully');
          } else {
            toast.error("Invalid Admin")
          }
        } else {
          toast.error('Invalid Email');
        }
      } else {
        toast.error('All fields are required');
      }
    } catch (error) {
      toast.error('something went wrong');
    } finally {
      setIsDisabled(false);
    }
  };


  useEffect(() => {
    try {
      let token = localStorage.getItem('token')
      if (token && token !== 'null') {
        navigate('/dashboard')
      }
    } catch (error) {
      console.error('Error while fetching token::::---', error)
    }
  }, [])


  return (
    <div>
      <Container fluid className="auth">
        <div className="">
          <div className="brand">
            <img src={RevNest} alt="RevNest Logo" />
          </div>
          <div className="auth-container d-flex">
            <Row className="mx-auto">
              <div></div>
              <h1 className="text-center">Sign In</h1>

              <Form className="mb-3" onSubmit={submitHandler}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formSignInEmail"
                >
                  <Form.Label column md="12">
                    Email Address
                  </Form.Label>
                  <Col md="12">
                    <Form.Control type="email" placeholder="Email Address" name='email' value={user.email} onChange={changeHandler} />
                  </Col>
                </Form.Group>

                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formSignInPassword"
                >
                  <Col md="12" className="d-flex align-items-center">
                    <Form.Label column md="6">
                      Password
                    </Form.Label>
                    {/* <Col md="6" className="d-flex justify-content-end">
                      <button
                        onClick={(e) => {
                          e.preventDefault();
                          props.setPasswrodModalVisible(true);
                        }}
                        className="link-btn"
                      >
                        Forgot Password ?
                      </button>
                    </Col> */}
                  </Col>

                  <Col md="12">
                    <Form.Control type="password" placeholder="Password" name='password' value={user.password} onChange={changeHandler} />
                  </Col>
                </Form.Group>

                <div className="d-grid gap-2">
                  <Button
                    variant="primary"
                    type="submit"
                    size="lg"
                    className="mt-3"
                    disabled={isDisabled}
                  >
                    Sign In
                  </Button>
                </div>
              </Form>
            </Row>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default SignIn;
