import React, { useEffect, useState } from 'react'
import { Navigate, useNavigate } from 'react-router-dom'

const AuthRoute = ({ children }) => {
    let isAuthorized = false
    const navigate = useNavigate()
    try {
        let token = localStorage.getItem('token')
        if (token && token !== 'null') {
            isAuthorized = true
        }
    } catch (error) {
        console.error('error', error)
        navigate('/signin')
    }

    return (
        <>
            {
                isAuthorized ? children : <Navigate to='/signin'></Navigate>
            }
        </>
    )
}

export default AuthRoute