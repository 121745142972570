import React, { useState } from "react";
import { DefaultEditor } from "react-simple-wysiwyg";

const EmailTemplateEditor = () => {
  const [html, setHtml] = useState("my <b>HTML</b>");
  const onChange = (e) => {
    setHtml(e.target.value);
  };

  return <DefaultEditor value={html} onChange={onChange} />;
};
export default EmailTemplateEditor;
