import axios from 'axios';
import { API_BASE_URL } from './constants';

export const forgotPasswordService = (token, userDetails) => {
    const headers = { Authorization: 'Bearer ' + token, "Content-Type": "multipart/form-data" };
    return axios.post(
        `${API_BASE_URL}user/forgot-password/`,
        userDetails,
        { headers }
    );
};
